import React, { useContext } from 'react'
import { PhidemDataContext } from './PhidemDataContext'
import {
	ApplicationConfiguration,
	AuthorityModel,
	CentreInfoModel,
	CentreModel,
	initApplication,
	initCentre,
	initDispatchData,
	initZoneModel,
	PhidemReferentialDataModel,
	REFERENTIEL,
	ResponseValueListModel,
	ValueListDicModel,
	ValueListModel
} from './phidemDataModel'
import { getAllCarriers, getAllContainers, getAllProducts, getAllTrackers, getAvailableTemplates, getMyCenter, getMyCenterList, getValueListByShortcut } from './phidemDataApi'
import { connect } from 'react-redux'
import { CENTER_TYPE, LANGUAGES_CODE, PRODUCT_TYPES, USER_ROLE, VALUE_LIST_SHORTCUT } from '../../../utils/constants'
import { ProductModel, VaccinModel } from '../../admin/product/services/productModel'
import Loader from '../../../components/layout/Loader'
import { SessionReducer } from '../session/sessionReducer'
import { getStorageCenter, setStorageZone, switchCenter, switchZone } from '../session/sessionActions'
import { TemplateRowModel } from '../../admin/template/services/templateModels'
import { CarrierModel } from '../../admin/carrier/services/carrierModel'
import { ContainerModel } from '../../admin/container/services/containerModel'
import { TrackerModel } from '../../admin/tracker/services/trackerModel'
import { RequestReplenishmentListModel, RequestReplenishmentPointListModel } from '../../dispatch/services/dispatchModels'
import { getLanguageIdByCode } from '../../../utils/formUtils'
import { OfflineContext } from '../offline/context/OfflineContext'
import { getStorageOfflineMode } from '../offline/offlineStorage'
import { ZoneModel } from '../../admin/zone/services/zoneDataModel'
import { getAllZones, getMyZone } from '../../admin/zone/services/zoneApi'

interface AdminRights {
	isSuperAdmin: boolean,
	isAdminZone: boolean,
	isAdminDispatch: boolean
}

interface AcceptingProps {
	children: React.FC
	authorities: AuthorityModel[]
	session: SessionReducer
	selectedLang: string
	switchCenter: (center?: any) => void
	switchZone: (zone?: any) => void
}

type PhidemDataProps = AcceptingProps

const PhidemDataProvider: React.FC<PhidemDataProps> = (
	{
		children,
		authorities,
		session,
		selectedLang,
		switchCenter,
		switchZone
	}
) => {
	const { handleOfflineMode, setBackgroundSync } = useContext(OfflineContext)
	const [userZone, setUserZone] = React.useState<ZoneModel>(initZoneModel)
	const [vaccins, setVaccins] = React.useState<VaccinModel[]>([])
	const [products, setProducts] = React.useState<ProductModel[]>([])
	const [zones, setZones] = React.useState<ZoneModel[]>([])
	const [templates, setTemplates] = React.useState<TemplateRowModel[]>([])
	const [userCentre, setUserCentre] = React.useState<CentreModel>(initCentre)
	const [userCentreList, setUserCentreList] = React.useState<CentreInfoModel[]>([])
	const [referentialData, setReferentialData] = React.useState<PhidemReferentialDataModel>(initDispatchData)
	const [valueList, setValueList] = React.useState<ValueListDicModel>({})
	const [isLoading, setIsLoading] = React.useState<boolean>(true)
	const [adminRight, setAdminRight] = React.useState<AdminRights>({ isSuperAdmin: false, isAdminZone: false, isAdminDispatch: false })
	const [replenishmentFilters, setReplenishmentFilters] = React.useState<RequestReplenishmentListModel>(initApplication.replenishment.filters)
	const [replenishmentPointFilters, setReplenishmentPointFilters] = React.useState<RequestReplenishmentPointListModel>(initApplication.replenishmentPoint.filters)
	const [processedApplicationConfiguration, setProcessedApplicationConfiguration] = React.useState<ApplicationConfiguration>(initApplication.configuration)

	// Au montage, on récupère les centres et la zone de l'utilisateur
	React.useEffect(() => {
		const promises = [
			getMyZone(),
			getMyCenterList()
		]

		Promise.all(promises)
			.then((response: any) => {
				const [zoneResult, centerListResult] = response

				// Traitement de la zone
				if (zoneResult !== null && zoneResult !== undefined) {
					setUserZone(zoneResult)
					setStorageZone(zoneResult.id)
					switchZone({
						id: zoneResult.id
					})
				}

				setUserCentreList(centerListResult)

				// Initialisation avec le storage
				const centerId = getStorageCenter()
				let centerSelected = undefined

				// On vérifie que l'on a des résultats
				if (centerListResult.length > 0) {
					if (centerId && centerId.length > 0) {
						const centerFound = centerListResult.find(
							(center: CentreInfoModel) => center.id === centerId
						)
						if (centerFound) {
							centerSelected = {
								type: centerFound.type,
								id: centerFound.id
							}
						}
					} else {
						centerSelected = {
							type: centerListResult[0].type,
							id: centerListResult[0].id
						}
					}
				}

				// Start offline mode
				if (
					centerSelected &&
					centerSelected.type === CENTER_TYPE.VACCINATION &&
					getStorageOfflineMode() === 'true'
				) {
					handleOfflineMode(true)
				}

				switchCenter(centerSelected)
			})
			.catch((error) => {
				console.error('Error fetching data:', error)
			})
			.finally(() => {
				setIsLoading(false)
			})
	}, [switchCenter, switchZone, handleOfflineMode])

	React.useEffect(() => {
		if (userZone !== initZoneModel && !adminRight.isSuperAdmin) {
			setProcessedApplicationConfiguration({
				defaultLanguage: userZone.defaultLanguage.codeLang,
				transfertPreparation: userZone.config.preparationTransfert,
				replenishmentCoverage: userZone.config.besoinCouverture,
				replenishmentDose: userZone.config.besoinDoses,
				languageList: userZone.languages.map(language => language.codeLang)
			})
		}
	}, [userZone, adminRight])

	const getChildrenCenter = React.useCallback((center: CentreModel): CentreModel[] => {
		if (center.centreEnfants && center.centreEnfants.length > 0) {
			return [center, ...center.centreEnfants.reduce((acc: CentreModel[], curr) =>
					[...acc, ...getChildrenCenter(curr)]
				, [])]
		}
		return [center]
	}, [])

	// Update children centers
	// Vaccination & Point => centreVaccEnfants
	// Référent & Dispatch => centreRefDispEnfants
	const setFlattenUserCentres = React.useCallback((centre: CentreModel) => {
		const children = getChildrenCenter(centre)
		centre.centreEnfantsByType = {
			refDispatchs: children.filter(c => c.id !== centre.id && (c.type === CENTER_TYPE.REFERENT || c.type === CENTER_TYPE.DISPATCH)),
			vaccinations: children.filter(c => c.id !== centre.id && (c.type === CENTER_TYPE.VACCINATION || c.type === CENTER_TYPE.VACCINATION_POINT))
		}

		setUserCentre(centre)
		// Offline center = start background sync
		setBackgroundSync(centre.modeOffline)
	}, [getChildrenCenter, setBackgroundSync])

	React.useEffect(() => {
		//On récupère la liste des zones si on est superAdmin
		if (adminRight.isSuperAdmin) {
			getAllZones().then((response: any) => setZones(response))
		}
	}, [adminRight.isSuperAdmin])

	// On récupère les données selon le type de centre
	React.useEffect(() => {
		// On ne récupère pas les données tant que l'on charge le centre et la zone
		if (session.isLoading) {
			return
		}

		if (session.centerType === CENTER_TYPE.REFERENT || session.centerType === CENTER_TYPE.DISPATCH) {
			const promises = [
				getMyCenter(),
				getAllProducts(),
				getAllCarriers(),
				getAllContainers(),
				getAllTrackers(),
				getAvailableTemplates()
			]

			Promise.all(promises)
				.then((response: any) => {
					setFlattenUserCentres(response[0])
					const resProducts: ProductModel[] = response[1]
					setVaccins(resProducts.filter((product: ProductModel) => product.type === PRODUCT_TYPES.VACCIN) as VaccinModel[])
					setProducts(resProducts.filter((product: ProductModel) => product.type !== PRODUCT_TYPES.VACCIN))
					setReferentialData({
						carriers: response[2],
						containers: response[3],
						trackers: response[4]
					})

					setAdminRight({
						isSuperAdmin: false,
						isAdminZone: false,
						isAdminDispatch: session.centerType !== CENTER_TYPE.REFERENT && authorities.findIndex((auth: AuthorityModel) => auth.authority === USER_ROLE.ROLE_ADMIN_DISPATCH) !== -1
					})
					setTemplates(response[5])
				})
				.finally(() => {
					setIsLoading(false)
				})
		} else if (session.centerType === CENTER_TYPE.VACCINATION || session.centerType === CENTER_TYPE.VACCINATION_POINT) {
			const promises = [
				getMyCenter(),
				getAllProducts()
			]

			setReplenishmentFilters(state => ({
				...state,
				idCentre: session.centerId
			}))

			Promise.all(promises)
				.then((response: any) => {
					setFlattenUserCentres(response[0])

					const resProducts: ProductModel[] = response[1]
					setVaccins(resProducts.filter((product: ProductModel) => product.type === PRODUCT_TYPES.VACCIN) as VaccinModel[])
					setProducts(resProducts.filter((product: ProductModel) => product.type !== PRODUCT_TYPES.VACCIN))
				})
				.finally(() => {
					setIsLoading(false)
				})
			//Administrateur de zone
		} else if (session.zoneId !== undefined && session.centerType === undefined) {
			const promises = [
				getAllProducts(),
				getAvailableTemplates(),
				getAllCarriers()
			]

			Promise.all(promises)
				.then((response: any) => {
					const resProducts: ProductModel[] = response[0]
					setVaccins(resProducts.filter((product: ProductModel) => product.type === PRODUCT_TYPES.VACCIN) as VaccinModel[])
					setProducts(resProducts.filter((product: ProductModel) => product.type !== PRODUCT_TYPES.VACCIN))
					setTemplates(response[1])
					setAdminRight({
						isSuperAdmin: false,
						isAdminZone: true,
						isAdminDispatch: false
					})
					setReferentialData({
						carriers: response[2],
						containers: [],
						trackers: []
					})
				})
				.finally(() => {
					setIsLoading(false)
				})
		} else {
			setAdminRight({
				isSuperAdmin: true,
				isAdminZone: false,
				isAdminDispatch: false
			})
			setReferentialData({
				carriers: [],
				containers: [],
				trackers: []
			})
			setIsLoading(false)
		}
	}, [session, authorities, setFlattenUserCentres])

	// On récupère les listes de valeurs internationalisées
	const formatValueList = (list: ResponseValueListModel[]) => {
		return list
			.reduce((acc: ValueListModel, item: ResponseValueListModel) => ({
				...acc,
				[item.code]: item.label
			}), {})
	}

	React.useEffect(() => {
		const promises = [
			getValueListByShortcut(VALUE_LIST_SHORTCUT.PRODUCT_TYPE, getLanguageIdByCode(selectedLang)),
			getValueListByShortcut(VALUE_LIST_SHORTCUT.SCRAPPING_CAUSE, getLanguageIdByCode(selectedLang))
		]

		Promise.all(promises)
			.then((response: ResponseValueListModel[][]) => setValueList({
				[VALUE_LIST_SHORTCUT.PRODUCT_TYPE]: formatValueList(response[0]),
				[VALUE_LIST_SHORTCUT.SCRAPPING_CAUSE]: formatValueList(response[1])
			}))
	}, [selectedLang])

	const reloadReferentiel = (key: string) => {
		if (adminRight.isSuperAdmin && key !== REFERENTIEL.ZONE) {
			return
		}

		switch (key) {
			case REFERENTIEL.ZONE:
				if (adminRight.isSuperAdmin) {
					getAllZones().then((response: any) => setZones(response))
				}
				break
			case REFERENTIEL.CENTER:
				if (session.centerType === CENTER_TYPE.DISPATCH || session.centerType === CENTER_TYPE.VACCINATION) {
					getMyCenter().then(setFlattenUserCentres)
				}
				break
			case REFERENTIEL.CARRIER:
				getAllCarriers()
					.then((res: CarrierModel[]) => {
						setReferentialData((state) => ({
							...state,
							carriers: res
						}))
					})
				break
			case REFERENTIEL.CONTAINER:
				getAllContainers()
					.then((res: ContainerModel[]) => {
						setReferentialData((state) => ({
							...state,
							containers: res
						}))
					})
				break
			case REFERENTIEL.PRODUCT:
				getAllProducts()
					.then((response: ProductModel[]) => {
						setProducts(response.filter((product: ProductModel) => product.type !== PRODUCT_TYPES.VACCIN))
						setVaccins(response.filter((product: ProductModel) => product.type === PRODUCT_TYPES.VACCIN) as VaccinModel[])
					})
				break
			case REFERENTIEL.TEMPLATE:
				getAvailableTemplates().then(setTemplates)
				break
			case REFERENTIEL.TRACKER:
				getAllTrackers()
					.then((res: TrackerModel[]) => {
						setReferentialData((state) => ({
							...state,
							trackers: res
						}))
					})
				break
			default:
				break
		}
	}

	return <PhidemDataContext.Provider value={{
		vaccins,
		products,
		templates,
		zones,
		referentialData,
		user: {
			selectedCenter: userCentre,
			centerList: userCentreList,
			selectedZone: userZone,
			isSuperAdmin: adminRight.isSuperAdmin,
			isZoneAdmin: adminRight.isAdminZone,
			isAdminDispatch: adminRight.isAdminDispatch,
			selectedLang,
			isDateFormatEn: selectedLang === LANGUAGES_CODE.EN
		},
		application: {
			replenishment: {
				filters: replenishmentFilters,
				setFilters: setReplenishmentFilters
			},
			replenishmentPoint: {
				filters: replenishmentPointFilters,
				setFilters: setReplenishmentPointFilters
			},
			configuration: processedApplicationConfiguration
		},
		valueList,
		reloadReferentiel
	}}>
		{isLoading
			?
			<Loader />
			:
			children
		}
	</PhidemDataContext.Provider>
}

const mapStateToProps = (state: any) => ({
	session: state.session,
	authorities: (state.user && state.user.authorities) || [],
	selectedLang: state.intl.locale
})

const actions = {
	switchCenter,
	switchZone
}

export default connect(mapStateToProps, actions)(PhidemDataProvider)
