import React, { useContext, useEffect, useState } from 'react'
import { dataTableActions, FormSelect, SpringDataTable } from 'isotope-client'
import { FormattedMessage } from 'react-intl'
import NotFoundPage from '../../../components/layout/NotFoundPage'
import { ProductModel, RequestProductFiltersModel } from './services/productModel'
import { useHistory } from 'react-router-dom'
import RedirectFAB from '../RedirectFAB'
import { updateProductStatus } from './services/productApi'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { injectActions as injectSnackbarActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import { PhidemDataContext } from '../../common/phidemData/PhidemDataContext'
import { REFERENTIEL } from '../../common/phidemData/phidemDataModel'
import { formatDecimal } from '../../../utils/formUtils'
import { VALUE_LIST_SHORTCUT } from '../../../utils/constants'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { Field, Form } from 'react-final-form'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '../../../components/layout/buttons/Button'
import { Search } from '@material-ui/icons'
import Paper from '@material-ui/core/Paper'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		paperForm: {
			width: '70%',
			minWidth: 300,
			maxWidth: 880,
			padding: theme.spacing(2),
			marginBottom: 50
		},
		submitButtonRoot: {
			width: '100%',
			margin: theme.spacing(1),
			display: 'flex',
			justifyContent: 'flex-end'
		}
	})
)

interface ProductListProps {
	refreshTable: () => void,
	snackSuccess: (value: any) => void,
	snackError: (value: any) => void,
}

const ProductList: React.FC<ProductListProps> = (
	{
		refreshTable,
		snackSuccess,
		snackError
	}
) => {
	const classes = useStyles()
	const history = useHistory()
	const { reloadReferentiel, valueList, user: { isSuperAdmin }, zones } = useContext(PhidemDataContext)
	const [filters, setFilters] = useState<RequestProductFiltersModel>({
		zoneId: zones.length > 0 ? zones[0]?.id : undefined
	})

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			setFilters({
				...filters,
				zoneId: zones.length > 0 ? zones[0]?.id : undefined
			})
		}, 500)

		return () => clearTimeout(timeoutId)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [zones])

	const getProducts = (formValues: RequestProductFiltersModel) => {
		setFilters({ zoneId: formValues?.zoneId })
	}

	const handleProduitStatus = (id: string, status: boolean) => {
		updateProductStatus(id, status)
			.then(() => {
				refreshTable()
				reloadReferentiel(REFERENTIEL.PRODUCT)
				snackSuccess({ id: 'product.updateStatusSuccess', defaultMessage: 'Le statut du produit a bien été modifié', description: 'Success message' })
			})
			.catch(() => snackError({
				id: 'product.updateStatusError',
				defaultMessage: 'Une erreur est survenue, le statut du produit n\'a pas été modifiée',
				description: 'Error message'
			}))
	}

	return (
		<>
			{isSuperAdmin &&
				<Paper className={classes.paperForm}>
					<Form
						initialValues={{ zoneId: zones.length > 0 ? zones[0]?.id : undefined }}
						onSubmit={getProducts}
						// @ts-ignore
						render={({ handleSubmit, submitting }) => (
							<form onSubmit={handleSubmit}>
								<Grid
									container
									direction="row"
									justify="flex-start"
									spacing={2}
								>
									<Grid item xs={12} md={3}>
										<Field
											name="zoneId"
											component={FormSelect}
											label={
												<FormattedMessage
													id="product.Zone"
													defaultMessage="Zone"
													description="Zone"
												/>
											}
										>
											{zones.map((zone) => (
												<MenuItem key={zone.id} value={zone.id}>
													{zone.name}
												</MenuItem>
											))}
										</Field>
									</Grid>
									<Grid item xs={12} md={12}>
										<div className={classes.submitButtonRoot}>
											<Button
												variant="contained"
												startIcon={<Search />}
												type="submit"
												isLoading={submitting}
											>
												<FormattedMessage
													id="button.search"
													defaultMessage="Rechercher"
													description="Search button label"
												/>
											</Button>
										</div>
									</Grid>
								</Grid>
							</form>
						)} />
				</Paper>
			}
			<RedirectFAB redirectPath="/products/add">
				<SpringDataTable
					apiUrl="/referentiel/produit"
					nom="productList"
					filters={filters}
					headers={[
						{
							key: 'type',
							name: <FormattedMessage
								id="product.type"
								defaultMessage="Nom"
								description="Header column name"
							/>,
							sortable: true,
							render: (row: ProductModel) => <span>
							{(valueList[VALUE_LIST_SHORTCUT.PRODUCT_TYPE] || {})[row.type] || ''}
						</span>
						},
						{
							key: 'nomCommercial',
							name: <FormattedMessage
								id="product.name"
								defaultMessage="Nom"
								description="Header column name"
							/>,
							sortable: true
						},
						{
							key: 'codeCip',
							name: <FormattedMessage
								id="product.codeCip"
								defaultMessage="Code CIP"
								description="Header column cip code"
							/>,
							sortable: true
						},
						{
							key: 'formePharma',
							name: <FormattedMessage
								id="product.formePharma"
								defaultMessage="Forme pharmaceutique"
								description="Header column pharmacetic format"
							/>,
							sortable: true
						},
						{
							key: 'conservation',
							name: <FormattedMessage
								id="product.preservation"
								defaultMessage="Conservation"
								description="Header column preservation"
							/>,
							sortable: true
						},
						{
							key: 'typeConditionnement',
							name: <FormattedMessage
								id="product.typeCond"
								defaultMessage="Type de conditionnement"
								description="Header column packaging type"
							/>,
							sortable: true
						},
						{
							key: 'nbUnitesBoite',
							name: <FormattedMessage
								id="product.nbunitBoite"
								defaultMessage="Nombre d'unités par boîte"
								description="Header column unit box count"
							/>,
							sortable: true
						},
						{
							key: 'nbDoses',
							name: <FormattedMessage
								id="product.nbDoses"
								defaultMessage="Nombre de doses"
								description="Header column dose count"
							/>,
							sortable: true,
							render: (row: ProductModel) => <span>{row.nbDoses ? formatDecimal(row.nbDoses) : ''}</span>
						},
						{
							key: 'actif',
							name: <FormattedMessage
								id="global.status.label"
								defaultMessage="Statut"
								description="Header column status"
							/>,
							sortable: true,
							render: (row: ProductModel) => row.actif ? <FormattedMessage
								id="global.status.enabled"
								defaultMessage="Actif"
								description="Center status"
							/> : <FormattedMessage
								id="global.status.disabled"
								defaultMessage="Inactif"
								description="Center status"
							/>

						}
					]}
					makeActions={(row: ProductModel) => [
						{
							label: <FormattedMessage
								id="global.update"
								defaultMessage="Modifier"
								description="Update action"
							/>, action: () => {
								history.push(`/products/${row.id}`)
							}
						},
						{
							label: row.actif ? <FormattedMessage
								id="global.disable"
								defaultMessage="Désactiver"
								description="Disable action"
							/> : <FormattedMessage
								id="global.enable"
								defaultMessage="Activer"
								description="Enable action"
							/>, action: () => handleProduitStatus(row.id, !row.actif)
						}
					]}
					showActions
					noResultFragment={<NotFoundPage />}
				/>
			</RedirectFAB>
		</>
	)
}

const actions = {
	refreshTable: () => dataTableActions.refresh('productList')
}

export default compose<any>(
	connect(null, actions),
	injectSnackbarActions
)(ProductList)
