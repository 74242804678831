import React, { useContext, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { OnChange } from 'react-final-form-listeners'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { FormattedMessage, useIntl } from 'react-intl'
import { FormInput, FormSelect } from 'isotope-client'
import PageFormContainer from '../../../../components/layout/PageFormContainer'
import ErrorMessageRequired from '../../../../components/layout/errors/ErrorMessageRequired'
import Loader from '../../../../components/layout/Loader'
import { RequestCreateOrUpdateCarrierFormModel } from '../services/carrierModel'
import ButtonCreate from '../../../../components/layout/buttons/ButtonCreate'
import ButtonSave from '../../../../components/layout/buttons/ButtonSave'
import ButtonActivate from '../../../../components/layout/buttons/ButtonActivate'
import { isEmailOk } from '../../../../utils/formUtils'
import { CENTER_TYPE, SCREEN_SIZE } from '../../../../utils/constants'
import { PhidemDataContext } from '../../../common/phidemData/PhidemDataContext'
import { getAvailableDispatchCenters } from '../../center/services/centerApi'
import { CentreModel } from '../../../common/phidemData/phidemDataModel'
import MenuItem from '@material-ui/core/MenuItem'
import { getCenterNameByType } from '../../../../utils/stringUtils'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		buttonRoot: {
			width: '100%',
			display: 'flex',
			justifyContent: 'space-evenly'
		},
		button: {
			margin: theme.spacing(2)
		},
		rowCarrier: {
			margin: 0
		}
	})
)

interface CarrierFormProps {
	isLoading?: boolean,
	initialValues: RequestCreateOrUpdateCarrierFormModel | undefined,
	onSubmit: (event: any) => void,
	isCreationForm: boolean,
	updateCarrierStatus?: () => void
}

const CarrierForm: React.FC<CarrierFormProps> = (
	{
		isLoading = false,
		initialValues,
		onSubmit,
		isCreationForm,
		updateCarrierStatus
	}) => {
	const classes = useStyles()
	const intl = useIntl()
	const isLargeScreen = useMediaQuery(`(min-width: ${SCREEN_SIZE.LARGE}px)`)
	const { user: { isSuperAdmin, selectedZone }, zones } = useContext(PhidemDataContext)
	const [dispatchCenters, setDispatchCenters] = useState<CentreModel[]>([])

	const onValidate = (formValues: RequestCreateOrUpdateCarrierFormModel) => {
		const errors: any = {}

		if (!formValues.nom) {
			errors.nom = <ErrorMessageRequired />
		}
		if (!formValues.zoneId) {
			errors.zoneId = <ErrorMessageRequired />
		}
		if (!formValues.idCentre) {
			errors.idCentre = <ErrorMessageRequired />
		}
		if (!formValues.adresse) {
			errors.adresse = <ErrorMessageRequired />
		}
		if (!formValues.codePostal) {
			errors.codePostal = <ErrorMessageRequired />
		}
		if (!formValues.ville) {
			errors.ville = <ErrorMessageRequired />
		}
		if (!formValues.pays) {
			errors.pays = <ErrorMessageRequired />
		}
		if (!formValues.mailContact) {
			errors.mailContact = <ErrorMessageRequired />
		} else if (!isEmailOk(formValues.mailContact)) {
			errors.mailContact = <FormattedMessage
				id="global.error.validation.emailFormat"
				defaultMessage="Le format de l'email n'est pas réglementaire"
				description="Email format error"
			/>
		}

		return errors
	}

	return (
		<>
			{(isLoading) ?
				<Loader />
				:
				<Form
					initialValues={initialValues}
					onSubmit={onSubmit}
					validate={onValidate}
					render={({ handleSubmit, submitting, form }) => {

						return (
							<PageFormContainer onSubmit={handleSubmit}>
								<Grid item xs={12}>
									<Typography variant={isLargeScreen ? 'h1' : 'h3'}>
										<FormattedMessage
											id="carrier.formSection.information"
											defaultMessage="Informations du transporteur"
											description="Form section title"
										/>
									</Typography>
								</Grid>
								{isSuperAdmin &&
									<Grid item xs={6}>
										<Field
											name="zoneId"
											component={FormSelect}
											required
											disabled={!isCreationForm}
											label={
												<FormattedMessage
													id="product.Zone"
													defaultMessage="Zone"
													description="Zone"
												/>
											}
										>
											{zones.map((zone) => (
												<MenuItem key={zone.id} value={zone.id}>
													{zone.name}
												</MenuItem>
											))}
										</Field>
									</Grid>}
								<Grid item xs={12}>
									<Grid item xs={6}>
										<Field
											name="nom"
											component={FormInput}
											label={
												<FormattedMessage
													id="carrier.nom"
													defaultMessage="Nom"
													description="form input"
												/>
											}
											required
										/>
									</Grid>
								</Grid>
								<Grid item xs={12}>
									<Grid item xs={4}>
										<Field
											name="siret"
											component={FormInput}
											label={
												<FormattedMessage
													id="carrier.siret"
													defaultMessage="Siret"
													description="form input"
												/>
											}
										/>
									</Grid>
								</Grid>
								<Grid item xs={12} hidden={!isSuperAdmin}>
									<Grid item xs={4}>
										<Field
											name="idCentre"
											component={FormSelect}
											label={
												getCenterNameByType(intl, selectedZone, CENTER_TYPE.DISPATCH)
											}
											disabled={!isCreationForm}
											required
										>
											{dispatchCenters.map(center =>
												<MenuItem key={center.id} value={center.id}>
													{center.nom}
												</MenuItem>
											)}
										</Field>
									</Grid>
								</Grid>
								<Grid item xs={12}>
									<Typography variant={isLargeScreen ? 'h1' : 'h3'}>
										<FormattedMessage
											id="carrier.formSection.address"
											defaultMessage="Adresse"
											description="Form section title"
										/>
									</Typography>
								</Grid>
								<Grid item xs={12}>
									<Grid item xs={10}>
										<Field
											name="adresse"
											component={FormInput}
											label={
												<FormattedMessage
													id="carrier.adresse"
													defaultMessage="Adresse"
													description="form input"
												/>
											}
											required
										/>
									</Grid>
								</Grid>
								<Grid item xs={12}>
									<Grid item xs={10}>
										<Field
											name="complementAdresse"
											component={FormInput}
											label={
												<FormattedMessage
													id="carrier.complementAdresse"
													defaultMessage="Complément d'adresse"
													description="form input"
												/>
											}
										/>
									</Grid>
								</Grid>
								<Grid container item xs={12} spacing={2}>
									<Grid item xs={4}>
										<Field
											name="ville"
											component={FormInput}
											label={
												<FormattedMessage
													id="carrier.ville"
													defaultMessage="Ville"
													description="form input"
												/>
											}
											required
										/>
									</Grid>
									<Grid item xs={3}>
										<Field
											name="codePostal"
											component={FormInput}
											label={
												<FormattedMessage
													id="carrier.codePostal"
													defaultMessage="Code postal"
													description="form input"
												/>
											}
											required
											inputProps={{
												maxLength: 5
											}}
										/>
									</Grid>
								</Grid>
								<Grid item xs={12}>
									<Grid item xs={4}>
										<Field
											name="pays"
											component={FormInput}
											label={
												<FormattedMessage
													id="carrier.pays"
													defaultMessage="Pays"
													description="form input"
												/>
											}
											required
										/>
									</Grid>
								</Grid>
								<Grid item xs={12}>
									<Typography variant={isLargeScreen ? 'h1' : 'h3'}>
										<FormattedMessage
											id="carrier.formSection.coordinates"
											defaultMessage="Coordonnées"
											description="Form section title"
										/>
									</Typography>
								</Grid>
								<Grid container item xs={12} spacing={2}>
									<Grid item xs={4}>
										<Field
											name="telephone"
											component={FormInput}
											label={
												<FormattedMessage
													id="carrier.telephone"
													defaultMessage="Téléphone"
													description="form input"
												/>
											}
										/>
									</Grid>
									<Grid item xs={7}>
										<Field
											name="mailContact"
											component={FormInput}
											label={
												<FormattedMessage
													id="carrier.mailContact"
													defaultMessage="Email"
													description="form input"
												/>
											}
											required
										/>
									</Grid>
								</Grid>
								<div
									className={classes.buttonRoot}
									style={{ flexDirection: isLargeScreen ? 'row' : 'column' }}
								>
									{isCreationForm
										?
										<ButtonCreate
											type="submit"
											isLoading={submitting}
										/>
										:
										<ButtonSave
											type="submit"
											isLoading={submitting}
										/>
									}
									<ButtonActivate actif={initialValues?.actif} handleStatus={updateCarrierStatus} />
								</div>
								<OnChange name="zoneId">
									{(currentZoneId, previousZoneId) => {
										if (currentZoneId !== previousZoneId) {
											getAvailableDispatchCenters(currentZoneId).then(setDispatchCenters)
											form.change('idCentre', undefined)
										}
									}}
								</OnChange>
							</PageFormContainer>
						)
					}}
				/>
			}
		</>
	)
}

export default CarrierForm
