import React, { useContext, useEffect, useState } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { FormattedMessage, useIntl } from 'react-intl'
import AccueilTitle from './AccueilTitle'
import AccueilMenuTiles from './AccueilMenuTiles'
import {
	HOME_ADMIN_DATA,
	HOME_ADMIN_ZONE_DATA,
	HOME_DISPATCH_DATA,
	HOME_REFERENT_WITH_STOCK_DATA,
	HOME_REFERENT_WITHOUT_STOCK_DATA,
	HOME_VACCINATION_DATA,
	HOME_VACCINATION_POINT_DATA
} from '../../utils/home-menus/homeMenus'
import { CENTER_TYPE } from '../../utils/constants'
import { PhidemDataContext } from '../common/phidemData/PhidemDataContext'
import { getCenterNameByType } from '../../utils/stringUtils'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		titleContainer: {
			marginTop: theme.spacing(6),
			marginLeft: theme.spacing(4),
			marginBottom: theme.spacing(3),

			[theme.breakpoints.down('md')]: {
				marginTop: theme.spacing(6),
				marginLeft: theme.spacing(0),
				marginBottom: theme.spacing(3)
			}
		}
	})
)

const AccueilPage: React.FC = () => {
	const intl = useIntl()
	const classes = useStyles()
	const { user: { selectedCenter, isSuperAdmin, isZoneAdmin, selectedZone } } = useContext(PhidemDataContext)

	const [menuData, setMenuData] = useState<any | null>(null)
	const [title, setTitle] = useState<any | null>(null)

	useEffect(() => {
		let data
		let header

		if (isSuperAdmin) {
			data = HOME_ADMIN_DATA
			header = (
				<FormattedMessage
					id="menu.admin.home.title"
					defaultMessage="Administration Promedeo"
					description="Home title"
				/>
			)
		} else if (isZoneAdmin) {
			data = HOME_ADMIN_ZONE_DATA
			header = (
				<FormattedMessage
					id="menu.admin.home.zoneTitle"
					defaultMessage="Administration de zone"
					description="Home title"
				/>
			)
		} else {
			switch (selectedCenter.type) {
				case CENTER_TYPE.REFERENT:
					data = selectedCenter.gestionStock
						? HOME_REFERENT_WITH_STOCK_DATA
						: HOME_REFERENT_WITHOUT_STOCK_DATA
					header = (
						<FormattedMessage
							id="menu.referentCenter.home.title"
							defaultMessage={`${getCenterNameByType(intl, selectedZone, CENTER_TYPE.REFERENT)} ${selectedCenter.nom}`}
							description="Home title"
							values={{
								referentCenter: getCenterNameByType(intl, selectedZone, CENTER_TYPE.REFERENT),
								centerName: selectedCenter.nom
							}}
						/>
					)
					break
				case CENTER_TYPE.DISPATCH:
					data = HOME_DISPATCH_DATA
					header = (
						<FormattedMessage
							id="menu.dispatchCenter.home.title"
							defaultMessage={`${getCenterNameByType(intl, selectedZone, CENTER_TYPE.DISPATCH)} ${selectedCenter.nom}`}
							description="Home title"
							values={{
								dispatchCenter: getCenterNameByType(intl, selectedZone, CENTER_TYPE.DISPATCH),
								centerName: selectedCenter.nom
							}}
						/>
					)
					break
				case CENTER_TYPE.VACCINATION:
					data = HOME_VACCINATION_DATA
					header = (
						<FormattedMessage
							id="menu.vaccinationCenter.home.title"
							defaultMessage={`${getCenterNameByType(intl, selectedZone, CENTER_TYPE.VACCINATION)} ${selectedCenter.nom}`}
							description="Home title"
							values={{
								vaccinationCenter: getCenterNameByType(intl, selectedZone, CENTER_TYPE.VACCINATION),
								centerName: selectedCenter.nom
							}}
						/>
					)
					break
				case CENTER_TYPE.VACCINATION_POINT:
					data = HOME_VACCINATION_POINT_DATA
					header = (
						<FormattedMessage
							id="menu.vaccinationPoint.home.title"
							defaultMessage={`${getCenterNameByType(intl, selectedZone, CENTER_TYPE.VACCINATION_POINT)} ${selectedCenter.nom}`}
							description="Home title"
							values={{
								vaccinationPoint: getCenterNameByType(intl, selectedZone, CENTER_TYPE.VACCINATION_POINT),
								centerName: selectedCenter.nom
							}}
						/>
					)
					break
				default:
					data = { tiles: [] }
					header = ''
					break
			}
		}

		setMenuData(data)
		setTitle(header)
	}, [isSuperAdmin, isZoneAdmin, selectedCenter, intl, selectedZone])

	return (
		title && menuData ? (
			<div>
				<div className={classes.titleContainer}>
					<AccueilTitle text={title} />
				</div>
				<AccueilMenuTiles tiles={menuData.tiles} />
			</div>
		) : null
	)
}

export default AccueilPage
