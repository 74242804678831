import { fetchFactory } from 'isotope-client'
import { RequestCreateOrUpdateCenterDetailsModel } from './centerDetailsDataModel'

export const postCenterDetails = (values: RequestCreateOrUpdateCenterDetailsModel) => fetchFactory(`/centre`, {
	method: 'POST',
	body: JSON.stringify(values)
})

export const updateCenterDetails = (id: string, values: RequestCreateOrUpdateCenterDetailsModel) => fetchFactory(`/centre`, {
	method: 'PUT',
	body: JSON.stringify({
		...values,
		id
	})
})

export const updateCenterStatus = (id: string, status: boolean) => fetchFactory(`/centre/status`, {
	method: 'PUT',
	body: JSON.stringify({ status, id })
})

export const getCenterByID = (id: string) => fetchFactory(`/centre/${id}`)

export const getAvailableChildrenCenters = (requestedType: string, zoneId: string, existingCenter?: string) => fetchFactory(`/centre/available?centerType=${requestedType}&zoneId=${zoneId}${existingCenter ? `&existingCenter=${existingCenter}` : ''}`)

export const getAvailableDispatchCenters = (zoneId: string) => fetchFactory(`/centre/dispatch/${zoneId}`)

export const sendMailToVaccination = (title: string, body: string, idCentre: string) => fetchFactory(`/centre/mail?${new URLSearchParams({ idCentre })}`, {
	method: 'POST',
	body: JSON.stringify({ title, body })
})
