import { LOGOUT_SUCCESS } from 'isotope-client'
import * as actions from './sessionActions'

export interface SessionReducer {
    centerId?: string,
    zoneId?: string,
    centerType?: string,
    isLoading: boolean
}

const initialState: SessionReducer = {
    isLoading: true
}
export const session = (state: SessionReducer = initialState, action: any) => {
    switch (action.type) {
        case actions.SWITCH_CENTER:
            return {
                ...state,
                centerId: action.payload && action.payload.id,
                centerType: action.payload && action.payload.type,
                isLoading: false
            }
        case actions.INIT_ZONE:
            return {
                ...state,
                zoneId: action.payload && action.payload.id
            }
        case LOGOUT_SUCCESS: {
            actions.deleteStorageCenter()
            actions.deleteStorageZone()

            return initialState
        }

        default:
            return state
    }
}
