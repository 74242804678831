import { unflatten } from 'flat'
import { logOut } from 'isotope-client'
import { serializeFormValues } from 'isotope-client/utils/serialize'
import { getStorageCenter, getStorageZone, setStorageCenter, setStorageZone } from '../modules/common/session/sessionActions'
import { LOCAL_STORAGE_ITEM } from './constants'

const defaultAdminApi = '/api/admin'

let Store = null

export const saveStore = (createdStore) => {
	Store = createdStore
}

const transformConfig = (config) => {
	const centerId = localStorage.getItem(LOCAL_STORAGE_ITEM.CENTER)
	const zoneId = localStorage.getItem(LOCAL_STORAGE_ITEM.ZONE)
	if (!!zoneId) {
		return {
			...config,
			headers: {
				...config.headers,
				'ID_CENTRE': centerId,
				'ID_ZONE': zoneId
			}
		}
	}

	return config
}

/**
 * Convertit les messages d'erreur reçus du serveur au format Intl.
 *
 * Petite subtilité pour l'attribut `values` qui doit être un Object.
 *
 * @param error
 */
const convertError = error => ({
	id: error.code || '__ERROR__',
	ids: error.codes,
	defaultMessage: error.defaultMessage,
	values: Object.assign({}, error.arguments, {
		rejectedValue: error.rejectedValue
	})
})

const checkErrors = ([response, json]) => {
	if (response.status === 400) {
		// conversion des erreurs

		// erreurs globales
		const globalErrors = (json.globalErrors || [])
			.map(convertError)
			.reduce((previousValue, currentValue) => [...previousValue, currentValue], [])
		// erreurs sur les champs du formulaire

		const fieldErrors = (json.fieldErrors || [])
			.map(error => ({
				[error.field.replace(/\[(\d)]/g, '.$1')]: convertError(error)
			}))
			.reduce((previousValue, currentValue) => ({
				...previousValue,
				...currentValue
			}), {})

		return Promise.reject({
			_error: globalErrors,
			...unflatten(fieldErrors),
			response,
			bodyError: json
		})
	}
	return json
}

const handleResponse = (response) => {
	// vérification du token
	if (response.status === 401) {
		// si le token n'est pas valide...
		return response.text().then(result => {
			// on déconnecte l'utilisateur
			Store.dispatch(logOut())
			// et on rejette l'appel
			return Promise.reject(result)
		})
	}
	if (response.status === 204 || response.status === 304) {
		// no content || not modifies
		return Promise.resolve()
	}
	if (!response.ok && response.status !== 400) {
		// si la réponse est en erreur sauf erreur de validation, on rejette l'appel
		return Promise.reject(response)
	}
	// dans tous les autres cas, la réponse est traitée en JSON
	return Promise.all([response, response.json()])
		.then(checkErrors)
}

export const fetchFactory = (path, config = {}, apiUrl = defaultAdminApi) => {
	const {
		headers,
		...others
	} = transformConfig(config)
	const token = localStorage.getItem(LOCAL_STORAGE_ITEM.TOKEN) || null
	const defaultHeaders = {
		Accept: 'application/json',
		'Content-Type': 'application/json;charset=utf-8'
	}
	if (token) {
		defaultHeaders['Authorization'] = token
	}
	const newConfig = {
		headers: {
			...defaultHeaders,
			...headers
		},
		...others
	}
	return fetch(`${process.env.REACT_APP_SERVER_URL}${apiUrl}${path}`, newConfig)
		.then(handleResponse)
}

export const postMultipart = (path, config = {}, apiUrl = defaultAdminApi) => {
	const token = localStorage.getItem(LOCAL_STORAGE_ITEM.TOKEN) || null
	const {
		body,
		headers,
		...others
	} = transformConfig(config)
	const newConfig = {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Authorization': token,
			...headers
		},
		body: body instanceof FormData ? body : serializeFormValues(body),
		...others
	}
	return fetch(`${process.env.REACT_APP_SERVER_URL}${apiUrl}${path}`, newConfig)
		.then(handleResponse)
}

export const fetchUser = (token) =>
	fetch(`${process.env.REACT_APP_SERVER_URL}/auth/user`, {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json; charset=utf-8',
			Authorization: token
		}
	})
		.then(response => {
			if (response.ok) {
				const result = response.json()
				return result
					.then(user => {
						const storageCenter = getStorageCenter()
						if (!storageCenter || storageCenter.length === 0) {
							setStorageCenter(user.idCentre)
						}
						const storageZone = getStorageZone()
						if (!storageZone || storageZone.length === 0) {
							setStorageZone(user.idZone)
						}

						return Promise.resolve(user)
					})
			}
			return
		})


export const refresh = (token) =>
	fetch(`${process.env.REACT_APP_SERVER_URL}/auth/refresh`, {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json; charset=utf-8',
			Authorization: token
		}
	})
		.then(response => {
			if (response.ok) {
				return response.json()
			}
			return
		})

export const login = (data) => {
	const { login: username, password, rememberMe } = data
	return fetch(`${process.env.REACT_APP_SERVER_URL}/auth/login`, {
		method: 'POST',
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({
			username,
			password,
			rememberMe
		})
	})
		.then(response => {
			if (response.ok) {
				return response.json()
			}
			return
		})
}
