import React, { useEffect, useRef } from 'react'
import { Field, Form } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import MenuItem from '@material-ui/core/MenuItem'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { FormattedMessage } from 'react-intl'
import { FormInput, FormSelect, FormSwitch, MultipleChoice } from 'isotope-client'
import PageFormContainer from '../../../../components/layout/PageFormContainer'
import ErrorMessageRequired from '../../../../components/layout/errors/ErrorMessageRequired'
import { SCREEN_SIZE } from '../../../../utils/constants'
import Loader from '../../../../components/layout/Loader'
import { RequestCreateOrUpdateZoneModel, ZoneLanguageConfigModel } from '../services/zoneDataModel'
import ButtonCreate from '../../../../components/layout/buttons/ButtonCreate'
import ButtonSave from '../../../../components/layout/buttons/ButtonSave'
import { languageChoices, timeZonesList } from '../services/zoneConsts'
import arrayMutators from 'final-form-arrays'
import ButtonActivate from '../../../../components/layout/buttons/ButtonActivate'
import { getLanguageLibelle } from '../services/zoneUtils'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		buttonRoot: {
			width: '100%',
			display: 'flex',
			justifyContent: 'space-evenly'
		},
		rowContainer: {
			margin: 0
		},
		tags: {
			marginBottom: 12
		},
		tag: {
			margin: theme.spacing(1)
		},
		itemsContainer: {
			display: 'flex',
			flexDirection: 'row',
			flexWrap: 'wrap'
		},
		missingCenterType: {
			marginBottom: 80
		},
		subSectionTitle: {
			textDecoration: 'underline'
		},
		templatePreferenceContainer: {
			justifyContent: 'space-between',
			alignItems: 'center'
		}
	})
)

interface ZoneDetailsFormProps {
	isLoading?: boolean,
	initialValues: RequestCreateOrUpdateZoneModel,
	onSubmit: (event: any) => void,
	handleUpdateZoneStatus?: () => void,
	isCreationForm?: boolean,
	canUpdateStatus?: boolean
}

const ZoneDetailsForm: React.FC<ZoneDetailsFormProps> = (
	{
		isLoading = false,
		initialValues,
		onSubmit,
		isCreationForm = false,
		canUpdateStatus = false,
		handleUpdateZoneStatus
	}) => {
	const classes = useStyles()
	const isLargeScreen = useMediaQuery(`(min-width: ${SCREEN_SIZE.LARGE}px)`)

	const onValidate = (formValues: RequestCreateOrUpdateZoneModel) => {
		const errors: any = {}

		if (!formValues.name) {
			errors.name = <ErrorMessageRequired />
		}
		if (!formValues.timezone) {
			errors.timezone = <ErrorMessageRequired />
		}
		if (!formValues.defaultLanguageId) {
			errors.defaultLanguageId = <ErrorMessageRequired />
		}

		return errors
	}

	const formValuesToModel = (formValues: RequestCreateOrUpdateZoneModel) => ({
		id: formValues?.id,
		name: formValues.name,
		timezone: formValues.timezone,
		defaultLanguageId: formValues.defaultLanguageId,
		languagesId: formValues.languagesId?.map((language) => language.id),
		configZoneLanguages: formValues.configZoneLanguages,
		besoinCouverture: formValues.besoinCouverture,
		besoinDoses: formValues.besoinDoses,
		preparationTransfert: formValues.preparationTransfert,
		active: formValues.active
	})

	const handleOnSubmit = (formValues: RequestCreateOrUpdateZoneModel) => {
		return onSubmit(formValuesToModel(formValues))
	}

	const ZoneDetailsFormComponent: React.FC<any> = ({ handleSubmit, form, submitting, values }) => {
		const previousSize = useRef(values?.languagesId?.length)

		useEffect(() => {
			const unsubscribe = form.subscribe(
				({ values: { languagesId, configZoneLanguages } }: any) => {
					if (languagesId?.length !== previousSize.current) {
						// Reset defaultLanguageId when the number of languages changes
						form.change('defaultLanguageId', '')

						// Add new languages to configZoneLanguages or remove them as needed
						const updatedConfigZoneLanguages = [...configZoneLanguages]

						// Adding missing languages to configZoneLanguages
						languagesId.forEach((languageId: any) => {
							// Check if the languageId is not already in configZoneLanguages
							if (!updatedConfigZoneLanguages.some((item: ZoneLanguageConfigModel) => item.idLanguage === languageId.id)) {
								updatedConfigZoneLanguages.push({
									idLanguage: languageId.id,
									centreReferentName: languageId.id === languageChoices[0].id ? 'Centre Référent' : 'Referral Centre',
									centreDispatchName: languageId.id === languageChoices[0].id ? 'Centre de dispatch' : 'Dispatch Centre',
									centreVaccinationName: languageId.id === languageChoices[0].id ? 'Centre de vaccination' : 'Vaccination Centre',
									centreVaccinationPointName: languageId.id === languageChoices[0].id ? 'Point de vaccination' : 'Vaccination Point'
								})
							}
						})

						const filteredConfigZoneLanguages = updatedConfigZoneLanguages.filter((item) =>
							languagesId.some((language: { id: string }) => language.id === item.idLanguage)
						)

						form.change('configZoneLanguages', filteredConfigZoneLanguages)

						previousSize.current = languagesId?.length
					}
				},
				{ values: true }
			)

			return () => {
				unsubscribe()
			}
		}, [form])

		return (
			<PageFormContainer onSubmit={handleSubmit}>
				<Grid item xs={12}>
					<Typography variant={isLargeScreen ? 'h1' : 'h3'}>
						<FormattedMessage
							id="zone.formSection.information"
							defaultMessage="Informations de la zone"
							description="Form section title"
						/>
					</Typography>
				</Grid>
				<Grid item xs={7} md={5}>
					<Field
						name="name"
						component={FormInput}
						label={
							<FormattedMessage
								id="zone.nom"
								defaultMessage="Nom de la zone"
								description="Zone name"
							/>
						}
						required
					/>
				</Grid>
				<Grid item xs={8} md={8}>
					<Field
						name="languagesId"
						component={MultipleChoice}
						fullWidth
						choices={languageChoices}
						getLabelElement={(codeLang: any) =>
							<FormattedMessage
								id={`global.languages.${codeLang.libelle}`}
								defaultMessage={`${codeLang.libelle}`}
								description="Langue label"
							/>
						}
						label={
							<FormattedMessage
								id="zone.languages"
								defaultMessage="Langues activées"
								description="Enabled languages label"
							/>
						}
					/>
					<Field
						name="defaultLanguageId"
						component={FormSelect}
						fullWidth
						required
						label={
							<FormattedMessage
								id="zone.defaultLanguage"
								defaultMessage="Langue par défaut"
								description="Default Language Label"
							/>
						}
					>
						{values?.languagesId?.map((language: any) => (
							<MenuItem key={`defaultLanguageId-${language.libelle}`} value={language.id}>
								<FormattedMessage
									id={`global.languages.${language.libelle}`}
									defaultMessage={`${language.libelle}`}
									description="Langue label"
								/>
							</MenuItem>
						))}
					</Field>
				</Grid>
				<Grid container spacing={2} className={classes.rowContainer}>
					<Grid item xs={7} md={5}>
						<Field
							name="timezone"
							component={FormSelect}
							label={
								<FormattedMessage
									id="zone.timezone"
									defaultMessage="Timezone"
									description="Zone timezone"
								/>
							}
							required
							disabled={!isCreationForm}
						>
							{timeZonesList.map((timezone: string) => (
								<MenuItem key={timezone} value={timezone}>
									{timezone}
								</MenuItem>
							))}
						</Field>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<Typography variant={isLargeScreen ? 'h1' : 'h3'}>
						<FormattedMessage
							id="zone.formSection.config"
							defaultMessage="Configuration de la zone"
							description="Form section title"
						/>
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Grid item xs={7} md={4}>
						<Field
							name="besoinCouverture"
							component={FormSwitch}
							label={
								<FormattedMessage
									id="zone.besoinCouverture"
									defaultMessage="Besoin Couverture"
									description="Need couverture label"
								/>
							}
						/>
					</Grid>
					<Grid item xs={7} md={4}>
						<Field
							name="besoinDoses"
							component={FormSwitch}
							label={
								<FormattedMessage
									id="zone.besoinDoses"
									defaultMessage="Besoin Doses"
									description="Besoin Doses label"
								/>
							}
						/>
					</Grid>
					<Grid item xs={7} md={4}>
						<Field
							name="preparationTransfert"
							component={FormSwitch}
							label={
								<FormattedMessage
									id="zone.preparationTransfert"
									defaultMessage="Preparation Transfert"
									description="Preparation transfert label"
								/>
							}
						/>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<Typography variant={isLargeScreen ? 'h1' : 'h3'}>
						<FormattedMessage
							id="zone.formSection.namingCenter"
							defaultMessage="Nommage des centres"
							description="Form section title"
						/>
					</Typography>
				</Grid>
				<FieldArray name="configZoneLanguages">
					{({ fields }) => (
						<div>
							{fields.map((name, index) => (
								<Grid container key={name} spacing={2}>
									<Grid item xs={12}>
										<Typography variant={isLargeScreen ? 'h3' : 'h4'}>
											<FormattedMessage
												id={`global.languages.${getLanguageLibelle(fields.value[index]?.idLanguage)}`}
												defaultMessage={`${getLanguageLibelle(fields.value[index]?.idLanguage)}`}
												description="Langue label"
											/>
										</Typography>
									</Grid>
									<Grid item xs={7} md={5}>
										<Field
											name={`${name}.centreReferentName`}
											component={FormInput}
											required
											label={
												<FormattedMessage
													id="zone.centreReferentName"
													defaultMessage="Nom de centre référent"
													description="Referent Name label"
												/>
											}
										/>
									</Grid>
									<Grid item xs={7} md={5}>
										<Field
											name={`${name}.centreDispatchName`}
											component={FormInput}
											required
											label={
												<FormattedMessage
													id="zone.centreDispatchName"
													defaultMessage="Nom de centre de dispatch"
													description="Dispatch Name label"
												/>
											}
										/>
									</Grid>
									<Grid item xs={7} md={5}>
										<Field
											name={`${name}.centreVaccinationName`}
											component={FormInput}
											required
											label={
												<FormattedMessage
													id="zone.centreVaccinationName"
													defaultMessage="Nom de centre de vaccination"
													description="Centre Vaccination Name label"
												/>
											}
										/>
									</Grid>
									<Grid item xs={7} md={5}>
										<Field
											name={`${name}.centreVaccinationPointName`}
											component={FormInput}
											required
											label={
												<FormattedMessage
													id="zone.pointVaccinationName"
													defaultMessage="Nom de point de vaccination"
													description="Point Vaccination Name label"
												/>
											}
										/>
									</Grid>
								</Grid>
							))}
						</div>
					)}
				</FieldArray>
				<div
					className={classes.buttonRoot}
					style={
						isLargeScreen
							?
							{ flexDirection: 'row' }
							:
							{ flexDirection: 'column' }
					}
				>
					{isCreationForm ? (
						<ButtonCreate type="submit" isLoading={submitting} />
					) : (
						<ButtonSave type="submit" isLoading={submitting} />
					)}
					{canUpdateStatus && (
						<ButtonActivate
							handleStatus={handleUpdateZoneStatus}
							actif={initialValues.active}
						/>
					)}
				</div>
			</PageFormContainer>
		)
	}

	return (
		<>
			{(isLoading)
				?
				<Loader />
				:
				<Form
					mutators={{
						...arrayMutators
					}}
					initialValues={initialValues}
					onSubmit={handleOnSubmit}
					validate={onValidate}
					component={ZoneDetailsFormComponent}
				/>
			}
		</>
	)
}

export default ZoneDetailsForm
