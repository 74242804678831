import { fetchFactory } from 'isotope-client'
import { RequestCreateOrUpdateZoneModel } from './zoneDataModel'

/**
 * Get Zone by id
 * @param id
 */
export const getZoneById = (id: string) => fetchFactory(`/zone/${id}`)

/**
 * Get All zones in list
 */
export const getAllZones = () => fetchFactory(`/zone/list`)

/**
 * Update zone status
 *
 * @param id
 * @param status
 */
export const updateZoneStatus = (id: string, status: boolean) => fetchFactory(`/zone/status`, {
	method: 'PUT',
	body: JSON.stringify({ status, id })
})

/**
 * Create zone
 * @param values
 */
export const postZoneDetails = (values: RequestCreateOrUpdateZoneModel) => fetchFactory(`/zone`, {
	method: 'POST',
	body: JSON.stringify(values)
})

/**
 * Update zone
 * @param id
 * @param values
 */
export const updateZoneDetails = (id: string, values: RequestCreateOrUpdateZoneModel) => fetchFactory(`/zone/${id}`, {
	method: 'PUT',
	body: JSON.stringify({
		...values,
		id
	})
})

/**
 * Get my zone
 */
export const getMyZone = () => fetchFactory('/zone/me')