import React from 'react'
import { Checkbox } from '@material-ui/core'

type FormCheckboxMenuProps = {
	input: any
	disabled?: boolean
}

const FormCheckboxMenu: React.FC<FormCheckboxMenuProps> = ({ input, disabled }) => {
	return (
		<Checkbox
			color="primary"
			disabled={disabled || false}
			checked={input.value || false}
			onChange={(event) => input.onChange(event.target.checked)}
		/>
	)
}

export default FormCheckboxMenu