import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Search from '@material-ui/icons/Search'
import { FormattedMessage } from 'react-intl'
import { colors } from '../../utils/constants'

const useStyles = makeStyles(() =>
    ({
        wrapper: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            color: colors.notFound,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
        },
        icon: {
            display: 'block',
            padding: '2em 0',
            width: '5em',
            height: '5em',
            color: colors.notFound
        },
        message: {
            fontSize: '1em'
        }
    })
)

const NotFoundPage: React.FC = () => {
    const classes = useStyles()

    return (
        <div className={classes.wrapper}>
            <Search className={classes.icon} />
            <span className={classes.message}>
				<FormattedMessage
                    id="global.error.notFound"
                    defaultMessage="Aucun résultat trouvé"
                    description="Not found message"
                />
			</span>
        </div>
    )
}

export default NotFoundPage
