import { ProfileMenu, ToolbarContext } from 'isotope-client'
import 'moment/locale/fr'
import React, { useContext, useMemo } from 'react'
import { Link } from 'react-router-dom'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import LanguageSelector from '../config/messages/LanguageSelector'
import AppRoutes from './AppRoutes'
import Typography from '@material-ui/core/Typography'
import { PhidemDataContext } from '../modules/common/phidemData/PhidemDataContext'

const PhidemContent = ({ location, session }) => {
	const { user: { selectedZone } } = useContext(PhidemDataContext)

	// UseMemo to prevent unnecessary renders
	const toolbarValue = useMemo(() => ({
		children: [
			<Typography
				key="zone-name"
				style={{
					color: '#FFFFFF',
					marginRight: '8px',
					fontSize: '16px',
					fontFamily: 'Roboto',
					fontWeight: 'normal',
				}}
			>
				{selectedZone?.name || ''}
			</Typography>,
			<Link key="notice" to="/notice">
				<HelpOutlineIcon style={{ color: '#FFFFFF', marginRight: 10 }} />
			</Link>,
			<LanguageSelector key="language" />,
			<ProfileMenu key="profile" />
		]
	}), [selectedZone])

	return (
		<ToolbarContext.Provider value={toolbarValue}>
			<AppRoutes location={location} session={session} />
		</ToolbarContext.Provider>
	)
}

export default PhidemContent