import React, { useState } from 'react'
import { dataTableActions, FormInput, FormSelect, SpringDataTable } from 'isotope-client'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { injectActions as injectSnackbarActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import { useHistory } from 'react-router-dom'
import RedirectFAB from '../RedirectFAB'
import NotFoundPage from '../../../components/layout/NotFoundPage'
import { RequestZoneFiltersModel, ZONE_STATUS, ZoneModel } from './services/zoneDataModel'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { Field, Form } from 'react-final-form'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '../../../components/layout/buttons/Button'
import { Search } from '@material-ui/icons'
import Paper from '@material-ui/core/Paper'
import { updateZoneStatus } from './services/zoneApi'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		paperForm: {
			width: '70%',
			minWidth: 300,
			maxWidth: 880,
			padding: theme.spacing(2),
			marginBottom: 50
		},
		submitButtonRoot: {
			width: '100%',
			margin: theme.spacing(1),
			display: 'flex',
			justifyContent: 'flex-end'
		}
	})
)

interface ZoneListProps {
	refreshTable: () => void,
	snackSuccess: (value: any) => void,
	snackError: (value: any) => void,
}

const ZoneList: React.FC<ZoneListProps> = (
	{
		refreshTable,
		snackSuccess,
		snackError
	}
) => {
	const classes = useStyles()
	const history = useHistory()
	const [filters, setFilters] = useState<RequestZoneFiltersModel>({
		name: undefined,
		statut: ZONE_STATUS.INDIFFERENT
	})

	const getZones = (formValues: RequestZoneFiltersModel) => {
		setFilters({
			name: formValues.name,
			statut: formValues.statut
		})
	}

	const handleZoneStatus = (id: string, status: boolean) => {
		updateZoneStatus(id, status)
			.then(() => {
				refreshTable()
				snackSuccess({ id: 'zone.updateStatusSuccess', defaultMessage: 'Le statut de la zone a bien été modifié', description: 'Success message' })
			})
			.catch(() => snackError({
				id: 'zone.updateStatusError',
				defaultMessage: 'Une erreur est survenue, le statut de la zone n\'a pas été modifié',
				description: 'Error message'
			}))
	}

	return (
		<>
			<Paper className={classes.paperForm}>
				<Form
					initialValues={{
						statut: ZONE_STATUS.INDIFFERENT
					}}
					onSubmit={getZones}
					// @ts-ignore
					render={({ handleSubmit, submitting }) => (
						<form onSubmit={handleSubmit}>
							<Grid
								container
								direction="row"
								justify="flex-start"
								spacing={2}
							>
								<Grid item xs={12} md={4}>
									<Field
										name="name"
										component={FormInput}
										label={
											<FormattedMessage
												id="zone.name"
												defaultMessage="Nom"
												description="Zone name"
											/>
										}
									/>
								</Grid>
								<Grid item xs={12} md={3}>
									<Field
										name="statut"
										component={FormSelect}
										label={
											<FormattedMessage
												id="zone.status"
												defaultMessage="Statut"
												description="Status"
											/>
										}
									>
										<MenuItem key="default" value={ZONE_STATUS.INDIFFERENT}>
											<FormattedMessage
												id="global.status.indifferent"
												defaultMessage="Indifférent"
												description="Indifferent"
											/>
										</MenuItem>
										<MenuItem
											key={ZONE_STATUS.ACTIVE}
											value={ZONE_STATUS.ACTIVE}
										>
											<FormattedMessage
												id="global.status.enabled"
												defaultMessage="Actif"
												description="Form select value"
											/>
										</MenuItem>
										<MenuItem
											key={ZONE_STATUS.INACTIVE}
											value={ZONE_STATUS.INACTIVE}
										>
											<FormattedMessage
												id="global.status.disabled"
												defaultMessage="Inactif"
												description="Form select value"
											/>
										</MenuItem>
									</Field>
								</Grid>
								<Grid item xs={12} md={12}>
									<div className={classes.submitButtonRoot}>
										<Button
											variant="contained"
											startIcon={<Search />}
											type="submit"
											isLoading={submitting}
										>
											<FormattedMessage
												id="button.search"
												defaultMessage="Rechercher"
												description="Search button label"
											/>
										</Button>
									</div>
								</Grid>
							</Grid>
						</form>
					)} />
			</Paper>
			<RedirectFAB redirectPath="/zones/add">
				<SpringDataTable
					apiUrl="/zone"
					nom="zoneList"
					showActions
					filters={filters}
					headers={[
						{
							key: 'name',
							name: <FormattedMessage
								id="zone.name"
								defaultMessage="Nom"
								description="Header column name"
							/>,
							sortable: true
						},
						{
							key: 'timezone',
							name: <FormattedMessage
								id="zone.timezone"
								defaultMessage="Timezone"
								description="Header column timezone"
							/>,
							sortable: true
						},
						{
							key: 'defaultLanguage.name',
							name: <FormattedMessage
								id="zone.defaultLanguage"
								defaultMessage="Langue par défaut"
								description="Header column default language"
							/>,
							render: (row: ZoneModel) => <FormattedMessage
								id={`global.languages.${row.defaultLanguage.codeLang}`}
								defaultMessage={`${row.defaultLanguage.codeLang}`}
								description="Langue label"
							/>,
							sortable: true
						},
						{
							key: 'active',
							name: <FormattedMessage
								id="zone.status"
								defaultMessage="Statut"
								description="Header column status"
							/>,
							sortable: true,
							render: (row: ZoneModel) => row.active ? <FormattedMessage
								id="global.status.enabled"
								defaultMessage="Actif"
								description="Zone status"
							/> : <FormattedMessage
								id="global.status.disabled"
								defaultMessage="Inactif"
								description="Zone status"
							/>

						}
					]}
					noResultFragment={<NotFoundPage />}
					makeActions={(row: ZoneModel) => {
						const options = [
							{
								label: <FormattedMessage
									id="global.update"
									defaultMessage="Modifier"
									description="Update action"
								/>, action: () => {
									history.push(`/zones/${row.id}`)
								}
							}
						]
						options.push({
							label: row.active ? <FormattedMessage
								id="global.disable"
								defaultMessage="Désactiver"
								description="Disable action"
							/> : <FormattedMessage
								id="global.enable"
								defaultMessage="Activer"
								description="Enable action"
							/>, action: () => handleZoneStatus(row.id, !row.active)
						})
						return options
					}}
				/>
			</RedirectFAB>
		</>
	)
}

const actions = {
	refreshTable: () => dataTableActions.refresh('zoneList')
}

export default compose<any>(
	connect(null, actions),
	injectSnackbarActions
)(ZoneList)
