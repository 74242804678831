import React, { useContext, useEffect, useState } from 'react'
import Paper from '@material-ui/core/Paper'
import { Field, Form } from 'react-final-form'
import Grid from '@material-ui/core/Grid'
import { Search } from '@material-ui/icons'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { FormattedMessage } from 'react-intl'
import { dataTableActions, FormInput, FormSelect, FormSwitch, SpringDataTable } from 'isotope-client'
import MenuItem from '@material-ui/core/MenuItem'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import { compose } from 'redux'
import { injectActions as injectSnackbarActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import { connect } from 'react-redux'
import PageLayout from '../../../components/layout/PageLayout'
import Button from '../../../components/layout/buttons/Button'
import { SELECT_VALUES, TEMPLATE_TYPES } from '../../../utils/constants'
import NotFoundPage from '../../../components/layout/NotFoundPage'
import RedirectFAB from '../RedirectFAB'
import { TemplateListRequestModel, TemplateRowModel } from './services/templateModels'
import { getTemplateDocument, testTemplate, updateTemplateStatus } from './services/templateApi'
import { PhidemDataContext } from '../../common/phidemData/PhidemDataContext'
import { REFERENTIEL } from '../../common/phidemData/phidemDataModel'
import { DATE_FORMAT, displayDate } from '../../../utils/dateUtils'
import ListSubheader from '@material-ui/core/ListSubheader'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		paperForm: {
			width: '70%',
			minWidth: 300,
			maxWidth: 880,
			padding: theme.spacing(2),
			marginBottom: 50
		},
		submitButtonRoot: {
			width: '100%',
			margin: theme.spacing(1),
			display: 'flex',
			justifyContent: 'flex-end'
		}
	})
)

interface FormValues {
	nom?: string
	type?: string
	zoneId?: string
	publie: boolean
}

interface TemplateListProps {
	snackSuccess: (value: any) => void
	snackError: (value: any) => void
	refreshTable: () => void
}

const TemplateList: React.FC<TemplateListProps> = (
	{
		snackSuccess,
		snackError,
		refreshTable
	}
) => {
	const classes = useStyles()
	const history = useHistory()
	const { reloadReferentiel, user: { isDateFormatEn, isSuperAdmin }, zones } = useContext(PhidemDataContext)
	const [filters, setFilters] = useState<TemplateListRequestModel>({
		nom: undefined,
		zoneId: zones.length > 0 ? zones[0]?.id : undefined,
		type: undefined,
		publie: true
	})

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			setFilters({
				...filters,
				zoneId: zones.length > 0 ? zones[0]?.id : undefined
			})
		}, 500)

		return () => clearTimeout(timeoutId)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [zones])

	const renderTemplatesItems = (templates: string[]) => {
		return templates.map((templateType: string) => (
			<MenuItem
				key={templateType}
				value={templateType}
			>
				<FormattedMessage
					id={`enum.templateTypes.${templateType}`}
					defaultMessage={templateType}
					description="Template type"
				/>
			</MenuItem>
		))
	}

	const getHeaders = () => {
		const headers = [
			{
				key: 'nom',
				name: <FormattedMessage
					id="template.fieldsLabel.nom"
					defaultMessage="Nom"
					description="Header column name"
				/>,
				sortable: true,
				render: (row: TemplateRowModel) => <span>{row.nom}</span>
			},
			{
				key: 'type',
				name: <FormattedMessage
					id="template.fieldsLabel.type"
					defaultMessage="Type"
					description="Header column name"
				/>,
				sortable: true,
				render: (row: TemplateRowModel) => <span>
                <FormattedMessage
	                id={`enum.templateTypes.${row.type}`}
	                defaultMessage={row.type}
	                description="Template type"
                />
            </span>
			}
		]
		if (!filters.publie) {
			headers.push({
				key: 'publie',
				name: <FormattedMessage
					id="template.fieldsLabel.statut"
					defaultMessage="Statut"
					description="Header column name"
				/>,
				sortable: true,
				render: (row: TemplateRowModel) => row.publie ?
					<span>
                    <FormattedMessage
	                    id="template.fieldsLabel.published"
	                    defaultMessage="Publié"
	                    description="Header column name"
                    />
                </span>
					: <span>
                    <FormattedMessage
	                    id="template.fieldsLabel.unpublished"
	                    defaultMessage="Masqué"
	                    description="Header column name"
                    />
                </span>
			})
		}

		headers.push({
				key: 'history.creationDate',
				name: <FormattedMessage
					id="template.fieldsLabel.creationDate"
					defaultMessage="Date de création"
					description="Header column name"
				/>,
				sortable: true,
				render: (row: TemplateRowModel) => <span>{displayDate(moment(row.history.creationDate), DATE_FORMAT.DATE, isDateFormatEn)}</span>
			},
			{
				key: 'history.creationUser',
				name: <FormattedMessage
					id="template.fieldsLabel.creator"
					defaultMessage="Créateur"
					description="Header column name"
				/>,
				sortable: true,
				render: (row: TemplateRowModel) => <span>{row.history.creationUser}</span>
			})

		return headers
	}

	const getActions = (row: TemplateRowModel) => [
		{
			label: <FormattedMessage
				id="global.update"
				defaultMessage="Modifier"
				description="Update action"
			/>, action: () => {
				history.push(`/templates/${row.id}`)
			}
		},
		{
			label: row.publie ?
				<FormattedMessage
					id="global.unpublish"
					defaultMessage="Masquer"
					description="Unpublish action"
				/>
				:
				<FormattedMessage
					id="global.publish"
					defaultMessage="Publier"
					description="Publish action"
				/>,
			action: () => updateTemplateStatus({
				id: row.id,
				status: !row.publie
			})
				.then(() => {
					refreshTable()
					reloadReferentiel(REFERENTIEL.TEMPLATE)
					snackSuccess({ id: 'template.updateStatusSuccess', defaultMessage: 'Le statut du template a bien été modifié', description: 'Success message' })
				})
				.catch(() => snackError({
					id: 'template.updateStatusError',
					defaultMessage: 'Une erreur est survenue, le statut du template n\'a pas pu être modifié',
					description: 'Error message'
				}))
		},
		{
			label: <FormattedMessage
				id="global.test"
				defaultMessage="Tester"
				description="Test action"
			/>, action: () => {
				testTemplate(row.id)
					.then(
						(blob: any) => {
							const url = window.URL.createObjectURL(blob)
							const a = document.createElement('a')
							a.href = url
							a.download = 'test_template.pdf'
							a.click()
						}
					)
					.catch(() => snackError({ id: 'global.error.downloadFailed', defaultMessage: 'Le téléchargement a échoué', description: 'Error message' }))
			}
		},
		{
			label: <FormattedMessage
				id="global.download"
				defaultMessage="Télécharger"
				description="Download action"
			/>, action: () => {
				getTemplateDocument(row.id)
					.then(
						(blob: any) => {
							const url = window.URL.createObjectURL(blob)
							const a = document.createElement('a')
							a.href = url
							a.download = `${row.nom}.docx`
							a.click()
						}
					)
					.catch(() => snackError({ id: 'global.error.downloadFailed', defaultMessage: 'Le téléchargement a échoué', description: 'Error message' }))
			}
		}
	]

	const onSubmit = (formValues: FormValues) => {
		setFilters({
			nom: formValues.nom,
			zoneId: formValues.zoneId,
			type: formValues.type === SELECT_VALUES.ALL ? undefined : formValues.type,
			publie: formValues.publie
		})
	}

	return (
		<PageLayout>
			<Paper className={classes.paperForm}>
				<Form
					initialValues={{
						type: SELECT_VALUES.ALL,
						zoneId: zones.length > 0 ? zones[0]?.id : undefined,
						publie: true
					}}
					onSubmit={onSubmit}
					render={({ handleSubmit, submitting }) => (
						<form onSubmit={handleSubmit}>
							<Grid
								container
								direction="row"
								justify="flex-start"
								spacing={2}
							>
								<Grid item xs={12} md={4}>
									<Field
										name="nom"
										component={FormInput}
										label={<FormattedMessage
											id="template.fieldsLabel.nom"
											defaultMessage="Nom"
											description="Input label"
										/>}
									/>
								</Grid>

								<Grid item xs={12} md={4}>
									<Field
										name="type"
										component={FormSelect}
										label={
											<FormattedMessage
												id="template.fieldsLabel.type"
												defaultMessage="Type"
												description="Input label"
											/>
										}
									>
										<MenuItem key="default" value={SELECT_VALUES.ALL}>
											<FormattedMessage
												id="select.all"
												defaultMessage="Tous"
												description="All"
											/>
										</MenuItem>
										<ListSubheader><FormattedMessage id="template.category.referent" defaultMessage="Templates référent" description="Template type category" /></ListSubheader>
										{renderTemplatesItems(Object.values(TEMPLATE_TYPES.REFERENT))}
										<ListSubheader><FormattedMessage id="template.category.dispatch" defaultMessage="Templates dispatch" description="Template type category" /></ListSubheader>
										{renderTemplatesItems(Object.values(TEMPLATE_TYPES.DISPATCH))}
										<ListSubheader><FormattedMessage id="template.category.vaccination" defaultMessage="Templates vaccination"
										                                 description="Template type category" /></ListSubheader>
										{renderTemplatesItems(Object.values(TEMPLATE_TYPES.VACCINATION))}
									</Field>
								</Grid>
								{isSuperAdmin && <Grid item xs={12} md={4}>
									<Field
										name="zoneId"
										component={FormSelect}
										label={
											<FormattedMessage
												id="product.Zone"
												defaultMessage="Zone"
												description="Zone"
											/>
										}
									>
										{zones.map((zone) => (
											<MenuItem key={zone.id} value={zone.id}>
												{zone.name}
											</MenuItem>
										))}
									</Field>
								</Grid>}
								<Grid item xs={12}>
									<Field
										name="publie"
										component={FormSwitch}
										label={
											<FormattedMessage
												id="template.fieldsLabel.publie"
												defaultMessage="Afficher uniquement les templates publiés"
												description="Switch description"
											/>
										}
									/>
								</Grid>
								<div className={classes.submitButtonRoot}>
									<Button
										variant="contained"
										startIcon={<Search />}
										type="submit"
										isLoading={submitting}
									>
										<FormattedMessage
											id="button.search"
											defaultMessage="Rechercher"
											description="Search button label"
										/>
									</Button>
								</div>
							</Grid>
						</form>
					)}
				/>
			</Paper>
			<RedirectFAB
				redirectPath="/templates/add"
			>
				<SpringDataTable
					apiUrl="/template"
					nom="templateList"
					headers={getHeaders()}
					filters={filters}
					noResultFragment={<NotFoundPage />}
					makeActions={getActions}
					showActions
				/>
			</RedirectFAB>
		</PageLayout>
	)
}

const actions = {
	refreshTable: () => dataTableActions.refresh('templateList')
}

export default compose<any>(
	connect(null, actions),
	injectSnackbarActions
)(TemplateList)
