import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import moment from 'moment'
import { FormattedMessage, useIntl } from 'react-intl'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Tooltip from '@material-ui/core/Tooltip'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { Assignment, Cancel, CheckCircle, HourglassEmpty, LocalShipping, Search } from '@material-ui/icons'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import { dataTableActions, FormInput, FormSelect, FormSwitch, SpringDataTable } from 'isotope-client'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Field, Form } from 'react-final-form'
import { OnChange } from 'react-final-form-listeners'
import { FormApi } from 'final-form'
import { injectActions as injectSnackbarActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import { useHistory } from 'react-router-dom'
import PageLayout from '../../../components/layout/PageLayout'
import NotFoundPage from '../../../components/layout/NotFoundPage'
import { ReplenishmentDialogDataModel, ReplenishmentModel } from './ReplenishmentModel'
import { getProcurement, postPreparationMultipleStock, updateMultipleReplenishmentStatus, updateReplenishmentStatus } from '../../dispatch/services/dispatchApi'
import {
	CENTER_TYPE,
	colors,
	DATE_ROLE,
	DOWNLOAD_FILE_TYPE,
	PRODUCT_TYPES,
	QTY_MODE,
	REPLENISHMENT_DIALOG,
	REPLENISHMENT_STATUS,
	SELECT_VALUES,
	TAG_CATEGORIES,
	USER_ROLE
} from '../../../utils/constants'
import { ProductModel, VaccinModel } from '../../admin/product/services/productModel'
import { CentreModel } from '../phidemData/phidemDataModel'
import FieldDatetime from '../../../components/fields/FieldDatetime'
import Button from '../../../components/layout/buttons/Button'
import { PhidemDataContext } from '../phidemData/PhidemDataContext'
import { isDateInputTypeSupported } from '../../../utils/form/inputTypes'
import { dateAndTimeDecorator } from '../../../utils/form/decorators'
import { DispatchBlModel } from '../../dispatch/services/dispatchModels'
import ErrorMessageRequired from '../../../components/layout/errors/ErrorMessageRequired'
import ExportExcelButton from '../../../components/layout/buttons/ExportExcelButton'
import DialogReplenishmentProcurement, { DataDialogProcurement } from './components/DialogReplenishmentProcurement'
import { ProcurementRowModel } from '../procurement/services/procurementModels'
import FieldAutocomplete from '../../../components/fields/FieldAutocomplete'
import MultipleSelectionButton from './components/MultipleSelectionButton'
import DialogReplenishmentPrepare, { DispatchPreparationMultipleFormModel } from './components/DialogReplenishmentPrepare'
import Dialog from '../../../components/layout/dialog/Dialog'
import MenuMore from '../../../components/layout/buttons/MenuMore'
import DialogQtyProcurementWarning from '../../dispatch/exitStock/components/DialogQtyProcurementWarning'
import SplitButton from '../../../components/layout/buttons/SplitButton'
import { fetchDownload } from '../../../utils/fetchDownload'
import DialogReplenishmentActions from './components/DialogReplenishmentActions'
import { CarrierModel } from '../../admin/carrier/services/carrierModel'
import MailTo from '../../../components/layout/MailTo'
import { DATE_FORMAT, DATE_FORMAT_IN_FORM, displayDate, formatDateInForm } from '../../../utils/dateUtils'
import { getCenterNameByType } from '../../../utils/stringUtils'
import { getAllCarriers, getAllProducts } from '../phidemData/phidemDataApi'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		paperForm: {
			width: '70%',
			minWidth: 300,
			maxWidth: 880,
			padding: theme.spacing(2),
			marginBottom: 50
		},
		countLine: {
			margin: 4
		},
		submitButtonRoot: {
			width: '100%',
			margin: theme.spacing(1),
			display: 'flex',
			justifyContent: 'flex-end'
		},
		checkCircleIcon: {
			color: colors.snackSuccess
		},
		cancelIcon: {
			color: colors.snackError
		}
	})
)

interface Column {
	key: string,
	name: React.ReactElement,
	sortable?: boolean,
	render?: (row: any) => React.ReactElement
}

interface ReplenishmentListProps {
	role: string,
	refreshTable: () => void,
	snackSuccess: (value: any) => void,
	snackError: (value: any) => void,
	clearRows: () => void
}

const initDialogData = {
	id: '',
	newStatut: '',
	nbFlacons: 0,
	dateReception: '',
	productName: '',
	productModeQte: QTY_MODE.DOSE,
	productQuantity1: 0,
	productQuantity2: 0,
	productQuantityR: 0,
	productQuantityCond: 0,
	productTotalQuantity: 0,
	productCond: '',
	destinationCenter: '',
	nbDoses: 0,
	dateCouvertureDebut: '',
	dateCouvertureFin: ''
}

interface FormValues {
	idProduit?: string
	idTransporteur?: string
	idCentre?: string
	idZone?: string
	// @ts-ignore
	dateRole: DATE_ROLE.CREATION | DATE_ROLE.RECEPTION | SELECT_VALUES.NONE
	startDatetime?: string
	startDate?: string
	startTime?: string
	endDatetime?: string
	endDate?: string
	endTime?: string
	isDateDemande: boolean
	isDateReception: boolean
	statut: string[]
	typeEtiquette?: string
	texteEtiquette?: string
}

const ReplenishmentList: React.FC<ReplenishmentListProps> = (
	{
		role,
		refreshTable,
		snackSuccess,
		snackError,
		clearRows
	}
) => {
	const classes = useStyles()
	const intl = useIntl()
	const {
		vaccins,
		referentialData: { carriers },
		user: { selectedCenter, isDateFormatEn, selectedZone, isSuperAdmin },
		zones,
		application: { replenishment, configuration }
	} = useContext(PhidemDataContext)
	const history = useHistory()
	// Gestion de la visibilité des dialog d'action
	const [openDialogType, setOpenDialogType] = React.useState<string | undefined>(undefined)
	// Data affichée aux dialogues d'actions
	const [selectedReplenishment, setSelectedReplenishment] = useState<ReplenishmentDialogDataModel>(initDialogData)
	// Dialog/Data procurement
	const [isOpenDialogProcurement, setIsOpenDialogProcurement] = useState<boolean>(false)
	const [dataDialogProcurement, setDataDialogProcurement] = useState<DataDialogProcurement>()
	// Dialog warning quantité + valeurs enregistrées pour resoumission
	const [isOpenDialogProcurementWarning, setIsOpenDialogProcurementWarning] = React.useState<boolean>(false)
	const [confirmValues, setConfirmValues] = React.useState<any>(undefined)
	// Dialog / Sélection pour actions multiples
	const [isOpenDialogPrepare, setIsOpenDialogPrepare] = React.useState<boolean>(false)
	const [rowsSelected, setRowsSelected] = useState<ReplenishmentModel[]>()
	// Gestion des documents à imprimer post préparation mutliples
	const [isOpenDialogDownloadBl, setIsOpenDialogDownloadBl] = React.useState<boolean>(false)
	const [returnBl, setReturnBl] = useState<string[]>([])
	const [isLoadingDocument, setIsLoadingDocument] = useState<boolean>(false)
	// Loader de submit
	const [submitLoading, setSubmitLoading] = useState<boolean>(false)
	const [processedCarriers, setProcessedCarriers] = useState<CarrierModel[]>(carriers)
	const [processedVaccins, setProccessedVaccins] = useState<VaccinModel[]>(vaccins)

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			replenishment.setFilters({
				...replenishment.filters,
				idZone: zones.length > 0 ? zones[0]?.id : undefined
			})
		}, 500)

		return () => clearTimeout(timeoutId)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [zones])

	// Filtre date
	const isDatetimeSupported = isDateInputTypeSupported('datetime-local')
	const formDecorators = useMemo(() => isDatetimeSupported
			? []
			:
			[
				dateAndTimeDecorator('startDate', 'startTime', 'startDatetime'),
				dateAndTimeDecorator('endDate', 'endTime', 'endDatetime'),
				dateAndTimeDecorator('startCreationDate', 'startCreationTime', 'startCreationDatetime'),
				dateAndTimeDecorator('endCreationDate', 'endCreationTime', 'endCreationDatetime')
			]
		, [isDatetimeSupported])

	// Open / Close des dialog d'action
	const openDialogAction = (data: ReplenishmentDialogDataModel, dialogType: string) => {
		setSelectedReplenishment(data)
		setOpenDialogType(dialogType)
	}
	const closeDialogAction = () => {
		setOpenDialogType(undefined)
		setSelectedReplenishment(initDialogData)
	}
	// Open / Close dialog allocation
	const openDialogProcurement = (data: DataDialogProcurement) => {
		setDataDialogProcurement(data)
		setIsOpenDialogProcurement(true)
	}
	const closeDialogProcurement = () => {
		setIsOpenDialogProcurement(false)
		setDataDialogProcurement(undefined)
	}
	const openDialogConfirm = (data: ReplenishmentModel[]) => {
		setRowsSelected(data)
		setOpenDialogType(REPLENISHMENT_DIALOG.MULTIPLE_CONFIRM)
	}
	// Open / Close actions multiples
	const openDialogPrepare = (data: ReplenishmentModel[]) => {
		setRowsSelected(data)
		setIsOpenDialogPrepare(true)
	}
	const closeDialogPrepare = () => setIsOpenDialogPrepare(false)
	const openDialogDeparture = (data: ReplenishmentModel[]) => {
		setRowsSelected(data)
		setOpenDialogType(REPLENISHMENT_DIALOG.DEPART_MARCHANDISES)
	}

	const onValidate = (formValues: FormValues) => {
		const errors: any = {}

		if ((formValues.isDateDemande || formValues.isDateReception)) {
			if (!formValues.startDatetime) {
				errors.startDatetime = <ErrorMessageRequired />
				errors.startDate = <ErrorMessageRequired />
				errors.startTime = <ErrorMessageRequired />
			}
			if (!formValues.endDatetime) {
				errors.endDatetime = <ErrorMessageRequired />
				errors.endDate = <ErrorMessageRequired />
				errors.endTime = <ErrorMessageRequired />
			}
			if (formValues.startDatetime && formValues.endDatetime && moment(formValues.startDatetime).isAfter(moment(formValues.endDatetime))) {
				errors.startDatetime = (
					<FormattedMessage
						id="global.error.validation.startDateAfterEndDate"
						defaultMessage="La date de début ne peut pas être antérieure à celle de fin"
						description="Field error"
					/>
				)
			}
		}

		return errors
	}

	const fetchProcessedCarriers = useCallback((idZone?: string) => {
		if (idZone && isSuperAdmin) {
			getAllCarriers(idZone)
				.then((response: CarrierModel[]) => setProcessedCarriers(response))
		}
	}, [isSuperAdmin])

	const fetchProccessedVaccins = useCallback((idZone?: string) => {
		if (idZone && isSuperAdmin) {
			getAllProducts(idZone)
				.then((response: VaccinModel[]) => setProccessedVaccins(response.filter((product: ProductModel) => product.type === PRODUCT_TYPES.VACCIN)))
		}
	}, [isSuperAdmin])

	useEffect(() => {
		fetchProccessedVaccins(zones.length > 0 ? zones[0]?.id : undefined)
	}, [fetchProccessedVaccins, zones])

	const getReplenishments = (formValues: FormValues) => {
		replenishment.setFilters({
			idProduit: formValues.idProduit === SELECT_VALUES.ALL ? undefined : formValues.idProduit,
			idTransporteur: formValues.idTransporteur === SELECT_VALUES.ALL ? undefined : formValues.idTransporteur,
			isDateReception: formValues.dateRole === DATE_ROLE.RECEPTION,
			isDateDemande: formValues.dateRole === DATE_ROLE.CREATION,
			dateRole: formValues.dateRole,
			dateDebut: formValues.dateRole === SELECT_VALUES.NONE ? undefined : new Date(formValues.startDatetime || '').toISOString(),
			dateFin: formValues.dateRole === SELECT_VALUES.NONE ? undefined : new Date(formValues.endDatetime || '').toISOString(),
			idCentre: formValues.idCentre,
			idZone: formValues.idZone,
			statut: formValues.statut,
			typeEtiquette: formValues.typeEtiquette === SELECT_VALUES.NONE ? undefined : formValues.typeEtiquette,
			texteEtiquette: formValues.typeEtiquette === SELECT_VALUES.NONE ? undefined : formValues.texteEtiquette
		})

		clearRows()
	}

	const onSubmitDialogAction = (values: any, forceValidation?: boolean) => {
		setConfirmValues(values)
		setSubmitLoading(true)
		return updateReplenishmentStatus({
			id: selectedReplenishment.id,
			newStatut: selectedReplenishment.newStatut,
			commentaire: values.comment,
			nbFlacons: values.nbFlacons,
			...(values?.dateReception && {
				dateReception: new Date(values.dateReception).toISOString()
			}),
			forceValidation
		})
			.then(() => {
				if (selectedReplenishment.newStatut === REPLENISHMENT_STATUS.ANNULE) {
					snackSuccess({ id: 'replenishmentList.cancelSuccess', defaultMessage: 'La demande a bien été annulée', description: 'Success message' })

				} else {
					snackSuccess({ id: 'replenishmentList.updateStatusSuccess', defaultMessage: 'Le statut de la demande a bien été modifié', description: 'Success message' })
				}
				closeDialogAction()
				setIsOpenDialogProcurementWarning(false)
				refreshTable()
				setConfirmValues(undefined)
			})
			.catch((e: any) => {
				// Gestion du retour de warning pour allocation
				if (e && e.bodyError) {
					if (e.bodyError.globalErrors && e.bodyError.globalErrors.length > 0 && e.bodyError.globalErrors[0].code === 'quantitePrevision') {
						setIsOpenDialogProcurementWarning(true)
						return
					}
				}

				snackError({
					id: 'replenishmentList.updateStatusError',
					defaultMessage: 'Une erreur est survenue, le statut de la demande n\'a pas été modifié',
					description: 'Success message'
				})
			})
			.finally(() => setSubmitLoading(false))
	}

	const resetDateFields = (form: FormApi<FormValues>) => {
		form.change('startDatetime', undefined)
		form.change('startDate', undefined)
		form.change('startTime', undefined)
		form.change('endDatetime', undefined)
		form.change('endDate', undefined)
		form.change('endTime', undefined)
	}

	const onSubmitPrepareMultiple = (values: DispatchPreparationMultipleFormModel) => {
		setReturnBl([])
		return postPreparationMultipleStock(values)
			.then((response: DispatchBlModel[]) => {
				closeDialogPrepare()
				refreshTable()
				clearRows()
				setIsOpenDialogDownloadBl(true)
				setReturnBl(response.map((bl: DispatchBlModel) => bl.id))
				snackSuccess({ id: 'replenishmentList.preparationSuccess', defaultMessage: 'Les préparations ont bien été effectuées', description: 'Success message' })
			})
			.catch(() => snackError({
				id: 'replenishmentList.preparationError',
				defaultMessage: 'Une erreur est survenue, les préparations n\'ont pas été effectuées',
				description: 'Error message'
			}))
	}

	// Fonction pour le départ unitaire ou multiples
	const onSubmitMultiple = (values: any, newStatus: string) => {
		setSubmitLoading(true)
		return updateMultipleReplenishmentStatus({
			ids: (rowsSelected || []).map((row: ReplenishmentModel) => row.id),
			newStatut: newStatus,
			commentaire: values.comment,
			dateDepart: values.departureDatetime ? new Date(values.departureDatetime).toISOString() : undefined
		})
			.then(() => {
				snackSuccess({
					id: 'replenishmentList.updateMultipleStatusSuccess',
					defaultMessage: 'Les statuts des demandes ont bien été modifiés',
					description: 'Success message'
				})
				closeDialogAction()
				refreshTable()
				clearRows()
			})
			.catch(() => snackError({
					id: 'replenishmentList.updateMultipleStatusError',
					defaultMessage: 'Une erreur est survenue, les statuts des demandes n\'ont pas été modifiés',
					description: 'Error message'
				})
			)
			.finally(() => setSubmitLoading(false))
	}

	const computeQuantityFromDoses = (row: ReplenishmentModel) => {
		const dosesSum = Number(row.nbDoses1 || 0) + Number(row.nbDoses2 || 0) + Number(row.nbDosesR || 0)
		return Math.ceil(dosesSum / (row.produit.nbDoses || 1))
	}

	const getHeaders = () => {
		const headers: Column[] = [
			{
				key: 'statut',
				name: <FormattedMessage
					id="dispatch.replenishmentList.status"
					defaultMessage="Statut"
					description="Header column name"
				/>,
				sortable: true,
				render: (row: ReplenishmentModel) => {
					let element

					switch (row.statut) {
						case REPLENISHMENT_STATUS.EN_ATTENTE:
							element = <HourglassEmpty />
							break
						case REPLENISHMENT_STATUS.CONFIRME:
							element = <CheckCircle className={classes.checkCircleIcon} />
							break
						case REPLENISHMENT_STATUS.REFUSE:
							element = <Cancel className={classes.cancelIcon} />
							break
						case REPLENISHMENT_STATUS.TRAITE:
							element = <Assignment />
							break
						case REPLENISHMENT_STATUS.DEPART_MARCHANDISES:
							element = <LocalShipping />
							break
						default:
							return (
								<span>
                                    <FormattedMessage
	                                    id={`enum.replenishmentStatus.${row.statut}`}
	                                    defaultMessage="Statut"
	                                    description="Status"
                                    />
                                </span>
							)
					}

					return (
						<Tooltip title={
							<FormattedMessage
								id={`enum.replenishmentStatus.${row.statut}`}
								defaultMessage="Statut"
								description="Status"
							/>
						}>
							{element}
						</Tooltip>
					)
				}
			},
			{
				key: 'dateReception',
				name: <FormattedMessage
					id="dispatch.replenishmentList.wantedDate"
					defaultMessage="Date de réception souhaitée"
					description="Header column name"
				/>,
				sortable: true,
				render: (row: ReplenishmentModel) => <>
					<span>{displayDate(moment(row.dateReception), DATE_FORMAT.DATE, isDateFormatEn)}</span>
					<br />
					{row.dateReceptionConfirmee &&
						<FormattedMessage
							id="dispatch.replenishmentList.confirmDate"
							defaultMessage={`(confirmée : ${row.dateReceptionConfirmee})`}
							description="Count total"
							values={{ date: displayDate(moment(row.dateReceptionConfirmee), DATE_FORMAT.DATETIME, isDateFormatEn) }}
						>
							{msg => <Typography variant="caption" className={classes.countLine}>{msg}</Typography>}
						</FormattedMessage>
					}

				</>
			}
		]

		if (configuration.replenishmentCoverage) {
			headers.push({
				key: 'dateCouvertureDebut',
				name: <FormattedMessage
					id="dispatch.replenishmentList.coveragePeriod"
					defaultMessage="Coverage period"
					description="Header column name"
				/>,
				sortable: true,
				render: (row: ReplenishmentModel) => <>
					{row.dateCouvertureDebut && row.dateCouvertureFin && (
						<>
							<span>{displayDate(moment(row.dateCouvertureDebut), DATE_FORMAT.DATETIME, isDateFormatEn)} - </span>
							<br />
							<span>{displayDate(moment(row.dateCouvertureFin), DATE_FORMAT.DATETIME, isDateFormatEn)}</span>
						</>
					)}
				</>
			})
		}

		headers.push(...[{
				key: 'commentaireDispatch',
				name: <FormattedMessage
					id="dispatch.replenishmentList.dispatchComment"
					defaultMessage="Commentaire dispatch"
					description="Header column name"
				/>,
				sortable: true,
				render: (row: ReplenishmentModel) => {
					switch (row.statut) {
						case REPLENISHMENT_STATUS.CONFIRME:
							return <span>{row.commentaireConfirmation}</span>
						case REPLENISHMENT_STATUS.DEPART_MARCHANDISES:
							return <span>{row.commentaireDepart}</span>
						default:
							return <span>{row.commentaireDispatch}</span>
					}
				}
			}]
		)

		headers.push(...[{
			key: 'produit.id',
			name: <FormattedMessage
				id="global.functional.product"
				defaultMessage="Produit"
				description="Header column name"
			/>,
			sortable: true,
			render: (row: ReplenishmentModel) => <span>{row.produit.nomCommercial}</span>
		}, {
			key: 'nbDoses1',
			name: <FormattedMessage
				id="global.functionnal.quantity"
				defaultMessage="Quantité"
				description="Header column name"
			/>,
			sortable: true,
			render: (row: ReplenishmentModel) => {
				if (row.modeQte === QTY_MODE.CONDITIONNEMENT) {
					return <span>
                        {row.nbCond}
						<FormattedMessage
							id="global.functionnal.conditioningType"
							defaultMessage={row.produit.typeConditionnement}
							description="Conditioning type"
							values={
								{
									count: row.nbCond,
									conditioningType: row.produit.typeConditionnement
								}
							}
						/>
                        <br />
						{row.quantiteConfirmee &&
							<FormattedMessage
								id="global.functionnal.confirmCount"
								defaultMessage={`(confirmée : ${row.quantiteConfirmee} ${row.produit.typeConditionnement}s)`}
								description="Count total"
								values={{ count: row.quantiteConfirmee, type: row.produit.typeConditionnement }}
							>
								{msg => <Typography variant="caption" className={classes.countLine}>{msg}</Typography>}
							</FormattedMessage>
						}
                    </span>
				}

				const conditioningSum = computeQuantityFromDoses(row)
				return (
					<div>
						<Typography className={classes.countLine}>
							<FormattedMessage
								id="dispatch.replenishmentList.quantity1"
								defaultMessage="1ère injection : "
								description="Header column name"
							/>
							<span style={{ fontWeight: 'bold' }}>{`${row.nbDoses1} `}</span>
							<FormattedMessage
								id="dispatch.replenishmentList.conditioningType"
								defaultMessage="Type conditionnement"
								description="Header column name"
								values={{
									count: row.nbDoses1
								}}
							/>
						</Typography>
						<Typography className={classes.countLine}>
							<FormattedMessage
								id="dispatch.replenishmentList.quantity2"
								defaultMessage="2ème injection : "
								description="Header column name"
							/>
							<span style={{ fontWeight: 'bold' }}>{`${row.nbDoses2} `}</span>
							<FormattedMessage
								id="dispatch.replenishmentList.conditioningType"
								defaultMessage="Type conditionnement"
								description="Header column name"
								values={{
									count: row.nbDoses2
								}}
							/>
						</Typography>
						<Typography className={classes.countLine}>
							<FormattedMessage
								id="dispatch.replenishmentList.quantityR"
								defaultMessage="injection rappel : "
								description="Header column name"
							/>
							<span style={{ fontWeight: 'bold' }}>{`${row.nbDosesR} `}</span>
							<FormattedMessage
								id="dispatch.replenishmentList.conditioningType"
								defaultMessage="Type conditionnement"
								description="Header column name"
								values={{
									count: row.nbDosesR
								}}
							/>
						</Typography>
						<FormattedMessage
							id="global.functionnal.totalCountExplicit"
							defaultMessage={`Soit ${conditioningSum} ${row.produit.typeConditionnement}s`}
							description="Count total"
							values={{ count: conditioningSum, type: row.produit.typeConditionnement }}
						>
							{msg => <Typography variant="caption" className={classes.countLine}>{msg}</Typography>}
						</FormattedMessage>
						<br />
						{row.quantiteConfirmee &&
							<FormattedMessage
								id="global.functionnal.confirmCount"
								defaultMessage={`(confirmée : ${row.quantiteConfirmee} ${row.produit.typeConditionnement}s)`}
								description="Count total"
								values={{ count: row.quantiteConfirmee, type: row.produit.typeConditionnement }}
							>
								{msg => <Typography variant="caption" className={classes.countLine}>{msg}</Typography>}
							</FormattedMessage>
						}
					</div>
				)
			}
		}])

		if (role === USER_ROLE.ROLE_ADMIN) {
			headers.push(
				{
					key: 'centreDispatch.id',
					name: <FormattedMessage
						id="dispatch.replenishmentList.expedition"
						defaultMessage="Expéditeur"
						description="Header column name"
					/>,
					sortable: true,
					render: (row: ReplenishmentModel) => <MailTo center={row.centreDispatch} />
				}
			)
			headers.push(
				{
					key: 'centreVacc.id',
					name: <FormattedMessage
						id="global.functionnal.destination"
						defaultMessage="Destination"
						description="Header column name"
					/>,
					sortable: true,
					render: (row: ReplenishmentModel) => <MailTo center={row.centreVacc} />
				}
			)
		} else if (role === USER_ROLE.ROLE_DISPATCH_CENTER) {
			headers.push(
				{
					key: 'centreVacc.id',
					name: <FormattedMessage
						id="dispatch.replenishmentList.destination"
						defaultMessage="Centre"
						description="Header column name"
					/>,
					sortable: true,
					render: (row: ReplenishmentModel) => <MailTo center={row.centreVacc} />
				}
			)
		}
		headers.push(
			{
				key: 'transporteur.nom',
				name: <FormattedMessage
					id="global.functionnal.carrier"
					defaultMessage="Transporteur"
					description="Header column name"
				/>,
				sortable: true,
				render: (row: ReplenishmentModel) => <span>{row.transporteur ? row.transporteur.nom : ''}</span>
			}
		)

		headers.push(
			{
				key: 'commentaireVaccination',
				name: <FormattedMessage
					id="dispatch.replenishmentList.destinationComment"
					defaultMessage="Commentaire vaccination"
					description="Header column name"
				/>,
				sortable: true
			}
		)

		if (role === USER_ROLE.ROLE_VACCINATION_CENTER) {
			headers.push(
				{
					key: 'menuAnnulation',
					name: <></>,
					sortable: true,
					render: (row: ReplenishmentModel) =>
						row.statut !== REPLENISHMENT_STATUS.TRAITE && row.statut !== REPLENISHMENT_STATUS.REFUSE ?
							<MenuMore children={
								<MenuItem onClick={() => openDialogAction(
									getDataToDialogAction(row, REPLENISHMENT_STATUS.ANNULE, computeQuantityFromDoses(row)),
									REPLENISHMENT_DIALOG.CANCEL
								)}>
									<FormattedMessage
										id="dispatch.replenishment.actions.cancel"
										defaultMessage="Annuler"
										description="Cancel action"
									/>
								</MenuItem>}
							/>
							: <></>
				}
			)
		}

		return headers
	}

	const getDataToDialogAction = (row: ReplenishmentModel, statut: string, quantitySum: number) => ({
		id: row.id,
		newStatut: statut,
		currentStatut: row.statut,
		productModeQte: row.modeQte,
		productName: row.produit.nomCommercial,
		productQuantity1: row.nbDoses1,
		productQuantity2: row.nbDoses2,
		productQuantityR: row.nbDosesR,
		productQuantityCond: row.nbCond,
		productTotalQuantity: quantitySum,
		productCond: row.produit.typeConditionnement,
		destinationCenter: row.centreVacc.nom,
		nbDoses: row.produit.nbDoses,
		receptionDate: row.dateReception,
		dateCouvertureDebut: row.dateCouvertureDebut,
		dateCouvertureFin: row.dateCouvertureFin,
		transporteur: row.transporteur && row.transporteur.nom
	})

	const getDownloadOptions = () => {
		const baseUrl = `/dispatch/preparation/multiple/document?ids=${returnBl.join('&ids=')}`

		return [
			{
				label: <FormattedMessage
					id="button.print.BL"
					defaultMessage="Bon de livraison"
					description="Message on print BL button"
				/>,
				onClick: () => downloadDocument(
					`${baseUrl}&documentType=${DOWNLOAD_FILE_TYPE.DISPATCH_BL.type}`,
					DOWNLOAD_FILE_TYPE.DISPATCH_BL.outputFile)
			},
			{
				label: <FormattedMessage
					id="button.print.label"
					defaultMessage="Etiquette"
					description="Message on print label button"
				/>,
				onClick: () => downloadDocument(
					`${baseUrl}&documentType=${DOWNLOAD_FILE_TYPE.DISPATCH_ETIQUETTE.type}`,
					DOWNLOAD_FILE_TYPE.DISPATCH_ETIQUETTE.outputFile)

			},
			{
				label: <FormattedMessage
					id="button.print.bottle36"
					defaultMessage="Etiquettes de flacon 36"
					description="Message on bottle 36 button"
				/>,
				onClick: () => downloadDocument(
					`${baseUrl}&documentType=${DOWNLOAD_FILE_TYPE.DISPATCH_FLACON_36.type}`,
					DOWNLOAD_FILE_TYPE.DISPATCH_FLACON_36.outputFile)
			},
			{
				label: <FormattedMessage
					id="button.print.bottle96"
					defaultMessage="Etiquettes de flacon 96"
					description="Message on bottle 96 button"
				/>,
				onClick: () => downloadDocument(
					`${baseUrl}&documentType=${DOWNLOAD_FILE_TYPE.DISPATCH_ETIQUETTE_CONTENANT.type}`,
					DOWNLOAD_FILE_TYPE.DISPATCH_ETIQUETTE_CONTENANT.outputFile)
			}]
	}
	const downloadDocument = (url: string, fileName: string) => {
		setIsLoadingDocument(true)
		fetchDownload(url)
			.then(
				(blob: any) => {
					const url = window.URL.createObjectURL(blob)
					const a = document.createElement('a')
					a.href = url
					a.download = fileName
					a.click()
				}
			)
			.catch(() => snackError({ id: 'global.error.downloadFailed', defaultMessage: 'Le téléchargement a échoué', description: 'Error message' }))
			.finally(() => setIsLoadingDocument(false))
	}

	const initValues = useMemo(() => ({
		...replenishment.filters,
		idZone: replenishment.filters.idZone ? replenishment.filters.idZone : zones.length > 0 ? zones[0]?.id : undefined,
		idProduit: replenishment.filters.idProduit ? replenishment.filters.idProduit : SELECT_VALUES.ALL,
		idTransporteur: replenishment.filters.idTransporteur ? replenishment.filters.idTransporteur : SELECT_VALUES.ALL,
		startDatetime: replenishment.filters.dateDebut ? formatDateInForm(moment(Date.parse(replenishment.filters.dateDebut)), DATE_FORMAT_IN_FORM.DATETIME_TIMEZONE) : undefined,
		endDatetime: replenishment.filters.dateFin ? formatDateInForm(moment(Date.parse(replenishment.filters.dateFin)), DATE_FORMAT_IN_FORM.DATETIME_TIMEZONE) : undefined,
		typeEtiquette: replenishment.filters.typeEtiquette ? replenishment.filters.typeEtiquette : SELECT_VALUES.NONE
	}), [replenishment.filters, zones])

	return (
		<PageLayout>
			<Paper className={classes.paperForm}>
				<Form
					initialValues={initValues}
					// @ts-ignore
					decorators={formDecorators}
					validate={onValidate}
					onSubmit={getReplenishments}
					render={({ handleSubmit, submitting, values, form }) => (
						<form onSubmit={handleSubmit}>
							<Grid
								container
								direction="row"
								justify="flex-start"
								spacing={2}
							>
								{isSuperAdmin &&
									<Grid item xs={12} md={3}>
										<Field
											name="idZone"
											component={FormSelect}
											label={
												<FormattedMessage
													id="product.Zone"
													defaultMessage="Zone"
													description="Zone"
												/>
											}
										>
											{zones.map((zone) => (
												<MenuItem key={zone.id} value={zone.id}>
													{zone.name}
												</MenuItem>
											))}
										</Field>
										<OnChange name="idZone">
											{value => {
												form.change("idTransporteur", SELECT_VALUES.ALL)
												fetchProcessedCarriers(value)
											}}
										</OnChange>
										<OnChange name="idZone">
											{value => {
												form.change("idProduit", SELECT_VALUES.ALL)
												fetchProccessedVaccins(value)
											}}
										</OnChange>
									</Grid>}
								<Grid item xs={12} md={5}>
									<Field
										name="idProduit"
										component={FormSelect}
										label={
											<FormattedMessage
												id="global.functional.product"
												defaultMessage="Produit"
												description="Product"
											/>
										}
									>
										<MenuItem key="default" value={SELECT_VALUES.ALL}>
											<FormattedMessage
												id="select.all"
												defaultMessage="Tous"
												description="All"
											/>
										</MenuItem>
										{processedVaccins.map((vaccin: VaccinModel) => (
											<MenuItem
												key={vaccin.id}
												value={vaccin.id}
											>
												{vaccin.nomCommercial}
											</MenuItem>
										))}
									</Field>
								</Grid>
								{
									role === USER_ROLE.ROLE_DISPATCH_CENTER
									&&
									<Grid item xs={12} md={5}>
										<Field
											name="idCentre"
											label={getCenterNameByType(intl, selectedZone, CENTER_TYPE.VACCINATION)}
											placeholder={intl.formatMessage({
												id: 'select.all',
												defaultMessage: 'Tous',
												description: 'All'
											})}
											component={FieldAutocomplete}
											options={selectedCenter.centreEnfantsByType.vaccinations.filter((centre: CentreModel) => centre.type === CENTER_TYPE.VACCINATION).map((centre: CentreModel) => ({
												label: `${centre.nom}${centre.service ? ` - ${centre.service}` : ''}`,
												value: centre.id
											}))}
										/>
									</Grid>
								}

								<Grid container item xs={12} spacing={2}>
									<Grid item xs={12} md={3}>
										<Field
											name="statut"
											component={FormSelect}
											label={
												<FormattedMessage
													id="dispatch.replenishmentList.status"
													defaultMessage="Statut"
													description="Status"
												/>
											}
											multiple
										>
											{Object.values(REPLENISHMENT_STATUS)
												.filter((status: string) => status !== REPLENISHMENT_STATUS.ANNULE)
												.map((status: string) => <MenuItem
													key={status}
													value={status}
												>
													{intl.formatMessage({
														id: `enum.replenishmentStatus.${status}`,
														defaultMessage: status,
														description: 'Status option'
													})}
												</MenuItem>)}

										</Field>
									</Grid>
									<Grid item xs={12} md={5}>
										<Field
											name="idTransporteur"
											component={FormSelect}
											label={
												<FormattedMessage
													id="global.functionnal.carrier"
													defaultMessage="Transporteur"
													description="Carrier"
												/>
											}
										>
											<MenuItem key="default" value={SELECT_VALUES.ALL}>
												<FormattedMessage
													id="select.all"
													defaultMessage="Tous"
													description="All"
												/>
											</MenuItem>
											{processedCarriers.map((carrier: CarrierModel) => (
												<MenuItem
													key={`carrier-${carrier.id}`}
													value={carrier.id}
												>
													{carrier.nom}
												</MenuItem>
											))}
										</Field>
									</Grid>
								</Grid>
								<Grid item container xs={12} spacing={2}>
									<Grid item xs={12} md={5}>
										<Field
											name="typeEtiquette"
											component={FormSelect}
											label={
												<FormattedMessage
													id="dispatch.replenishmentList.search.labelType"
													defaultMessage="Type d'étiquettes du centre de vaccination"
													description="Label type"
													values={{ vaccinationCenter: getCenterNameByType(intl, selectedZone, CENTER_TYPE.VACCINATION, true) }}
												/>
											}
										>
											<MenuItem key="default" value={SELECT_VALUES.NONE}>
												<FormattedMessage
													id="select.none"
													defaultMessage="Aucun"
													description="None"
												/>
											</MenuItem>
											{
												Object.values(TAG_CATEGORIES).map((element, index) =>
													<MenuItem key={`tag-${index}`} value={element}>
														<FormattedMessage
															id={`enum.tagCategories.${element}`}
															defaultMessage={`${element[0].toUpperCase()}${element.slice(1).toLowerCase()}`}
															description="Field value"
														/>
													</MenuItem>
												)
											}
										</Field>
										<OnChange name="typeEtiquette">
											{value => {
												if (value === SELECT_VALUES.NONE) {
													form.change('texteEtiquette', '')
												}
											}}
										</OnChange>
									</Grid>
									<Grid item xs={12} md={5}>
										<Field
											name="texteEtiquette"
											component={FormInput}
											label={
												<FormattedMessage
													id="dispatch.replenishmentList.search.labelText"
													defaultMessage="Valeur de l'étiquette"
													description="Label text"
												/>
											}
										/>
									</Grid>
								</Grid>
								<Grid item xs={12}>
									<Grid item xs={12} md={5}>
										<Field
											name="dateRole"
											component={FormSelect}
											label={
												<FormattedMessage
													id="dispatch.replenishmentList.search.dateRole"
													defaultMessage="Type de date"
													description="Type de date"
												/>
											}
										>
											<MenuItem key="default" value={SELECT_VALUES.NONE}>
												<FormattedMessage
													id="select.none"
													defaultMessage="Aucun"
													description="None"
												/>
											</MenuItem>
											<MenuItem
												key={DATE_ROLE.CREATION}
												value={DATE_ROLE.CREATION}
											>
												<FormattedMessage
													id="enum.dateRole.creation"
													defaultMessage="Date de création"
													description="Form select value"
												/>
											</MenuItem>
											<MenuItem
												key={DATE_ROLE.RECEPTION}
												value={DATE_ROLE.RECEPTION}
											>
												<FormattedMessage
													id="enum.dateRole.reception"
													defaultMessage="Date de réception"
													description="Form select value"
												/>
											</MenuItem>
										</Field>
										<OnChange
											name="dateRole"
										>
											{
												value => {
													resetDateFields(form)

													if (value === SELECT_VALUES.NONE) {
														form.change('isDateDemande', false)
														form.change('isDateReception', false)
													} else if (value === DATE_ROLE.CREATION) {
														form.change('isDateDemande', true)
														form.change('isDateReception', false)
													} else if (value === DATE_ROLE.RECEPTION) {
														form.change('isDateDemande', false)
														form.change('isDateReception', true)
													}
												}
											}
										</OnChange>
									</Grid>
								</Grid>

								{values.dateRole !== SELECT_VALUES.NONE
									&&
									<>
										<Grid item xs={12}>
											<Typography>
												{
													values.dateRole === DATE_ROLE.RECEPTION
														?
														<FormattedMessage
															id="dispatch.replenishmentList.search.receptionDate"
															defaultMessage="Date de réception"
															description="Section label for datetime"
														/>
														:
														<FormattedMessage
															id="dispatch.replenishmentList.search.creationDate"
															defaultMessage="Date de creation"
															description="Section label for datetime"
														/>
												}
											</Typography>
										</Grid>
										<FieldDatetime
											datetimeName="startDatetime"
											dateName="startDate"
											timeName="startTime"
											dateLabel={
												<FormattedMessage
													id="dispatch.replenishmentList.search.startDate"
													defaultMessage="Date de début (jj/mm/aaaa)"
													description="input label"
												/>
											}
											timeLabel={
												<FormattedMessage
													id="dispatch.replenishmentList.search.startTime"
													defaultMessage="Heure de début (hh:mm)"
													description="input label"
												/>
											}
										/>
										<FieldDatetime
											datetimeName="endDatetime"
											dateName="endDate"
											timeName="endTime"
											dateLabel={
												<FormattedMessage
													id="dispatch.replenishmentList.search.endDate"
													defaultMessage="Date de fin (jj/mm/aaaa)"
													description="input label"
												/>
											}
											timeLabel={
												<FormattedMessage
													id="dispatch.replenishmentList.search.endTime"
													defaultMessage="Heure de fin (hh:mm)"
													description="input label"
												/>
											}
										/>
									</>
								}

								{/* INVISIBLE FIELDS (USEFUL FOR FORM VALUE MANAGEMENT ONLY) */}
								<Grid item style={{ display: 'none' }}>
									<Field
										name="isDateReception"
										component={FormSwitch}
										type="checkbox"
									/>
								</Grid>
								<Grid item style={{ display: 'none' }}>
									<Field
										name="isDateDemande"
										component={FormSwitch}
										type="checkbox"
									/>
								</Grid>
								{/* !INVISIBLE FIELDS */}

								<Grid item xs={12} md={12}>
									<div className={classes.submitButtonRoot}>
										<Button
											variant="contained"
											startIcon={<Search />}
											type="submit"
											isLoading={submitting}
										>
											<FormattedMessage
												id="button.search"
												defaultMessage="Rechercher"
												description="Search button label"
											/>
										</Button>
									</div>
								</Grid>
							</Grid>
						</form>
					)} />
			</Paper>

			<SpringDataTable
				apiUrl="/vaccination/besoin"
				nom="replenishmentList"
				headers={getHeaders()}
				filters={replenishment.filters}
				noResultFragment={<NotFoundPage />}
				makeActions={(row: ReplenishmentModel) => {
					const options: any[] = []
					const quantitySumFromDoses = computeQuantityFromDoses(row)
					const status = row.statut

					/* ACTIONS */
					const actionConfirm = {
						label: <FormattedMessage
							id="dispatch.replenishmentList.actions.confirm"
							defaultMessage="Confirmer"
							description="list action"
						/>, action: () => openDialogAction(
							getDataToDialogAction(row, REPLENISHMENT_STATUS.CONFIRME, quantitySumFromDoses),
							REPLENISHMENT_DIALOG.CONFIRM
						),
						key: 'confirm'
					}
					const actionRefuse = {
						label: <FormattedMessage
							id="dispatch.replenishmentList.actions.refuse"
							defaultMessage="Refuser"
							description="list action"
						/>,
						action: () => openDialogAction(getDataToDialogAction(row, REPLENISHMENT_STATUS.REFUSE, quantitySumFromDoses), REPLENISHMENT_DIALOG.REFUSE),
						key: 'refuse'
					}
					const actionPrepare = {
						label: <FormattedMessage
							id="dispatch.replenishmentList.actions.prepareReplenishment"
							defaultMessage="Préparer"
							description="Update action"
						/>, action: () => {
							history.push({
								pathname: '/dispatch/exits',
								state: {
									replenishmentID: row.id,
									productID: row.produit.id,
									unitProduct: row.produit.typeConditionnement,
									quantity: row.quantiteConfirmee || (row.modeQte === QTY_MODE.DOSE ? quantitySumFromDoses : row.nbCond),
									destination: row.centreVacc.id,
									carrier: row.transporteur ? row.transporteur.id : undefined,
									commentFromReplenishment: row.commentaireVaccination
								}
							})
						},
						key: 'prepare'
					}
					const actionProcess = {
						label: (
							<FormattedMessage
								id="dispatch.replenishmentList.actions.process"
								defaultMessage="Traiter"
								description="Action"
							/>
						),
						action: () => openDialogAction(
							{
								...getDataToDialogAction(row, REPLENISHMENT_STATUS.TRAITE, quantitySumFromDoses),
								quantiteConfirmee: row.quantiteConfirmee,
								dateConfirmee: row.dateReceptionConfirmee
							}, REPLENISHMENT_DIALOG.PROCESS),

						key: 'process'
					}
					const actionProcurement = {
						label: (
							<FormattedMessage
								id="dispatch.replenishmentList.dialog.procurement.action"
								defaultMessage="Plan d'approvisionnement"
								description="Action"
							/>
						),
						action: async () => {
							const checkDate = moment(row.dateCouvertureDebut || row.dateReceptionConfirmee || row.dateReception).startOf('isoWeek').hours(12)
							getProcurement({
								debutSemaine: checkDate.toISOString(),
								idProduit: row.produit.id,
								idCentre: row.centreVacc.id,
								modeQte: row.modeQte
							})
								.then((response: ProcurementRowModel) => {
									openDialogProcurement({
										...response,
										startDate: row.dateCouvertureDebut || row.dateReceptionConfirmee || row.dateReception,
										endDate: row.dateCouvertureFin,
										centerName: response.centre.nom,
										productName: response.produit.nomCommercial,
										conditioningType: response.produit.typeConditionnement,
										residualPrevision: Math.max(response.residualPrevision - (row.quantiteConfirmee || (row.nbCond || quantitySumFromDoses)), 0),
										modeQte: row.modeQte
									})
								})
								.catch(() => snackError({
									id: 'dispatch.replenishmentList.errors.procurement',
									defaultMessage: 'Ce centre ne possède pas de plan d\'approvisionnement pour la semaine indiquée',
									description: 'Error message'
								}))
						},
						key: 'procurement'
					}
					const actionDeparture = {
						label: <FormattedMessage
							id="dispatch.replenishment.actions.departure"
							defaultMessage="Départ marchandises"
							description="Departure action"
						/>,
						action: () => openDialogDeparture([row]),
						key: 'departure'
					}

					const actionCancel = {
						label: <FormattedMessage
							id="dispatch.replenishment.actions.cancel"
							defaultMessage="Annuler"
							description="Cancel action"
						/>,
						action: () => openDialogAction(getDataToDialogAction(row, REPLENISHMENT_STATUS.EN_ATTENTE, quantitySumFromDoses), REPLENISHMENT_DIALOG.CANCEL),
						key: 'cancel'
					}
					/* !ACTIONS */

					switch (status) {
						case REPLENISHMENT_STATUS.EN_ATTENTE:
							options.push(
								actionRefuse,
								actionConfirm,
								actionPrepare,
								actionProcess
							)
							break
						case REPLENISHMENT_STATUS.CONFIRME:
							options.push(
								actionPrepare,
								actionProcess,
								actionCancel
							)
							break
						case REPLENISHMENT_STATUS.TRAITE:
							options.push(actionDeparture)
							break
						default:
							break
					}
					options.push(actionProcurement)

					return options
				}}
				showActions={selectedCenter && selectedCenter.type === CENTER_TYPE.DISPATCH}
				displaySelectAll={selectedCenter && selectedCenter.type === CENTER_TYPE.DISPATCH}
				selectable={selectedCenter && selectedCenter.type === CENTER_TYPE.DISPATCH}
			/>
			{selectedCenter && selectedCenter.type === CENTER_TYPE.DISPATCH &&
				<MultipleSelectionButton
					openConfirmation={openDialogConfirm}
					openPreparation={openDialogPrepare}
					openDeparture={openDialogDeparture}
					snackError={snackError}
				/>
			}
			<ExportExcelButton
				url="/vaccination/besoin/export"
				fileName={DOWNLOAD_FILE_TYPE.VACCINATION_BESOIN.outputFile}
				filters={replenishment.filters}
			/>
			<DialogReplenishmentActions
				dialogType={openDialogType}
				loading={submitLoading}
				closeDialogAction={closeDialogAction}
				onSubmit={(values: any) => onSubmitDialogAction(values, false)}
				onSubmitConfirm={() => onSubmitDialogAction({})}
				onSubmitMultiple={onSubmitMultiple}
				replenishment={selectedReplenishment}
			/>
			<DialogQtyProcurementWarning
				title="dispatch.confirm.dialog.warningTitle"
				description="dispatch.confirm.dialog.warningDesc"
				isOpen={isOpenDialogProcurementWarning}
				handleClose={() => setIsOpenDialogProcurementWarning(false)}
				onConfirm={() => onSubmitDialogAction(confirmValues, true)}
				loading={submitLoading}
			/>

			{isOpenDialogProcurement && <DialogReplenishmentProcurement
				isOpen={isOpenDialogProcurement}
				handleClose={closeDialogProcurement}
				data={dataDialogProcurement}
				residualLabel="dispatch.replenishmentList.dialog.procurement.residualPrevisionExcept"
			/>}
			{isOpenDialogPrepare && <DialogReplenishmentPrepare
				isOpen={isOpenDialogPrepare}
				handleClose={closeDialogPrepare}
				rows={rowsSelected}
				onSubmit={onSubmitPrepareMultiple}
			/>}
			{isOpenDialogDownloadBl && <Dialog
				title={
					<FormattedMessage
						id="dispatch.replenishmentList.download.title"
						defaultMessage="Téléchargement pour vos préparations"
						description="Dialog title"
					/>
				}
				isOpen={isOpenDialogDownloadBl}
				handleClose={() => setIsOpenDialogDownloadBl(false)}
				buttons={[
					<SplitButton items={getDownloadOptions()} isLoading={isLoadingDocument} />
				]}
			>
				<Typography gutterBottom>
					<FormattedMessage
						id="dispatch.replenishmentList.download.description"
						defaultMessage="Vous avez procédé à multiples préparations. Souhaitez-vous télécharger les fiches de suivi ?"
						description="Dialog description"
					/>
				</Typography>
			</Dialog>
			}
		</PageLayout>
	)
}

const actions = {
	refreshTable: () => dataTableActions.refresh('replenishmentList'),
	clearRows: () => dataTableActions.selectRows('replenishmentList', [])
}

export default compose<any>(
	connect(null, actions),
	injectSnackbarActions
)(ReplenishmentList)
