import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { compose } from 'redux'
import { injectActions as injectSnackbarActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import { injectToolbarData } from 'isotope-client'
import { FormattedMessage } from 'react-intl'
import { defaultRequestZone, RequestCreateOrUpdateZoneModel, ZoneModel } from '../services/zoneDataModel'
import { getZoneById, updateZoneDetails, updateZoneStatus } from '../services/zoneApi'
import errorsMapper from '../../../../utils/errorsMapper'
import ZoneDetailsForm from './ZoneDetailsForm'
import { REFERENTIEL } from '../../../common/phidemData/phidemDataModel'
import { PhidemDataContext } from '../../../common/phidemData/PhidemDataContext'

interface MatchParamsModel {
	id: string
}

interface ZoneDetailsUpdateProps {
	snackSuccess: (value: any) => void,
	snackError: (value: any) => void,
}

const ZoneDetailsUpdate: React.FC<ZoneDetailsUpdateProps> = (
	{
		snackSuccess,
		snackError
	}) => {
	const { id: idInUrl } = useParams<MatchParamsModel>()
	const { reloadReferentiel, user: { isSuperAdmin } } = useContext(PhidemDataContext)
	const [fetchedZoneDetails, setFetchedZoneDetails] = useState<RequestCreateOrUpdateZoneModel>(defaultRequestZone)
	const [isLoading, setIsLoading] = useState<boolean>(true)

	const handleUpdateZoneStatus = () => {
		updateZoneStatus(idInUrl, !fetchedZoneDetails?.active)
			.then(() => {
				setFetchedZoneDetails({
					...fetchedZoneDetails,
					active: !fetchedZoneDetails?.active
				})
				snackSuccess({ id: 'zone.updateStatusSuccess', defaultMessage: 'Le statut de la zone a bien été modifiée', description: 'Success message' })
				reloadReferentiel(REFERENTIEL.ZONE)
			})
			.catch(() => {
				snackError({ id: 'global.error.occured', defaultMessage: 'Une erreur est survenue', description: 'Error message' })
			})
	}

	const fetchedDataToFormValues = (fetchedData: ZoneModel): RequestCreateOrUpdateZoneModel => {
		return {
			id: fetchedData.id,
			name: fetchedData.name,
			timezone: fetchedData.timezone,
			defaultLanguageId: fetchedData.defaultLanguage.id,
			languagesId: fetchedData.languages.map(language => ({
				id: language.id,
				libelle: language.codeLang
			})),
			besoinCouverture: fetchedData.config.besoinCouverture,
			besoinDoses: fetchedData.config.besoinDoses,
			preparationTransfert: fetchedData.config.preparationTransfert,
			configZoneLanguages: fetchedData.configZoneLanguages,
			active: fetchedData.active
		}
	}

	useEffect(() => {
		getZoneById(idInUrl)
			.then((values: ZoneModel) => setFetchedZoneDetails(fetchedDataToFormValues(values)))
			.finally(() => setIsLoading(false))
	}, [idInUrl])


	const onSubmit = async (formValuesModel: any) => {
		return updateZoneDetails(idInUrl, formValuesModel)
			.then((response: ZoneModel) => {
				setFetchedZoneDetails(fetchedDataToFormValues(response))
				snackSuccess({ id: 'zone.updateSuccess', defaultMessage: 'La zone a bien été modifiée', description: 'Success message' })
				reloadReferentiel(REFERENTIEL.ZONE)
			})
			.catch((e: any) => {
				const { errors, displayFields } = errorsMapper(e)
				if (displayFields) {
					snackError({ id: 'global.error.formError', defaultMessage: 'Votre formulaire comporte des erreurs', description: 'Form error message' })
				} else {
					snackError({ id: 'zone.updateError', defaultMessage: 'Une erreur est survenue, la zone n\'a pas été modifiée', description: 'Error message' })
				}
				return errors
			})
	}

	return (
		<ZoneDetailsForm
			isLoading={isLoading}
			initialValues={fetchedZoneDetails}
			handleUpdateZoneStatus={handleUpdateZoneStatus}
			onSubmit={onSubmit}
			canUpdateStatus={isSuperAdmin}
		/>
	)
}

export default compose<any>(injectToolbarData(() => ({
	title: <FormattedMessage
		id="zone.pageTitle.update"
		defaultMessage="Modifier une zone"
		description="Page title"
	/>
})), injectSnackbarActions)(ZoneDetailsUpdate)

