import React, { useContext } from 'react'
import { Field, Form } from 'react-final-form'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { FormattedMessage } from 'react-intl'
import { FormInput, FormSelect } from 'isotope-client'
import PageFormContainer from '../../../../components/layout/PageFormContainer'
import ErrorMessageRequired from '../../../../components/layout/errors/ErrorMessageRequired'
import Loader from '../../../../components/layout/Loader'
import { ContainerModel, RequestCreateOrUpdateContainerFormModel } from '../services/containerModel'
import ButtonCreate from '../../../../components/layout/buttons/ButtonCreate'
import ButtonSave from '../../../../components/layout/buttons/ButtonSave'
import ErrorPositiveValue from '../../../../components/layout/errors/ErrorPositiveValue'
import ButtonActivate from '../../../../components/layout/buttons/ButtonActivate'
import { SCREEN_SIZE } from '../../../../utils/constants'
import { PhidemDataContext } from '../../../common/phidemData/PhidemDataContext'
import MenuItem from '@material-ui/core/MenuItem'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		buttonRoot: {
			width: '100%',
			display: 'flex',
			justifyContent: 'space-evenly'
		},
		button: {
			margin: theme.spacing(2)
		},
		rowContainer: {
			margin: 0
		}
	})
)

interface ContainerFormProps {
	isLoading?: boolean,
	initialValues: RequestCreateOrUpdateContainerFormModel | undefined,
	onSubmit: (event: any) => void,
	isCreationForm: boolean,
	updateContainerStatus?: () => void
}

const ContainerForm: React.FC<ContainerFormProps> = (
	{
		isLoading = false,
		initialValues,
		onSubmit,
		isCreationForm,
		updateContainerStatus
	}) => {
	const classes = useStyles()
	const isLargeScreen = useMediaQuery(`(min-width: ${SCREEN_SIZE.LARGE}px)`)
	const { zones, user: { isSuperAdmin } } = useContext(PhidemDataContext)

	const onValidate = (formValues: ContainerModel) => {
		const errors: any = {}

		if (!formValues.fabricant) {
			errors.fabricant = <ErrorMessageRequired />
		}
		if (!formValues.nom) {
			errors.nom = <ErrorMessageRequired />
		}
		if (!formValues.type) {
			errors.type = <ErrorMessageRequired />
		}
		if (!formValues.taille && formValues.taille !== 0) {
			errors.taille = <ErrorMessageRequired />
		} else if (formValues.taille < 1) {
			errors.taille = <ErrorPositiveValue />
		}

		if (!formValues.zoneId) {
			errors.zoneId = <ErrorMessageRequired />
		}

		if (!formValues.poids && formValues.poids !== 0) {
			errors.poids = <ErrorMessageRequired />
		}
		if (!formValues.capacite && formValues.capacite !== 0) {
			errors.capacite = <ErrorMessageRequired />
		}

		return errors
	}

	return (
		<>
			{(isLoading)
				?
				<Loader />
				:
				<Form
					initialValues={initialValues}
					onSubmit={onSubmit}
					validate={onValidate}
					render={({ handleSubmit, submitting }) => {

						return (
							<PageFormContainer onSubmit={handleSubmit}>
								<Grid item xs={12}>
									<Typography variant={isLargeScreen ? 'h1' : 'h3'}>
										<FormattedMessage
											id="container.formSection.information"
											defaultMessage="Informations du contenant"
											description="Form section title"
										/>
									</Typography>
								</Grid>
								{isSuperAdmin &&
									<Grid item xs={6}>
										<Field
											name="zoneId"
											component={FormSelect}
											required
											disabled={!isCreationForm}
											label={
												<FormattedMessage
													id="product.Zone"
													defaultMessage="Zone"
													description="Zone"
												/>
											}
										>
											{zones.map((zone) => (
												<MenuItem key={zone.id} value={zone.id}>
													{zone.name}
												</MenuItem>
											))}
										</Field>
									</Grid>}
								<Grid item xs={12}>
									<Grid item xs={6}>
										<Field
											name="nom"
											component={FormInput}
											label={
												<FormattedMessage
													id="container.nom"
													defaultMessage="Nom"
													description="form input"
												/>
											}
											required
										/>
									</Grid>
								</Grid>
								<Grid item xs={12}>
									<Grid item xs={6}>
										<Field
											name="fabricant"
											component={FormInput}
											label={
												<FormattedMessage
													id="container.fabricant"
													defaultMessage="Fabricant"
													description="form input"
												/>
											}
											required
										/>
									</Grid>
								</Grid>
								<Grid item xs={12}>
									<Typography variant={isLargeScreen ? 'h1' : 'h3'}>
										<FormattedMessage
											id="container.formSection.characteristics"
											defaultMessage="Caractéristiques"
											description="Form section title"
										/>
									</Typography>
								</Grid>
								<Grid item xs={12}>
									<Grid item xs={5}>
										<Field
											name="type"
											component={FormInput}
											label={
												<FormattedMessage
													id="container.type"
													defaultMessage="Type"
													description="form input"
												/>
											}
											required
										/>
									</Grid>
								</Grid>
								<Grid item xs={12}>
									<Grid item xs={5}>
										<Field
											name="taille"
											component={FormInput}
											label={
												<FormattedMessage
													id="container.taille"
													defaultMessage="Taille"
													description="form input"
												/>
											}
											required
										/>
									</Grid>
								</Grid>
								<Grid container item xs={12} spacing={2}>
									<Grid item xs={3}>
										<Field
											name="poids"
											component={FormInput}
											label={
												<FormattedMessage
													id="container.poids"
													defaultMessage="Poids"
													description="form input"
												/>
											}
											required
										/>
									</Grid>
									<Grid item xs={3}>
										<Field
											name="capacite"
											component={FormInput}
											label={
												<FormattedMessage
													id="container.capacite"
													defaultMessage="Capacité"
													description="form input"
												/>
											}
											required
										/>
									</Grid>
								</Grid>
								<div
									className={classes.buttonRoot}
									style={{ flexDirection: isLargeScreen ? 'row' : 'column' }}
								>
									{isCreationForm
										?
										<ButtonCreate
											type="submit"
											isLoading={submitting}
										/>
										:
										<ButtonSave
											type="submit"
											isLoading={submitting}
										/>
									}
									<ButtonActivate actif={initialValues?.actif} handleStatus={updateContainerStatus} />
								</div>
							</PageFormContainer>
						)
					}}
				/>
			}
		</>
	)
}

export default ContainerForm
