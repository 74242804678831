import React, { useContext } from 'react'
import { compose } from 'redux'
import { injectActions as injectSnackbarActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import CenterDetailsForm from './components/CenterDetailsForm'
import { postCenterDetails } from './services/centerApi'
import { CenterDetailsDataModel, defaultRequestCenter } from './services/centerDetailsDataModel'
import { useHistory } from 'react-router-dom'
import { injectToolbarData } from 'isotope-client'
import { FormattedMessage } from 'react-intl'
import errorsMapper from '../../../utils/errorsMapper'
import { PhidemDataContext } from '../../common/phidemData/PhidemDataContext'
import { CENTER_TYPE } from '../../../utils/constants'
import { REFERENTIEL } from '../../common/phidemData/phidemDataModel'

interface CenterDetailsCreateProps {
	snackSuccess: (value: any) => void,
	snackError: (value: any) => void,
}

const CenterDetailsCreate: React.FC<CenterDetailsCreateProps> = (
	{
		snackSuccess,
		snackError
	}) => {
	const history = useHistory()
	const { user: { isAdminDispatch, selectedZone }, reloadReferentiel } = useContext(PhidemDataContext)

	const onSubmit = async (formValuesModel: any) => {
		return postCenterDetails(formValuesModel)
			.then((response: CenterDetailsDataModel) => {
				history.push(`/centers/${response.id}`)
				snackSuccess({ id: 'center.createSuccess', defaultMessage: 'Le centre a bien été créé', description: 'Success message' })
				reloadReferentiel(REFERENTIEL.CENTER)
			})
			.catch((e: any) => {
				const { errors, displayFields } = errorsMapper(e)
				if (displayFields) {
					snackError({ id: 'global.error.formError', defaultMessage: 'Votre formulaire comporte des erreurs', description: 'Form error message' })
				} else {
					snackError({ id: 'center.createError', defaultMessage: 'Une erreur est survenue, le centre n\'a pas été créé', description: 'Error message' })
				}
				return errors
			})
	}

	return (
		<CenterDetailsForm
			initialValues={{
				...defaultRequestCenter,
				type: isAdminDispatch ? CENTER_TYPE.VACCINATION : '',
				zoneId: selectedZone?.id
			}}
			onSubmit={onSubmit}
			isCreationForm={true}
			isAdminDispatch={isAdminDispatch}
		/>
	)
}

export default compose<any>(injectToolbarData(() => ({
	title: <FormattedMessage
		id="center.pageTitle.create"
		defaultMessage="Nouveau centre"
		description="Page title"
	/>
})), injectSnackbarActions)(CenterDetailsCreate)
