import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { compose } from 'redux'
import { injectActions as injectSnackbarActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import TemplateForm, { TemplateFormValues } from './components/TemplateForm'
import { TemplateRowModel } from './services/templateModels'
import { getTemplateByID, updateTemplate, updateTemplateStatus } from './services/templateApi'
import { REFERENTIEL } from '../../common/phidemData/phidemDataModel'
import { PhidemDataContext } from '../../common/phidemData/PhidemDataContext'

interface MatchParamsModel {
    id: string
}

interface TemplateUpdateProps {
    snackSuccess: (value: any) => void,
    snackError: (value: any) => void,
}

const TemplateUpdate: React.FC<TemplateUpdateProps> = (
    {
        snackSuccess,
        snackError
    }
) => {
    const { id: idFromUrlParam } = useParams<MatchParamsModel>()
    const history = useHistory()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isStatusLoading, setIsStatusLoading] = useState<boolean>(false)
    const [fetchedTemplate, setFetchedTemplate] = useState<TemplateRowModel>()
    const { reloadReferentiel } = useContext(PhidemDataContext)

    useEffect(() => {
        setIsLoading(true)

        getTemplateByID(idFromUrlParam)
            .then((response: TemplateRowModel) => {
                setFetchedTemplate(response)
                setIsLoading(false)
            })
            .catch(() => history.push('/templates'))
    }, [idFromUrlParam, history])

    const handleUpdateTemplateStatus = (wantedStatus: boolean) => {
        setIsStatusLoading(true)

        updateTemplateStatus({
            id: idFromUrlParam,
            status: wantedStatus
        })
            .then((response: TemplateRowModel) => {
                snackSuccess({ id: 'template.updateStatusSuccess', defaultMessage: 'Le statut du template a bien été modifié', description: 'Success message' })
                setFetchedTemplate(response)
                reloadReferentiel(REFERENTIEL.TEMPLATE)
            })
            .catch(() => snackError({ id: 'template.updateStatusError', defaultMessage: 'Une erreur est survenue, le statut du template n\'a pas pu être modifié', description: 'Error message' }))
            .finally(() => setIsStatusLoading(false))
    }

    const onSubmit = (formValues: TemplateFormValues) => {
        return updateTemplate({
            id: idFromUrlParam,
            nom: formValues.nom,
            type: formValues.type,
            file: formValues.file,
            zoneId: formValues.zoneId
        })
            .then((response: TemplateRowModel) => {
                history.push(`/templates/${response.id}`)
                snackSuccess({ id: 'template.updateTemplateSuccess', defaultMessage: 'Le template a bien été modifié', description: 'Success message' })
                reloadReferentiel(REFERENTIEL.TEMPLATE)
            })
            .catch(() => snackError({ id: 'template.updateTemplateError', defaultMessage: 'Une erreur est survenue, le template n\'a pas pu être modifié', description: 'Error message' }))
    }

    return (
        <TemplateForm
            isCreationForm={false}
            onSubmit={onSubmit}
            isLoading={isLoading}
            isStatusLoading={isStatusLoading}
            updateTemplateStatus={handleUpdateTemplateStatus}
            initialValues={fetchedTemplate ? {
                nom: fetchedTemplate.nom,
                type: fetchedTemplate.type,
                publie: fetchedTemplate.publie,
                zoneId: fetchedTemplate.zoneId
            } : undefined}
        />
    )
}

export default compose(injectSnackbarActions)(TemplateUpdate)
