import { fetchFactory } from 'isotope-client'

export const getUsersByQueryAndZoneId = (query: string, zoneId: string) => fetchFactory(`/utilisateurs/query-list?query=${query}&zoneId=${zoneId}`)

export const updateUserLanguage = (language: string) => fetchFactory('/utilisateurs/langue', { method: 'PUT', body: JSON.stringify({ langue: language }) })

export const saveUser = (user: User) => fetchFactory('/utilisateurs', {
	method: 'put',
	body: JSON.stringify(user)
})

export const addUser = (user: User) => fetchFactory('/utilisateurs', {
	method: 'post',
	body: JSON.stringify(user)
})

export const disableUser = (id: string) => fetchFactory(`/users/${id}`, {
	method: 'delete'
})

export const restoreUser = (id: string) => fetchFactory(`/users/${id}/restore`, {
	method: 'post'
})
