import React, { useContext, useEffect, useState } from 'react'
import { dataTableActions, FormInput, FormSelect, SpringDataTable } from 'isotope-client'
import { FormattedMessage, useIntl } from 'react-intl'
import { Search } from '@material-ui/icons'
import MenuItem from '@material-ui/core/MenuItem'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { updateCenterStatus } from './services/centerApi'
import { connect } from 'react-redux'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { compose } from 'redux'
import { injectActions as injectSnackbarActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import { useHistory } from 'react-router-dom'
import { Field, Form } from 'react-final-form'
import RedirectFAB from '../RedirectFAB'
import { CentreModel } from '../../common/phidemData/phidemDataModel'
import NotFoundPage from '../../../components/layout/NotFoundPage'
import { CENTER_STATUS, CENTER_TYPE, DOWNLOAD_FILE_TYPE, SELECT_VALUES } from '../../../utils/constants'
import Button from '../../../components/layout/buttons/Button'
import { RequestCentersFiltersModel } from './services/centerDetailsDataModel'
import { PhidemDataContext } from '../../common/phidemData/PhidemDataContext'
import ExportExcelButton from '../../../components/layout/buttons/ExportExcelButton'
import { getCenterNameByType } from '../../../utils/stringUtils'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		paperForm: {
			width: '70%',
			minWidth: 300,
			maxWidth: 880,
			padding: theme.spacing(2),
			marginBottom: 50
		},
		submitButtonRoot: {
			width: '100%',
			margin: theme.spacing(1),
			display: 'flex',
			justifyContent: 'flex-end'
		}
	})
)

interface CenterListProps {
	refreshTable: () => void,
	snackSuccess: (value: any) => void,
	snackError: (value: any) => void,
}

const CenterList: React.FC<CenterListProps> = (
	{
		refreshTable,
		snackSuccess,
		snackError
	}
) => {
	const classes = useStyles()
	const intl = useIntl()
	const history = useHistory()
	const { user: { selectedCenter, isAdminDispatch, isSuperAdmin, selectedZone }, zones } = useContext(PhidemDataContext)
	const [filters, setFilters] = useState<RequestCentersFiltersModel>({
		nom: undefined,
		type: undefined,
		zoneId: zones.length > 0 ? zones[0]?.id : undefined,
		statut: CENTER_STATUS.INDIFFERENT
	})

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			setFilters({
				...filters,
				zoneId: zones.length > 0 ? zones[0]?.id : undefined
			})
		}, 500)

		return () => clearTimeout(timeoutId)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [zones])

	const getCenters = (formValues: RequestCentersFiltersModel) => {
		setFilters({
			nom: formValues.nom,
			type: formValues.type === SELECT_VALUES.ALL ? undefined : formValues.type,
			zoneId: formValues.zoneId,
			statut: formValues.statut
		})
	}

	const handleCenterStatus = (id: string, status: boolean) => {
		updateCenterStatus(id, status)
			.then(() => {
				refreshTable()
				snackSuccess({ id: 'center.updateStatusSuccess', defaultMessage: 'Le statut du centre a bien été modifié', description: 'Success message' })
			})
			.catch(() => snackError({
				id: 'center.updateStatusError',
				defaultMessage: 'Une erreur est survenue, le statut du centre n\'a pas été modifiée',
				description: 'Error message'
			}))
	}

	return (
		<>
			<Paper className={classes.paperForm}>
				<Form
					initialValues={{
						type: SELECT_VALUES.ALL,
						zoneId: zones.length > 0 ? zones[0]?.id : undefined,
						statut: CENTER_STATUS.INDIFFERENT
					}}
					onSubmit={getCenters}
					// @ts-ignore
					render={({ handleSubmit, submitting }) => (
						<form onSubmit={handleSubmit}>
							<Grid
								container
								direction="row"
								justify="flex-start"
								spacing={2}
							>
								<Grid item xs={12} md={3}>
									<Field
										name="type"
										component={FormSelect}
										label={
											<FormattedMessage
												id="center.type"
												defaultMessage="Type"
												description="Center type"
											/>
										}
									>
										<MenuItem key="default" value={SELECT_VALUES.ALL}>
											<FormattedMessage
												id="select.all"
												defaultMessage="Tous"
												description="All"
											/>
										</MenuItem>
										{Object.values(CENTER_TYPE).map(centerType =>
											<MenuItem key={centerType} value={centerType}>
												{getCenterNameByType(intl, selectedZone, centerType)}
											</MenuItem>
										)}
									</Field>
								</Grid>
								<Grid item xs={12} md={3}>
									<Field
										name="statut"
										component={FormSelect}
										label={
											<FormattedMessage
												id="center.status"
												defaultMessage="Statut"
												description="Status"
											/>
										}
									>
										<MenuItem key="default" value={CENTER_STATUS.INDIFFERENT}>
											<FormattedMessage
												id="global.status.indifferent"
												defaultMessage="Indifférent"
												description="Indifferent"
											/>
										</MenuItem>
										<MenuItem
											key={CENTER_STATUS.ACTIVE}
											value={CENTER_STATUS.ACTIVE}
										>
											<FormattedMessage
												id="global.status.enabled"
												defaultMessage="Actif"
												description="Form select value"
											/>
										</MenuItem>
										<MenuItem
											key={CENTER_STATUS.INACTIVE}
											value={CENTER_STATUS.INACTIVE}
										>
											<FormattedMessage
												id="global.status.disabled"
												defaultMessage="Inactif"
												description="Form select value"
											/>
										</MenuItem>
									</Field>
								</Grid>
								{isSuperAdmin &&
									<Grid item xs={12} md={3}>
										<Field
											name="zoneId"
											component={FormSelect}
											label={
												<FormattedMessage
													id="product.Zone"
													defaultMessage="Zone"
													description="Zone"
												/>
											}
										>
											{zones.map((zone) => (
												<MenuItem key={zone.id} value={zone.id}>
													{zone.name}
												</MenuItem>
											))}
										</Field>
									</Grid>}
								<Grid item xs={12}>
									<Grid item xs={12} md={4}>
										<Field
											name="nom"
											component={FormInput}
											label={
												<FormattedMessage
													id="center.name"
													defaultMessage="Nom"
													description="Center name"
												/>
											}
										/>
									</Grid>
								</Grid>

								<Grid item xs={12} md={12}>
									<div className={classes.submitButtonRoot}>
										<Button
											variant="contained"
											startIcon={<Search />}
											type="submit"
											isLoading={submitting}
										>
											<FormattedMessage
												id="button.search"
												defaultMessage="Rechercher"
												description="Search button label"
											/>
										</Button>
									</div>
								</Grid>
							</Grid>
						</form>
					)} />
			</Paper>
			<RedirectFAB redirectPath="/centers/add">
				<SpringDataTable
					apiUrl="/centre"
					nom="centerList"
					showActions
					filters={filters}
					headers={[
						{
							key: 'nom',
							name: <FormattedMessage
								id="center.name"
								defaultMessage="Nom"
								description="Header column name"
							/>,
							sortable: true
						},
						{
							key: 'service',
							name: <FormattedMessage
								id="center.service"
								defaultMessage="Service"
								description="Header column service"
							/>,
							sortable: true
						},
						{
							key: 'type',
							name: <FormattedMessage
								id="center.type"
								defaultMessage="Type"
								description="Header column type"
							/>,
							sortable: true,
							render: (row: CentreModel) => <span>
                  {getCenterNameByType(intl, selectedZone, row.type)}
                </span>
						},
						{
							key: 'adresse',
							name: <FormattedMessage
								id="center.address"
								defaultMessage="Adresse"
								description="Header column adress"
							/>,
							sortable: true,
							render: (row: CentreModel) => <span>{row.adresse} {row.codePostal} {row.ville}</span>

						},
						{
							key: 'actif',
							name: <FormattedMessage
								id="center.status"
								defaultMessage="Statut"
								description="Header column status"
							/>,
							sortable: true,
							render: (row: CentreModel) => row.actif ? <FormattedMessage
								id="global.status.enabled"
								defaultMessage="Actif"
								description="Center status"
							/> : <FormattedMessage
								id="global.status.disabled"
								defaultMessage="Inactif"
								description="Center status"
							/>

						}
					]}
					noResultFragment={<NotFoundPage />}
					makeActions={(row: CentreModel) => {
						const options = [
							{
								label: <FormattedMessage
									id="global.update"
									defaultMessage="Modifier"
									description="Update action"
								/>, action: () => {
									history.push(`/centers/${row.id}`)
								}
							}
						]

						if (isSuperAdmin || (selectedCenter && (isAdminDispatch || selectedCenter.type === CENTER_TYPE.REFERENT) && row.id !== selectedCenter.id)
						) {
							options.push({
								label: row.actif ? <FormattedMessage
									id="global.disable"
									defaultMessage="Désactiver"
									description="Disable action"
								/> : <FormattedMessage
									id="global.enable"
									defaultMessage="Activer"
									description="Enable action"
								/>, action: () => handleCenterStatus(row.id, !row.actif)
							})
						}

						return options
					}}
				/>
			</RedirectFAB>
			<ExportExcelButton
				url="/centre/export"
				fileName={DOWNLOAD_FILE_TYPE.CENTRES.outputFile}
				filters={filters}
			/>
		</>
	)
}

const actions = {
	refreshTable: () => dataTableActions.refresh('centerList')
}

export default compose<any>(
	connect(null, actions),
	injectSnackbarActions
)(CenterList)
