import React, { useContext, useEffect, useState } from 'react'
import { dataTableActions, FormSelect, SpringDataTable } from 'isotope-client'
import { FormattedMessage } from 'react-intl'
import NotFoundPage from '../../../components/layout/NotFoundPage'
import { ContainerModel, RequestContainerFiltersModel } from './services/containerModel'
import { useHistory } from 'react-router-dom'
import RedirectFAB from '../RedirectFAB'
import { updateContainerStatus } from './services/containerApi'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { injectActions as injectSnackbarActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import { PhidemDataContext } from '../../common/phidemData/PhidemDataContext'
import { REFERENTIEL } from '../../common/phidemData/phidemDataModel'
import Paper from '@material-ui/core/Paper'
import { Field, Form } from 'react-final-form'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '../../../components/layout/buttons/Button'
import { Search } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core'
import { createStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		paperForm: {
			width: '70%',
			minWidth: 300,
			maxWidth: 880,
			padding: theme.spacing(2),
			marginBottom: 50
		},
		submitButtonRoot: {
			width: '100%',
			margin: theme.spacing(1),
			display: 'flex',
			justifyContent: 'flex-end'
		}
	})
)

interface ContainerListProps {
	refreshTable: () => void,
	snackSuccess: (value: any) => void,
	snackError: (value: any) => void,
}

const ContainerList: React.FC<ContainerListProps> = (
	{
		refreshTable,
		snackSuccess,
		snackError
	}
) => {
	const classes = useStyles()
	const history = useHistory()
	const { reloadReferentiel, user: { isSuperAdmin }, zones } = useContext(PhidemDataContext)
	const [filters, setFilters] = useState<RequestContainerFiltersModel>({
		zoneId: zones.length > 0 ? zones[0]?.id : undefined
	})

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			setFilters({
				...filters,
				zoneId: zones.length > 0 ? zones[0]?.id : undefined
			})
		}, 500)

		return () => clearTimeout(timeoutId)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [zones])

	const handleContainerStatus = (id: string, status: boolean) => {
		updateContainerStatus(id, status)
			.then(() => {
				refreshTable()
				reloadReferentiel(REFERENTIEL.CONTAINER)
				snackSuccess({ id: 'container.updateStatusSuccess', defaultMessage: 'Le statut du contenant a bien été modifié', description: 'Success message' })
			})
			.catch(() => snackError({
				id: 'container.updateStatusError',
				defaultMessage: 'Une erreur est survenue, le statut du contenant n\'a pas été modifiée',
				description: 'Error message'
			}))
	}

	const getContainers = (formValues: RequestContainerFiltersModel) => {
		setFilters({ zoneId: formValues?.zoneId })
	}

	return (
		<>
			{isSuperAdmin &&
				<Paper className={classes.paperForm}>
					<Form
						initialValues={{ zoneId: zones.length > 0 ? zones[0]?.id : undefined }}
						onSubmit={getContainers}
						// @ts-ignore
						render={({ handleSubmit, submitting }) => (
							<form onSubmit={handleSubmit}>
								<Grid
									container
									direction="row"
									justify="flex-start"
									spacing={2}
								>
									<Grid item xs={12} md={3}>
										<Field
											name="zoneId"
											component={FormSelect}
											label={
												<FormattedMessage
													id="product.Zone"
													defaultMessage="Zone"
													description="Zone"
												/>
											}
										>
											{zones.map((zone) => (
												<MenuItem key={zone.id} value={zone.id}>
													{zone.name}
												</MenuItem>
											))}
										</Field>
									</Grid>
									<Grid item xs={12} md={12}>
										<div className={classes.submitButtonRoot}>
											<Button
												variant="contained"
												startIcon={<Search />}
												type="submit"
												isLoading={submitting}
											>
												<FormattedMessage
													id="button.search"
													defaultMessage="Rechercher"
													description="Search button label"
												/>
											</Button>
										</div>
									</Grid>
								</Grid>
							</form>
						)} />
				</Paper>
			}
			<RedirectFAB redirectPath="/containers/add">
				<SpringDataTable
					apiUrl="/referentiel/contenant"
					nom="containerList"
					filters={filters}
					headers={[
						{
							key: 'nom',
							name: <FormattedMessage
								id="container.name"
								defaultMessage="Nom"
								description="Header column name"
							/>,
							sortable: true
						},
						{
							key: 'fabricant',
							name: <FormattedMessage
								id="container.manufacturer"
								defaultMessage="Fabricant"
								description="Header column manufacturer"
							/>,
							sortable: true
						},
						{
							key: 'type',
							name: <FormattedMessage
								id="container.type1"
								defaultMessage="Type"
								description="Header column type"
							/>,
							sortable: true
						},
						{
							key: 'taille',
							name: <FormattedMessage
								id="container.size"
								defaultMessage="Taille"
								description="Header column size"
							/>,
							sortable: true
						},
						{
							key: 'poids',
							name: <FormattedMessage
								id="container.weight"
								defaultMessage="Poids"
								description="Header column weight"
							/>,
							sortable: true
						},
						{
							key: 'capacite',
							name: <FormattedMessage
								id="container.capacity"
								defaultMessage="Capacité"
								description="Header column capacity"
							/>,
							sortable: true
						},
						{
							key: 'actif',
							name: <FormattedMessage
								id="global.status.label"
								defaultMessage="Statut"
								description="Header column status"
							/>,
							sortable: true,
							render: (row: ContainerModel) => row.actif ? <FormattedMessage
								id="global.status.enabled"
								defaultMessage="Actif"
								description="Center status"
							/> : <FormattedMessage
								id="global.status.disabled"
								defaultMessage="Inactif"
								description="Center status"
							/>

						}
					]}
					makeActions={(row: ContainerModel) => [
						{
							label: <FormattedMessage
								id="global.update"
								defaultMessage="Modifier"
								description="Update action"
							/>, action: () => {
								history.push(`/containers/${row.id}`)
							}
						},
						{
							label: row.actif ? <FormattedMessage
								id="global.disable"
								defaultMessage="Désactiver"
								description="Disable action"
							/> : <FormattedMessage
								id="global.enable"
								defaultMessage="Activer"
								description="Enable action"
							/>, action: () => handleContainerStatus(row.id, !row.actif)
						}
					]}
					showActions
					noResultFragment={<NotFoundPage />}
				/>
			</RedirectFAB>
		</>
	)
}

const actions = {
	refreshTable: () => dataTableActions.refresh('containerList')
}

export default compose<any>(
	connect(null, actions),
	injectSnackbarActions
)(ContainerList)
