import { fetchFactory } from '../../../../utils/fetch'
import { MenuManagementByRoleForm, MenuManagementByZoneForm } from './menuManagement.types'

export const getMenuManagementByZone = () => fetchFactory(`/menu-management/by-zone`)

export const getMenuManagementByRole = (zoneId: string) => fetchFactory(`/menu-management/by-role?zoneId=${zoneId}`)

export const getMenuManagementMaxId = () => fetchFactory(`/menu-management/max`)

export const updateMenuManagementByZone = (data: MenuManagementByZoneForm) => fetchFactory(`/menu-management/by-zone`, {
	method: 'PUT',
	body: JSON.stringify({ matrix: data.matrix, selectedZones: data.selectedZones })
})

export const updateMenuManagementByRole = (data: MenuManagementByRoleForm) => fetchFactory(`/menu-management/by-role`, {
	method: 'PUT',
	body: JSON.stringify({ matrixRole: data.matrixRole, selectedRoles: data.selectedRoles, selectedZoneId: data.zoneId })
})

export const getMenuRoles = () => fetchFactory('/role')