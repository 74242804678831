import React, { useContext } from 'react'
import { compose } from 'redux'
import { injectActions as injectSnackbarActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import { useHistory } from 'react-router-dom'
import { injectToolbarData } from 'isotope-client'
import { FormattedMessage } from 'react-intl'
import { postContainer } from './services/containerApi'
import { ContainerModel } from './services/containerModel'
import ContainerForm from './components/ContainerForm'
import errorsMapper from '../../../utils/errorsMapper'
import { PhidemDataContext } from '../../common/phidemData/PhidemDataContext'
import { REFERENTIEL } from '../../common/phidemData/phidemDataModel'

const defaultValues = {
	fabricant: '',
	nom: '',
	type: '',
	taille: 0,
	poids: 0,
	capacite: 0,
	actif: false,
	zoneId: undefined
}

interface ContainerCreateProps {
	snackSuccess: (value: any) => void,
	snackError: (value: any) => void,
}

const ContainerCreate: React.FC<ContainerCreateProps> = (
	{
		snackSuccess,
		snackError
	}) => {
	const history = useHistory()
	const { reloadReferentiel, user: { selectedZone } } = useContext(PhidemDataContext)

	const onSubmit = async (formValues: any) => {
		return postContainer(formValues)
			.then((response: ContainerModel) => {
				history.push(`/containers/${response.id}`)
				snackSuccess({ id: 'container.createSuccess', defaultMessage: 'Le contenant a bien été créé', description: 'Success message' })
				reloadReferentiel(REFERENTIEL.CONTAINER)
			})
			.catch((e: any) => {
				const { errors, displayFields } = errorsMapper(e)
				if (displayFields) {
					snackError({ id: 'global.error.formError', defaultMessage: 'Votre formulaire comporte des erreurs', description: 'Form error message' })
				} else {
					snackError({ id: 'container.createError', defaultMessage: 'Une erreur est survenue, le contenant n\'a pas été créé', description: 'Error message' })
				}
				return errors
			})
	}

	return (
		<ContainerForm
			initialValues={{
				...defaultValues,
				zoneId: selectedZone?.id
			}}
			onSubmit={onSubmit}
			isCreationForm={true}
		/>
	)
}

export default compose<any>(injectToolbarData(() => ({
	title: <FormattedMessage
		id="container.pageTitle.create"
		defaultMessage="Nouveau contenant"
		description="Page title"
	/>
})), injectSnackbarActions)(ContainerCreate)
