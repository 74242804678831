import { IntlShape } from 'react-intl'
import { ZoneModel } from '../modules/admin/zone/services/zoneDataModel'
import { CENTER_TYPE } from './constants'
import { languageChoices } from '../modules/admin/zone/services/zoneConsts'

export type CenterType = (typeof CENTER_TYPE)[keyof typeof CENTER_TYPE];

/**
 * Fonction pour récupérer le nom lié à un type de centre dans la configuration de la zone
 * @param intl
 * @param zone
 * @param centerType
 * @param needLowerCase
 * @returns string
 */
export const getCenterNameByType: (
	intl: IntlShape,
	zone: ZoneModel | null,
	centerType: CenterType,
	needLowerCase?: boolean
) => string = (intl, zone, centerType, needLowerCase = false) => {

	const getLowerCase = (name: string): string => (needLowerCase ? name.toLowerCase() : name)

	const defaultMessages = {
		[CENTER_TYPE.REFERENT]: intl.formatMessage({
			id: 'enum.centerType.REFERENT',
			defaultMessage: 'Centre référent'
		}),
		[CENTER_TYPE.DISPATCH]: intl.formatMessage({
			id: 'enum.centerType.DISPATCH',
			defaultMessage: 'Centre de dispatch'
		}),
		[CENTER_TYPE.VACCINATION]: intl.formatMessage({
			id: 'enum.centerType.VACCINATION',
			defaultMessage: 'Centre de vaccination'
		}),
		[CENTER_TYPE.VACCINATION_POINT]: intl.formatMessage({
			id: 'enum.centerType.VACCINATION_POINT',
			defaultMessage: 'Point de vaccination'
		})
	}

	if (!zone) {
		return defaultMessages[centerType] || intl.formatMessage({
			id: 'center.unknown',
			defaultMessage: 'Unknown Center'
		})
	}

	const { configZoneLanguages } = zone

	const matchingLanguage = languageChoices.find(
		(choice) => choice.libelle === intl.locale
	)

	const config = configZoneLanguages.find(
		(config) => config.idLanguage === matchingLanguage?.id
	)

	switch (centerType) {
		case CENTER_TYPE.REFERENT:
			return getLowerCase(config?.centreReferentName || defaultMessages[CENTER_TYPE.REFERENT])

		case CENTER_TYPE.DISPATCH:
			return getLowerCase(config?.centreDispatchName || defaultMessages[CENTER_TYPE.DISPATCH])

		case CENTER_TYPE.VACCINATION:
			return getLowerCase(config?.centreVaccinationName || defaultMessages[CENTER_TYPE.VACCINATION])

		case CENTER_TYPE.VACCINATION_POINT:
			return getLowerCase(config?.centreVaccinationPointName || defaultMessages[CENTER_TYPE.VACCINATION_POINT])

		default:
			return intl.formatMessage({
				id: 'center.unknown',
				defaultMessage: 'Unknown Center'
			})
	}
}