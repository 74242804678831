import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Field, Form } from 'react-final-form'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { FormattedMessage, useIntl } from 'react-intl'
import { OnChange } from 'react-final-form-listeners'
import { FormApi } from 'final-form'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Alert from '@material-ui/lab/Alert'
import moment from 'moment'
import PageFormContainer from '../../../../components/layout/PageFormContainer'
import FieldWeekPicker from '../../../../components/fields/FieldWeekPicker'
import { WeekRange } from '../../../../components/weekPicker/WeekPicker'
import FieldAutocomplete from '../../../../components/fields/FieldAutocomplete'
import { CentreModel } from '../../phidemData/phidemDataModel'
import { FormSelect } from 'isotope-client'
import { VaccinModel } from '../../../admin/product/services/productModel'
import Button from '../../../../components/layout/buttons/Button'
import { FetchProcurementProductsModel, ProcurementFormValues } from '../services/procurementModels'
import ErrorMessageRequired from '../../../../components/layout/errors/ErrorMessageRequired'
import ErrorPositiveValue from '../../../../components/layout/errors/ErrorPositiveValue'
import { CENTER_TYPE, PRODUCT_TYPES, QTY_MODE, SCREEN_SIZE } from '../../../../utils/constants'
import { PhidemDataContext } from '../../phidemData/PhidemDataContext'
import { fetchProcurementProducts } from '../services/procurementApi'
import Loader from '../../../../components/layout/Loader'
import TotalCountCaption from '../../../../components/fields/TotalCountCaption'
import FieldRadio from '../../../../components/fields/FieldRadio'
import DayTile from './DayTile'
import { DATE_FORMAT, displayDate } from '../../../../utils/dateUtils'
import { getCenterNameByType } from '../../../../utils/stringUtils'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		submitButtonRoot: {
			width: '100%',
			margin: 40,
			marginBottom: 100,
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'center',
			alignItems: 'center',
			[theme.breakpoints.down('md')]: {
				flexDirection: 'column',
				marginLeft: 0,
				marginRight: 0
			}
		},
		button: {
			marginBottom: theme.spacing(4),
			marginRight: theme.spacing(4)
		}
	})
)

export interface DayModel {
	key: string,
	value: string
}

const DAYS: DayModel[] = [
	{ key: 'lundi', value: 'MONDAY' },
	{ key: 'mardi', value: 'TUESDAY' },
	{ key: 'mercredi', value: 'WEDNESDAY' },
	{ key: 'jeudi', value: 'THURSDAY' },
	{ key: 'vendredi', value: 'FRIDAY' },
	{ key: 'samedi', value: 'SATURDAY' },
	{ key: 'dimanche', value: 'SUNDAY' }
]

interface ProcurementFormProps {
	initialValues: ProcurementFormValues | undefined
	isCreationForm: boolean
	nbDoses?: number
	snackError: (value: any) => void
	isLoading?: boolean
	isLoadingDelete?: boolean
	isVaccination?: boolean
	onSubmit: (event: any) => void
	handleDeleteProcurement?: () => void
	centerList: CentreModel[]
}

const ProcurementForm: React.FC<ProcurementFormProps> = (
	{
		initialValues,
		isCreationForm,
		nbDoses,
		snackError,
		isLoading = false,
		isLoadingDelete = false,
		isVaccination = false,
		onSubmit,
		handleDeleteProcurement,
		centerList
	}
) => {
	const isLargeScreen = useMediaQuery(`(min-width: ${SCREEN_SIZE.LARGE}px)`)
	const { vaccins, user: { isDateFormatEn, selectedLang, selectedZone } } = useContext(PhidemDataContext)
	const [showSection3, setShowSection3] = useState<boolean>(!isCreationForm)
	const [fetchedProducts, setFetchedProducts] = useState<VaccinModel[]>([])
	const intl = useIntl()
	const classes = useStyles()

	const fetchProducts = useCallback((values: FetchProcurementProductsModel, form?: FormApi<ProcurementFormValues>) => {
		setShowSection3(false)
		if (!!form) {
			form.change('idProduit', undefined)
		}
		fetchProcurementProducts(values)
			.then((response: VaccinModel[]) => {
				setFetchedProducts(response)
				setShowSection3(true)
			})
			.catch(() => {
				snackError({ id: 'global.error.occured', defaultMessage: 'Une erreur est survenue', description: 'Error message' })
			})
	}, [snackError])

	useEffect(() => {
		if (isVaccination) {
			fetchProducts({
				debutSemaine: initialValues?.weekRange.from?.toISOString() || '',
				idCentre: initialValues?.idCentre || ''
			})
		}
	}, [fetchProducts, initialValues, isVaccination])

	const onValidate = (formValues: ProcurementFormValues) => {
		const errors: any = {}

		if (!formValues.weekRange.from || !formValues.weekRange.to) {
			errors.weekRange = <ErrorMessageRequired />
		}
		if (!formValues.idCentre) {
			errors.idCentre = <ErrorMessageRequired />
		}
		if (!formValues.idProduit) {
			errors.idCentre = <ErrorMessageRequired />
		}
		DAYS.forEach((day: DayModel) => {
			if (formValues.modeQte === QTY_MODE.CONDITIONNEMENT) {
				const dayCond = formValues[day.key as keyof ProcurementFormValues]

				if (Number(dayCond) < 0) {
					errors[day.key] = <ErrorPositiveValue />
				}
			} else {
				const day1 = formValues[`${day.key}1` as keyof ProcurementFormValues]
				const day2 = formValues[`${day.key}2` as keyof ProcurementFormValues]

				if (Number(day1) < 0) {
					errors[`${day.key}1`] = <ErrorPositiveValue />
				}
				if (Number(day2) < 0) {
					errors[`${day.key}2`] = <ErrorPositiveValue />
				}
			}
		})

		return errors
	}

	const getDay = (week: WeekRange, day: number) => ` ${displayDate(moment(week.from).isoWeekday(day + 1), DATE_FORMAT.SHORT_DATE, isDateFormatEn)}`

	const resetQuantity = (form: any) => {
		DAYS.forEach((day: DayModel) => {
			form.change(`${day.key}`, 0)
			form.change(`${day.key}1`, 0)
			form.change(`${day.key}2`, 0)
		})
	}

	return isLoading
		?
		<Loader />
		:
		<Form
			initialValues={initialValues}
			onSubmit={onSubmit}
			validate={onValidate}
			render={({ handleSubmit, submitting, values, form }) => {
				const selectedType = vaccins.find(vaccin => vaccin.id === values.idProduit)?.typeConditionnement
				const selectedNbDoses = vaccins.find(vaccin => vaccin.id === values.idProduit)?.nbDoses

				return (
					<PageFormContainer onSubmit={handleSubmit} isLarge>
						<Grid item xs={10}>
							<Alert severity="info" variant="outlined">
								<FormattedMessage
									id="procurement.warning"
									defaultMessage="Attention, ceci n'est qu'une allocation. Cela n'exclue en rien la saisie d'une demande lorsque vous souhaitez un réapprovisionnement"
									description="Warning message"
								/>
							</Alert>
						</Grid>

						{/* SECTION 1 */}
						<Grid item xs={12}>
							<Typography variant={isLargeScreen ? 'h1' : 'h3'}>
								<FormattedMessage
									id="procurement.add.sections.weekSelect"
									defaultMessage="1. Sélection de la semaine"
									description="Form section title"
								/>
							</Typography>
						</Grid>
						<FieldWeekPicker
							name="weekRange"
							label={
								<FormattedMessage
									id="procurement.fieldsLabel.date"
									defaultMessage="Date"
									description="form input"
								/>
							}
							required
							disabled={!isCreationForm}
							isDateFormatEn={isDateFormatEn}
							selectedLang={selectedLang}
						/>
						<OnChange name="weekRange">
							{(value: WeekRange) => {
								if (value.from && value.to && values.idCentre && isCreationForm) {
									fetchProducts({
										debutSemaine: value.from.toISOString(),
										idCentre: values.idCentre
									}, form)
								}
							}}
						</OnChange>

						{/* SECTION 2 */}
						<Grid item xs={12}>
							<Typography variant={isLargeScreen ? 'h1' : 'h3'}>
								<FormattedMessage
									id="procurement.add.sections.centerSelect"
									defaultMessage="2. Sélection du centre"
									description="Form section title"
								/>
							</Typography>
						</Grid>
						<Grid item xs={12} md={5}>
							<Field
								name="idCentre"
								label={getCenterNameByType(intl, selectedZone, CENTER_TYPE.VACCINATION)}
								component={FieldAutocomplete}
								options={centerList.map((centre: CentreModel) => ({
									label: `${centre.nom}${centre.service ? ` - ${centre.service}` : ''}`,
									value: centre.id
								}))}
								required
								disabled={!isCreationForm || isVaccination}
							/>
							<OnChange name="idCentre">
								{(value: string) => {
									if (value && values.weekRange.from && values.weekRange.to && isCreationForm) {
										fetchProducts({
											debutSemaine: values.weekRange.from.toISOString(),
											idCentre: value
										}, form)
									}
								}}
							</OnChange>
						</Grid>

						{/* SECTION 3 */}
						{showSection3 &&
							<>
								<Grid item xs={12}>
									<Typography variant={isLargeScreen ? 'h1' : 'h3'}>
										<FormattedMessage
											id="procurement.add.sections.productSelect"
											defaultMessage="3. Sélection du produit"
											description="Form section title"
										/>
									</Typography>
								</Grid>

								{!isCreationForm || fetchedProducts.length > 0 ?
									<Grid item xs={12} md={5}>
										<Field
											name="idProduit"
											component={FormSelect}
											label={
												<FormattedMessage
													id="global.functional.product"
													defaultMessage="Produit"
													description="Product"
												/>
											}
											disabled={!isCreationForm}
										>
											{isCreationForm ?
												fetchedProducts.filter((product: VaccinModel) => product.type === PRODUCT_TYPES.VACCIN).map((product: VaccinModel) => (
													<MenuItem
														key={product.id}
														value={product.id}
													>
														{product.nomCommercial}
													</MenuItem>
												))
												:
												vaccins.map((vaccin: VaccinModel) => (
													<MenuItem
														key={vaccin.id}
														value={vaccin.id}
													>
														{vaccin.nomCommercial}
													</MenuItem>
												))
											}
										</Field>
									</Grid>
									:
									<Grid item xs={12}>
										<Typography variant={isLargeScreen ? 'body1' : 'body2'}>
											<FormattedMessage
												id="procurement.add.noProductsAvailable"
												defaultMessage="Aucun produits à ajouter"
												description="No products"
											/>
										</Typography>
									</Grid>
								}

								{/* SECTION 4 */}
								{values.idProduit &&
									<>
										<Grid item xs={12}>
											<Typography variant={isLargeScreen ? 'h1' : 'h3'}>
												<FormattedMessage
													id="procurement.add.sections.quantityTyping"
													defaultMessage="4. Saisie de l'allocation"
													description="Form section title"
												/>
											</Typography>
										</Grid>
										<Grid item xs={12}>
											<FieldRadio
												name="modeQte"
												label={<FormattedMessage
													id="vaccination.replenishment.modeQte"
													defaultMessage="Mode de saisie"
													description="Quantity mode selection"
												/>}
												choices={Object.keys(QTY_MODE).map((mode: string) => ({
													label: intl.formatMessage({
														id: `enum.qtyMode.${mode}`,
														defaultMessage: `Quantity mode ${mode}`,
														description: 'Quantity mode option'
													}),
													value: mode,
													disabled: !isCreationForm
												}))}
											/>
											<OnChange name="modeQte">
												{() => resetQuantity(form)}
											</OnChange>
										</Grid>
										<Grid container item xs={12} spacing={2}>
											{values.modeQte ?
												DAYS.map((day: DayModel, index: number) => (
													<Grid container item xs={3} key={`DAY${index}`}>
														<DayTile
															title={
																<span>
											                <FormattedMessage
												                id={`enum.days.${day.value}`}
												                defaultMessage={`DAY ${index}`}
												                description="label input"
											                />
																	{getDay(values.weekRange, index)}
											            </span>
															}
															day={day}
															type={selectedType || ''}
															nbDoses={selectedNbDoses || 0}
															values={values}
														/>
													</Grid>
												))
												:
												<></>
											}
										</Grid>

										<Grid item xs={12}>
											<TotalCountCaption
												labelId="procurement.countDoses"
												count={DAYS.reduce((acc: number, curr: DayModel) => {
													const nbDosesForProduct = (nbDoses || fetchedProducts?.find(product => product.id === values.idProduit)?.nbDoses) || 1
													if (values.modeQte === QTY_MODE.CONDITIONNEMENT) {
														return acc + (Number(values[curr.key as keyof ProcurementFormValues] || 0) * nbDosesForProduct)
													}

													return acc + Math.ceil((Number(values[`${curr.key}1` as keyof ProcurementFormValues]) + Number(values[`${curr.key}2` as keyof ProcurementFormValues]) || 0) / nbDosesForProduct)
												}, 0)}
												type={values.modeQte === QTY_MODE.CONDITIONNEMENT ? 'dose' : vaccins.find(product => product.id === values.idProduit)?.typeConditionnement}
											/>
										</Grid>

										<div
											className={classes.submitButtonRoot}
											style={{
												justifyContent: isLargeScreen ? 'flex-start' : 'center'
											}}
										>
											{isCreationForm ?
												<Button type="submit" variant="contained" isLoading={submitting} className={classes.button}>
													<FormattedMessage
														id="button.create"
														defaultMessage="Créer"
														description="Message on form submission button"
													/>
												</Button>
												:
												<>
													<Button type="submit" variant="contained" isLoading={submitting} className={classes.button}>
														<FormattedMessage
															id="button.save"
															defaultMessage="Sauvegarder"
															description="Message on form submission button"
														/>
													</Button>
													{handleDeleteProcurement &&
														<Button
															variant="contained"
															onClick={handleDeleteProcurement}
															isLoading={isLoadingDelete}
															className={classes.button}
														>
															<FormattedMessage
																id="button.delete"
																defaultMessage="Supprimer"
																description="Message on form submission button"
															/>
														</Button>
													}
												</>
											}
										</div>
									</>
								}
							</>
						}
					</PageFormContainer>
				)
			}}
		/>
}

export default ProcurementForm
