import { fetchFactory } from 'isotope-client'
import { loadEntries } from 'isotope-client/modules/valueList/services/valueListApi'

export const getAllProducts = (zoneId?: string) => fetchFactory(`/referentiel/liste/produit${zoneId ? `?zoneId=${zoneId}` : ''}`)
export const getAllCarriers = (zoneId?: string) => fetchFactory(`/referentiel/liste/transporteur${zoneId ? `?zoneId=${zoneId}` : ''}`)
export const getAllContainers = () => fetchFactory('/referentiel/liste/contenant')
export const getAllTrackers = () => fetchFactory('/referentiel/liste/tracker')
export const getAvailableTemplates = (zoneId?: string) => fetchFactory(`/template/available${zoneId ? `?zoneId=${zoneId}` : ''}`)

export const getMyCenter = () => fetchFactory('/centre/me')

export const getMyCenterList = () => fetchFactory('/centre/me/list')

export const getValueListByShortcut = (shortcut: string, languageId: string) => loadEntries(shortcut, false, languageId)
