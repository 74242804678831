import { getCenterNameByType } from '../../../../utils/stringUtils'
import { UserRoles } from './userEnums'
import { IntlShape } from 'react-intl'
import { CENTER_TYPE } from '../../../../utils/constants'
import { ZoneModel } from '../../../admin/zone/services/zoneDataModel'

/**
 * Render Role with Center Name
 * @param roleCode
 * @param intl
 * @param selectedZone
 */
export const renderRoleWithCenterName = (roleCode: UserRoles, intl: IntlShape, selectedZone: ZoneModel | null): string => {
	switch (roleCode) {
		case UserRoles.DISPATCH_CENTER:
			return getCenterNameByType(intl, selectedZone, CENTER_TYPE.DISPATCH)
		case UserRoles.VACCINATION_CENTER:
			return getCenterNameByType(intl, selectedZone, CENTER_TYPE.VACCINATION)
		case UserRoles.VACCINATION_POINT:
			return getCenterNameByType(intl, selectedZone, CENTER_TYPE.VACCINATION_POINT)
		case UserRoles.REFERENT:
			return getCenterNameByType(intl, selectedZone, CENTER_TYPE.REFERENT)
		case UserRoles.ADMIN_DISPATCH:
			return intl.formatMessage({ id: roleCode }, { dispatchCenter: getCenterNameByType(intl, selectedZone, CENTER_TYPE.DISPATCH, true) })
		default:
			return intl.formatMessage({ id: roleCode })
	}
}