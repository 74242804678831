import React, { useContext } from 'react'
import { PhidemDataContext } from '../../common/phidemData/PhidemDataContext'
import UserForm from './UserForm'
import { useIntl } from 'react-intl'

const UserFormWrapper = (props) => {
	const intl = useIntl()
	const { user: { selectedZone } } = useContext(PhidemDataContext)

	return <UserForm {...props} intl={intl} selectedZone={selectedZone} />
}

export default UserFormWrapper
