export const colors = {
    primary: '#EA4E26',
    secondary: '#19ae88',
    elementOnPrimary: '#fff',
    input: '#f6f6f6',
    placeholder: '#88888a',
    text: '#55555a',
    displayText: '#757575',
    background: '#EEF4F4',
    snackSuccess: '#4caf50',
    snackInfo: '#5c6bc0',
    snackError: '#f44336',
    notFound: '#919191',
    disabled: '#a0a0a0'
}

export const DOWNLOAD_FILE_TYPE = {
    CENTRES: {
        type: 'CENTRE',
        outputFile: 'centres.xlsx'
    },
    DISPATCH_BL: {
        type: 'DISPATCH_BL',
        outputFile: 'bl.pdf'
    },
    DISPATCH_ETIQUETTE: {
        type: 'DISPATCH_ETIQUETTE',
        outputFile: 'etiquette.pdf'
    },
    DISPATCH_FLACON_36: {
        type: 'DISPATCH_FLACON_36',
        outputFile: 'flacon_36.pdf'
    },
    DISPATCH_ETIQUETTE_CONTENANT: {
        type: 'DISPATCH_ETIQUETTE_CONTENANT',
        outputFile: 'etiquette_contenant.pdf'
    },
    DISPATCH_DECHET: {
        type: 'DISPATCH_DECHET',
        outputFile: 'dechet.pdf'
    },
    DISPATCH_RETOUR: {
        type: 'DISPATCH_RETOUR',
        outputFile: 'retour.pdf'
    },
    DISPATCH_REFERENT_STOCK: {
        type: 'DISPATCH_STOCK',
        outputFile: 'stocks.xlsx'
    },
    DISPATCH_MOUVEMENT: {
        type: 'DISPATCH_MOUVEMENT',
        outputFile: 'mouvements.xlsx'
    },
    VACCINATION_DECHET: {
        type: 'VACCINATION_DECHET',
        outputFile: 'dechet.pdf'
    },
    VACCINATION_BESOIN: {
        type: 'VACCINATION_BESOIN',
        outputFile: 'demandes.xlsx'
    },
    VACCINATION_APPROVISIONNEMENT: {
        type: 'VACCINATION_APPROVISIONNEMENT',
        outputFile: 'previsions.xlsx'
    },
    VACCINATION_APPROVISIONNEMENT_CUMULE: {
        type: 'VACCINATION_APPROVISIONNEMENT_CUMULE',
        outputFile: 'previsions-cumulees.xlsx'
    },
    VACCINATION_ETIQUETTE: {
        type: 'VACCINATION_ETIQUETTE',
        outputFile: 'etiquette_vaccination.pdf'
    },
    VACCINATION_STOCK: {
        type: 'VACCINATION_STOCK',
        outputFile: 'stocks.xlsx'
    },
    VACCINATION_MOUVEMENT: {
        type: 'VACCINATION_MOUVEMENT',
        outputFile: 'mouvements.xlsx'
    },
    ADMINISTRATIONS: {
        type: 'ADMINISTRATION',
        outputFile: 'administration.xlsx'
    },
    POINT_VACCINATION_BESOIN: {
        type: 'POINT_VACCINATION_BESOIN',
        outputFile: 'demandes-point.xlsx'
    },
    REFERENT_BL: {
        type: 'REFERENT_BL',
        outputFile: 'bl.pdf'
    },
    REFERENT_ETIQUETTE: {
        type: 'REFERENT_ETIQUETTE',
        outputFile: 'etiquette.pdf'
    },
    REFERENT_FLACON_36: {
        type: 'REFERENT_FLACON_36',
        outputFile: 'flacon_36.pdf'
    },
    REFERENT_ETIQUETTE_CONTENANT: {
        type: 'REFERENT_ETIQUETTE_CONTENANT',
        outputFile: 'etiquette_contenant.pdf'
    },
    REFERENT_DECHET: {
        type: 'REFERENT_DECHET',
        outputFile: 'dechet.pdf'
    },
}

export const TEMPLATE_TYPES = {
    REFERENT: {
        REFERENT_BL: 'REFERENT_BL',
        REFERENT_ETIQUETTE: 'REFERENT_ETIQUETTE',
        REFERENT_FLACON: 'REFERENT_FLACON',
        REFERENT_ETIQUETTE_CONTENANT: 'REFERENT_ETIQUETTE_CONTENANT',
        REFERENT_DECHET: 'REFERENT_DECHET'
    },
    DISPATCH: {
        DISPATCH_BL: 'DISPATCH_BL',
        DISPATCH_ETIQUETTE: 'DISPATCH_ETIQUETTE',
        DISPATCH_FLACON_36: 'DISPATCH_FLACON_36',
        DISPATCH_ETIQUETTE_CONTENANT: 'DISPATCH_ETIQUETTE_CONTENANT',
        DISPATCH_DECHET: 'DISPATCH_DECHET',
        DISPATCH_RETOUR: 'DISPATCH_RETOUR',
    },
    VACCINATION: {
        VACCINATION_DECHET: 'VACCINATION_DECHET',
        VACCINATION_ETIQUETTE: 'VACCINATION_ETIQUETTE'
    }
}

export const CENTER_TYPE = {
    REFERENT: 'REFERENT',
    DISPATCH: 'DISPATCH',
    VACCINATION: 'VACCINATION',
    VACCINATION_POINT: 'VACCINATION_POINT'
}

export const CENTER_TYPE_LOWERCASE = {
    REFERENT: 'referent',
    DISPATCH: 'dispatch',
    VACCINATION: 'vaccination',
    VACCINATION_POINT: 'vaccination_point'
}

export const USER_ROLE = {
    ROLE_ADMIN: 'ROLE_ADMIN',
    ROLE_REFERENT: 'ROLE_REFERENT',
    ROLE_ADMIN_DISPATCH: 'ROLE_ADMIN_DISPATCH',
    ROLE_DISPATCH_CENTER: 'ROLE_DISPATCH_CENTER',
    ROLE_VACCINATION_CENTER: 'ROLE_VACCINATION_CENTER',
    ROLE_VACCINATION_POINT: 'ROLE_VACCINATION_POINT'
}

export const SELECT_VALUES = {
    ALL: 'ALL',
    NONE: 'NONE',
    DEFAULT: 'DEFAULT'
}

export const TDB_COUNT = {
    ENTRY_STOCK: 'enterStock',
    PREPARATION: 'preparation',
    EXIT_FRIDGE: 'exitFridge',
    DECHET: 'scrapping'
}

export const MOVEMENT_SENS = {
    ENTREE: 'ENTREE',
    SORTIE: 'SORTIE'
}

export const MOVEMENT_TYPE = {
    ENTREE: 'ENTREE',
    REASSORT: 'REASSORT',
    PREPARATION: 'PREPARATION',
    SORTIE_FRIGO: 'SORTIE_FRIGO',
    SORTIE_POINT: 'SORTIE_POINT',
    DECHET: 'DECHET',
    CORRECTION: 'CORRECTION',
    RETOUR: 'RETOUR',
    MODIF_PEREMPTION: 'MODIF_PEREMPTION'
}

export const SCREEN_SIZE = {
    LARGE: 960
}

export const DAYS = {
    LUNDI: 'LUNDI',
    MARDI: 'MARDI',
    MERCREDI: 'MERCREDI',
    JEUDI: 'JEUDI',
    VENDREDI: 'VENDREDI',
    SAMEDI: 'SAMEDI',
    DIMANCHE: 'DIMANCHE'
}

export const MONTHS = {
    JANUARY: 'JANUARY',
    FEBRUARY: 'FEBRUARY',
    MARCH: 'MARCH',
    APRIL: 'APRIL',
    MAY: 'MAY',
    JUNE: 'JUNE',
    JULY: 'JULY',
    AUGUST: 'AUGUST',
    SEPTEMBER: 'SEPTEMBER',
    OCTOBER: 'OCTOBER',
    NOVEMBER: 'NOVEMBER',
    DECEMBER: 'DECEMBER'
}

export const EXPIRATION_YEARS_ADD_COUNT = 30

export const DATE_ROLE = {
    CREATION: 'CREATION',
    RECEPTION: 'RECEPTION'
}

export const CENTER_STATUS = {
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE',
    INDIFFERENT: 'INDIFFERENT'
}

export const REPLENISHMENT_STATUS = {
    TRAITE: 'TRAITE',
    DEPART_MARCHANDISES: 'DEPART_MARCHANDISES',
    CONFIRME: 'CONFIRME',
    REFUSE: 'REFUSE',
    EN_ATTENTE: 'EN_ATTENTE',
    ANNULE: 'ANNULE'
}

export const STATUT_LIGNE_BL = {
    PREPARE: 'PREPARE',
    LIVRE: 'LIVRE',
    EPUISE: 'EPUISE',
    PERIME: 'PERIME',
    ANNULE: 'ANNULE'
}

export const MAIL = {
    REFERENT: {
        RECEPTION_STOCK_TO_REFERENT: 'RECEPTION_STOCK_TO_REFERENT',
    },
    DISPATCH: {
        BESOIN_APPROVISIONNEMENT: 'BESOIN_APPROVISIONNEMENT',
        RECEPTION_STOCK_VACCINATION: 'RECEPTION_STOCK_VACCINATION',
        ANNULATION_APPROVISIONNEMENT: 'ANNULATION_APPROVISIONNEMENT'
    },
    VACCINATION: {
        TRAITEMENT_APPROVISIONNEMENT: 'TRAITEMENT_APPROVISIONNEMENT',
        CONFIRMATION_APPROVISIONNEMENT: 'CONFIRMATION_APPROVISIONNEMENT',
        REFUS_APPROVISIONNEMENT: 'REFUS_APPROVISIONNEMENT',
        BESOIN_APPROVISIONNEMENT_POINT: 'BESOIN_APPROVISIONNEMENT_POINT',
        ANNULATION_APPROVISIONNEMENT_POINT: 'ANNULATION_APPROVISIONNEMENT_POINT'
    },
    VACCINATION_POINT: {
        TRAITEMENT_APPROVISIONNEMENT: 'TRAITEMENT_APPROVISIONNEMENT'
    }
}

export const RECEIVER_MAIL = {
    CENTER: 'CENTER',
    REFERENT: 'REFERENT',
    BOTH: 'BOTH'
}

export const TAG_CATEGORIES = {
    TRANSPORTEUR: 'TRANSPORTEUR',
    TYPE_CENTRE: 'TYPE_CENTRE',
    TOURNEE: 'TOURNEE',
    SECTEUR: 'SECTEUR',
    AUTRE: 'AUTRE'
}

export const QTY_MODE = {
    CONDITIONNEMENT: 'CONDITIONNEMENT',
    DOSE: 'DOSE'
}

export const ADMIN_MODE = {
    JOUR: 'JOUR',
    SEMAINE: 'SEMAINE'
}

// Type vaccin figé dans l'application
// Les autres produits sont régit par une liste de valeur
export const PRODUCT_TYPES = {
    VACCIN: 'VACCIN'
}

export const PRODUCT_EXPIRATION_TYPE = {
    JOUR: 'JOUR',
    MOIS: 'MOIS'
}

export const REPLENISHMENT_DIALOG = {
    REFUSE: 'REFUSE',
    CONFIRM: 'CONFIRM',
    PROCESS: 'PROCESS',
    PROCUREMENT: 'PROCUREMENT',
    CANCEL: 'CANCEL',
    PREPARE: 'PREPARE',
    CANCEL_CONFIRM: 'CANCEL_CONFIRM',
    DEPART_MARCHANDISES: 'DEPART_MARCHANDISES',
    MULTIPLE_CONFIRM: 'MULTIPLE_CONFIRM'
}

export const TRANSFERT_TYPE = {
    CONGELE: 'CONGELE',
    MOINS_20: 'MOINS_20',
    DECONGELE: 'DECONGELE'
}

export const EXIT_TYPE = {
    INTERNE: 'INTERNE',
    VACCINATION_POINT: 'VACCINATION_POINT'
}

export const EXIT_MODE = {
    FLACON: 'FLACON',
    SERINGUE: 'SERINGUE'
}

export const PEREMPTION_HOUR = 23

export const VALUE_LIST_SHORTCUT = {
    PRODUCT_TYPE: 'PRODUCT_TYPE',
    SCRAPPING_CAUSE: 'SCRAPPING_CAUSE'
}

export const LANGUAGES_CODE = {
    FR: 'fr',
    EN: 'en'
}

export const LANGUAGES_ID = {
    FR: '1',
    EN: '2'
}

export const LOCAL_STORAGE_ITEM = {
    TOKEN: 'token',
    CENTER: 'centerId',
    ZONE: 'zoneId',
    OFFLINE_MODE: 'offline-mode',
    OFFLINE_ACTIONS: 'offline-actions',
    OFFLINE_DATA: 'offline-data'
}

export const ORIGIN = {
    REFERENT: 'REFERENT',
    EXTERNAL: 'EXTERNAL'
}

export const SCRAPPING_OTHER_OPTION = 'AUTRE'
