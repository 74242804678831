import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { OnChange } from 'react-final-form-listeners'
import { Box, Button, Tab, Tabs } from '@material-ui/core'
import { MenuManagement, MenuManagementByRoleForm, MenuManagementGroupByCodeMenu } from './services/menuManagement.types'
import {
	getMenuManagementByRole,
	getMenuManagementByZone,
	getMenuManagementMaxId,
	getMenuRoles,
	updateMenuManagementByRole,
	updateMenuManagementByZone
} from './services/menuManagement.api'
import { PhidemDataContext } from '../../common/phidemData/PhidemDataContext'
import { ZoneModel } from '../zone/services/zoneDataModel'
import { FormattedMessage, useIntl } from 'react-intl'
import { FormSelect, MultipleChoice } from 'isotope-client'
import Grid from '@material-ui/core/Grid'
import Table from '@material-ui/core/Table'
import TableContainer from '@material-ui/core/TableContainer'
import Paper from '@material-ui/core/Paper'
import TableHead from '@material-ui/core/TableHead'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import { MenuManagementTab } from './services/menuManagement.enums'
import { sortRolesByImportance } from '../../common/user/userUtils'
import MenuItem from '@material-ui/core/MenuItem'
import { UserRoles } from '../../user/components/services/userEnums'
import FormCheckboxMenu from './components/FormCheckboxMenu'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { injectActions as injectSnackbarActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import actions from 'redux-form/lib/actions'

type MenuManagementPageProps = {
	snackSuccess: (value: any) => void
	snackError: (value: any) => void
}

const MenuManagementPage: React.FC<MenuManagementPageProps> = ({ snackSuccess, snackError }) => {
	const { zones, user: { selectedZone, isSuperAdmin } } = useContext(PhidemDataContext)
	const intl = useIntl()
	const [maxId, setMaxId] = useState<number>(0)
	const [selectedTab, setSelectedTab] = useState(isSuperAdmin ? MenuManagementTab.ZoneTab : MenuManagementTab.RoleTab)
	const [sortedZones, setSortedZones] = useState<ZoneModel[]>([])
	const [menuManagementList, setMenuManagementList] = useState<MenuManagementGroupByCodeMenu[]>([])
	const [menuManagementRoleList, setMenuManagementRoleList] = useState<MenuManagementGroupByCodeMenu[]>([])
	const [roleList, setRoleList] = useState<Role[]>([])
	const [selectedRoles, setSelectedRoles] = useState<Role[]>([])
	const [selectedZoneId, setSelectedZoneId] = useState<string | null>(selectedZone?.id)

	useEffect(() => {
		getMenuRoles()
			.then((data: Role[]) => {
				let filteredRoles = data.filter(role => role.code !== UserRoles.ADMIN)
				if (!isSuperAdmin) {
					filteredRoles = filteredRoles.filter(role => role.code !== UserRoles.ADMIN_ZONE)
				}
				setRoleList(sortRolesByImportance(filteredRoles))
			})
	}, [isSuperAdmin])

	useEffect(() => {
		getMenuManagementByZone()
			.then(setMenuManagementList)
	}, [])

	useEffect(() => {
		getMenuManagementMaxId()
			.then(setMaxId)
	}, [])

	const handleZoneChange = (zoneId: string) => {
		getMenuManagementByRole(zoneId).then(setMenuManagementRoleList)
	}

	useEffect(() => {
		if (selectedZoneId !== null) {
			handleZoneChange(selectedZoneId)
		}
	}, [selectedZoneId])

	const handleTabChange = (event: any, newValue: number) => {
		setSelectedRoles([])
		setSortedZones(zones.filter((zone, index) => index < 3 && zone !== null).sort((a, b) => a.id.localeCompare(b.id)))
		setSelectedZoneId(null)
		setSelectedTab(newValue)
	}

	const onSubmit = (values: any) => {
		updateMenuManagementByZone(values)
			.then(() => snackSuccess({
				id: 'menuManagement.success'
			}))
			.catch(() => snackError({ id: 'global.error.occured', defaultMessage: 'Une erreur est survenue', description: 'Error message' }))
	}

	const onSubmitRole = (values: MenuManagementByRoleForm) => {
		updateMenuManagementByRole(values)
			.then(() => snackSuccess({ id: 'menuManagement.success' }))
			.catch(() => snackError({ id: 'global.error.occured', defaultMessage: 'Une erreur est survenue', description: 'Error message' }))
	}

	const handleSelectedZonesChange = (selectedZoneIds: string[]) => {
		setSortedZones(zones
			.filter((zone) => selectedZoneIds.includes(zone.id))
			.sort((a, b) => a.id.localeCompare(b.id)))
	}

	const handleSelectedRolesChange = (selectedRolesIds: string[]) => {
		setSelectedRoles(roleList
			.filter((role) => selectedRolesIds.includes(role.id)))
	}

	const initialValues = useMemo(() => {
		const matrix = new Array(maxId).fill(null).map((_, index) => ({ id: index, value: false, changedInBo: false }))

		menuManagementList.forEach((group) => {
			group.listMenus.forEach((menuManagement: MenuManagement) => {
				const index = parseInt(menuManagement.id, 10)
				if (!isNaN(index)) {
					matrix[index] = { id: index, value: menuManagement.zoneActive, changedInBo: true }
				}
			})
		})

		// Default 3 first zones displayed
		return { matrix, selectedZones: zones.filter((zone, index) => index < 3 && zone !== null).map(zone => zone.id) }
	}, [menuManagementList, zones, maxId])

	const initialValuesRoles = useMemo(() => {
		// UserRoles - 1 because super admin hasn't menu management
		const matrixRole = new Array((Object.keys(UserRoles).length - 1) * menuManagementRoleList.length).fill(null).map((_, index) => ({
			id: index,
			value: false,
			changedInBo: false
		}))

		menuManagementRoleList.forEach((group) => {
			group.listMenus.forEach((menuManagement: MenuManagement) => {
				const index = parseInt(menuManagement.id, 10)
				if (!isNaN(index)) {
					matrixRole[index] = { id: index, value: menuManagement.roleActive, changedInBo: true }
				}
			})
		})
		return { matrixRole, zoneId: selectedZoneId, selectedRoles: roleList.map(role => role.id) }
	}, [menuManagementRoleList, roleList, selectedZoneId])

	return (
		<Box style={{ padding: '10px' }}>
			{isSuperAdmin &&
				<Tabs
					value={selectedTab}
					onChange={handleTabChange}
					indicatorColor="primary"
					textColor="primary"
				>
					<Tab label={intl.formatMessage({ id: 'menuManagement.tabZone' })} />
					<Tab label={intl.formatMessage({ id: 'menuManagement.tabRole' })} />
				</Tabs>
			}
			{selectedTab === MenuManagementTab.ZoneTab &&
				<Box style={{ paddingTop: '20px' }}>
					<Form
						initialValues={initialValues}
						onSubmit={onSubmit}
						render={({ handleSubmit }) => (
							<form onSubmit={handleSubmit}>
								<Grid>
									<Field
										name="selectedZones"
										component={MultipleChoice}
										fullWidth
										choices={zones.map(zone => zone.id)}
										getLabelElement={(zoneId: string) =>
											zones.find(zone => zone.id === zoneId)?.name
										}
										label={
											<FormattedMessage
												id="menuManagement.zones"
												defaultMessage="Zone(s) sélectionnée(s)"
												description="SelectedZones Label"
											/>
										}
									/>
								</Grid>
								<OnChange name="selectedZones">
									{(value: string[]) => handleSelectedZonesChange(value)}
								</OnChange>
								{sortedZones.length > 0 &&
									<TableContainer component={Paper}>
										<Table size="small" aria-label="dense table">
											<TableHead>
												<TableRow>
													<TableCell></TableCell>
													{sortedZones.length > 0 &&
														sortedZones.map((zone, colIndex) => (
															<TableCell align="center" key={`header-${colIndex}`}>
																{zone?.name}
															</TableCell>
														))}
												</TableRow>
											</TableHead>
											<TableBody>
												{menuManagementList.map((group, rowIndex) => (
													<TableRow key={`row-${rowIndex}`}>
														<TableCell component="th" scope="row">
															<FormattedMessage id={group.codeMenu} />
														</TableCell>

														{sortedZones.map((zone) => {
															const menuManagement = group.listMenus.find((item) => item.zone.id === zone.id)
															return (
																<TableCell align="center">
																	<Field
																		name={`matrix[${menuManagement?.id}].value`}
																		component={FormCheckboxMenu}
																	/>
																</TableCell>
															)
														})}
													</TableRow>
												))}
											</TableBody>
										</Table>
									</TableContainer>}
								<Box style={{ marginTop: '20px' }}>
									<Button variant="contained" color="primary" type="submit" disabled={sortedZones.length === 0}>
										<FormattedMessage id="btn.save" />
									</Button>
								</Box>
							</form>
						)}
					/>
				</Box>
			}
			{selectedTab === MenuManagementTab.RoleTab &&
				<Box style={{ paddingTop: '20px' }}>
					<Form
						initialValues={initialValuesRoles}
						onSubmit={onSubmitRole}
						render={({ handleSubmit }) => (
							<form onSubmit={handleSubmit}>
								<Grid>
									{isSuperAdmin &&
										<Grid xs={3}>
											<Field
												name="zoneId"
												component={FormSelect}
												required
												label={
													<FormattedMessage
														id="product.Zone"
														defaultMessage="Zone"
														description="Zone"
													/>
												}
											>
												{zones.map((zone) => (
													<MenuItem key={zone.id} value={zone.id}>
														{zone.name}
													</MenuItem>
												))}
											</Field>
										</Grid>
									}
									<OnChange name="zoneId">
										{(value: string) => {
											setSelectedZoneId(value)
										}}
									</OnChange>
									<Field
										name="selectedRoles"
										component={MultipleChoice}
										fullWidth
										choices={roleList.map(role => role.id)}
										getLabelElement={(roleId: string) => <FormattedMessage id={roleList.find(role => role.id === roleId)?.code} />}
										label={
											<FormattedMessage
												id="menuManagement.roles"
												defaultMessage="Profil(s) sélectionné(s)"
												description="SelectedProfiles Label"
											/>
										}
									/>
								</Grid>
								<OnChange name="selectedRoles">
									{(value: string[]) => handleSelectedRolesChange(value)}
								</OnChange>
								{selectedRoles.length > 0 &&
									<TableContainer component={Paper}>
										<Table size="small" aria-label="dense table">
											<TableHead>
												<TableRow>
													<TableCell></TableCell> {/* Empty Cell */}
													{selectedRoles.length > 0 &&
														selectedRoles.map((role, colIndex) => (
															<TableCell align="center" key={`header-${colIndex}`}>
																<FormattedMessage id={role.code} />
															</TableCell>
														))}
												</TableRow>
											</TableHead>
											<TableBody>
												{menuManagementRoleList.map((group, rowIndex) => (
													<TableRow key={`row-${rowIndex}`}>
														<TableCell component="th" scope="row">
															<FormattedMessage id={group.codeMenu} />
														</TableCell>

														{selectedRoles.map((role) => {
															const menuManagement = group.listMenus.find((item) => item.role.id === role.id)
															if (menuManagement === undefined) {
																return (<TableCell>
																	<Box style={{ padding: '20px' }}>
																		{/*	Nothing because no menu for this combination of role/menu*/}
																	</Box>
																</TableCell>)
															} else {
																return (
																	<TableCell align="center">
																		<Field
																			name={`matrixRole[${menuManagement.id}].value`}
																			disabled={!menuManagement.zoneActive}
																			component={FormCheckboxMenu}
																		/>
																	</TableCell>
																)
															}
														})}
													</TableRow>
												))}
											</TableBody>
										</Table>
									</TableContainer>}

								<Box style={{ marginTop: '20px' }}>
									<Button variant="contained" color="primary" type="submit" disabled={selectedRoles.length === 0 || selectedZoneId === null}>
										<FormattedMessage id="btn.save" />
									</Button>
								</Box>
							</form>
						)}
					/>
				</Box>
			}
		</Box>
	)
}

export default compose<any>(
	connect(null, actions),
	injectSnackbarActions
)(MenuManagementPage)
