import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import { Assignment, HourglassEmpty, Search } from '@material-ui/icons'
import { FormApi } from 'final-form'
import { dataTableActions, FormSelect, FormSwitch, SpringDataTable } from 'isotope-client'
import { injectActions as injectSnackbarActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import moment from 'moment'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { OnChange } from 'react-final-form-listeners'
import { FormattedMessage, useIntl } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'redux'
import FieldAutocomplete from '../../../components/fields/FieldAutocomplete'
import FieldDatetime from '../../../components/fields/FieldDatetime'
import Button from '../../../components/layout/buttons/Button'
import ExportExcelButton from '../../../components/layout/buttons/ExportExcelButton'
import ErrorMessageRequired from '../../../components/layout/errors/ErrorMessageRequired'
import NotFoundPage from '../../../components/layout/NotFoundPage'
import PageLayout from '../../../components/layout/PageLayout'
import {
	CENTER_TYPE,
	colors,
	DATE_ROLE,
	DOWNLOAD_FILE_TYPE,
	PRODUCT_TYPES,
	QTY_MODE,
	REPLENISHMENT_DIALOG,
	REPLENISHMENT_STATUS,
	SELECT_VALUES,
	USER_ROLE
} from '../../../utils/constants'
import { dateAndTimeDecorator } from '../../../utils/form/decorators'
import { isDateInputTypeSupported } from '../../../utils/form/inputTypes'
import { ProductModel, VaccinModel } from '../../admin/product/services/productModel'
import { updatePointReplenishmentStatus } from '../../pointVaccination/services/pointVaccinationApi'
import { PhidemDataContext } from '../phidemData/PhidemDataContext'
import { CentreModel } from '../phidemData/phidemDataModel'
import DialogReplenishmentActions from './components/DialogReplenishmentActions'
import { PointReplenishmentModel, ReplenishmentDialogDataModel } from './ReplenishmentModel'
import MailTo from '../../../components/layout/MailTo'
import { DATE_FORMAT, DATE_FORMAT_IN_FORM, displayDate, formatDateInForm } from '../../../utils/dateUtils'
import { getAllProducts } from '../phidemData/phidemDataApi'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		paperForm: {
			width: '70%',
			minWidth: 300,
			maxWidth: 880,
			padding: theme.spacing(2),
			marginBottom: 50
		},
		countLine: {
			margin: 4
		},
		submitButtonRoot: {
			width: '100%',
			margin: theme.spacing(1),
			display: 'flex',
			justifyContent: 'flex-end'
		},
		checkCircleIcon: {
			color: colors.snackSuccess
		},
		cancelIcon: {
			color: colors.snackError
		},
		button: {
			color: 'black',
			backgroundColor: 'lightgrey'
		}
	})
)

interface Column {
	key: string,
	name: React.ReactElement,
	sortable?: boolean,
	render?: (row: any) => React.ReactElement
}

interface ReplenishmentListProps {
	role: string,
	refreshTable: () => void,
	snackSuccess: (value: any) => void,
	snackError: (value: any) => void,
	clearRows: () => void
}

const initDialogData = {
	id: '',
	newStatut: '',
	nbFlacons: 0,
	dateReception: '',
	productName: '',
	productModeQte: QTY_MODE.DOSE,
	productQuantity1: 0,
	productQuantity2: 0,
	productQuantityCond: 0,
	productTotalQuantity: 0,
	productCond: '',
	destinationCenter: '',
	nbDoses: 0
}

interface FormValues {
	idProduit?: string
	idCentre?: string
	idZone?: string
	// @ts-ignore
	dateRole: DATE_ROLE.CREATION | DATE_ROLE.RECEPTION | SELECT_VALUES.NONE
	startDatetime?: string
	startDate?: string
	startTime?: string
	endDatetime?: string
	endDate?: string
	endTime?: string
	isDateDemande: boolean
	isDateReception: boolean
	statut: string[]
}

const VaccinPointReplenishmentList: React.FC<ReplenishmentListProps> = (
	{
		role,
		refreshTable,
		snackSuccess,
		snackError,
		clearRows
	}
) => {
	const classes = useStyles()
	const intl = useIntl()
	const { vaccins, user: { selectedCenter, isDateFormatEn, isSuperAdmin }, zones, application: { replenishmentPoint } } = useContext(PhidemDataContext)
	// Gestion de la visibilité des dialog d'action
	const [openDialogType, setOpenDialogType] = React.useState<string | undefined>(undefined)
	// Data affichée aux dialogues d'actions
	const [selectedReplenishment, setSelectedReplenishment] = useState<ReplenishmentDialogDataModel>(initDialogData)
	// Loader de submit
	const [submitLoading, setSubmitLoading] = useState<boolean>(false)
	const [processedVaccins, setProccessedVaccins] = useState<VaccinModel[]>(vaccins)

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			replenishmentPoint.setFilters({
				...replenishmentPoint.filters,
				idZone: zones.length > 0 ? zones[0]?.id : undefined
			})
		}, 500)

		return () => clearTimeout(timeoutId)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [zones])

	// Filtre date
	const isDatetimeSupported = isDateInputTypeSupported('datetime-local')
	const formDecorators = useMemo(() => isDatetimeSupported
			? []
			:
			[
				dateAndTimeDecorator('startDate', 'startTime', 'startDatetime'),
				dateAndTimeDecorator('endDate', 'endTime', 'endDatetime'),
				dateAndTimeDecorator('startCreationDate', 'startCreationTime', 'startCreationDatetime'),
				dateAndTimeDecorator('endCreationDate', 'endCreationTime', 'endCreationDatetime')
			]
		, [isDatetimeSupported])

	// Open / Close des dialog d'action
	const openDialogAction = (data: ReplenishmentDialogDataModel, dialogType: string) => {
		setSelectedReplenishment(data)
		setOpenDialogType(dialogType)
	}
	const closeDialogAction = () => {
		setOpenDialogType(undefined)
		setSelectedReplenishment(initDialogData)
	}

	const onValidate = (formValues: FormValues) => {
		const errors: any = {}

		if ((formValues.isDateDemande || formValues.isDateReception)) {
			if (!formValues.startDatetime) {
				errors.startDatetime = <ErrorMessageRequired />
				errors.startDate = <ErrorMessageRequired />
				errors.startTime = <ErrorMessageRequired />
			}
			if (!formValues.endDatetime) {
				errors.endDatetime = <ErrorMessageRequired />
				errors.endDate = <ErrorMessageRequired />
				errors.endTime = <ErrorMessageRequired />
			}
			if (formValues.startDatetime && formValues.endDatetime && moment(formValues.startDatetime).isAfter(moment(formValues.endDatetime))) {
				errors.startDatetime = (
					<FormattedMessage
						id="global.error.validation.startDateAfterEndDate"
						defaultMessage="La date de début ne peut pas être antérieure à celle de fin"
						description="Field error"
					/>
				)
			}
		}

		return errors
	}

	const getReplenishments = (formValues: FormValues) => {
		replenishmentPoint.setFilters({
			idProduit: formValues.idProduit === SELECT_VALUES.ALL ? undefined : formValues.idProduit,
			isDateReception: formValues.dateRole === DATE_ROLE.RECEPTION,
			isDateDemande: formValues.dateRole === DATE_ROLE.CREATION,
			dateRole: formValues.dateRole,
			idZone: formValues.idZone,
			dateDebut: formValues.dateRole === SELECT_VALUES.NONE ? undefined : new Date(formValues.startDatetime || '').toISOString(),
			dateFin: formValues.dateRole === SELECT_VALUES.NONE ? undefined : new Date(formValues.endDatetime || '').toISOString(),
			idCentre: formValues.idCentre,
			statut: formValues.statut
		})

		clearRows()
	}

	const onSubmitDialogAction = (values: any, forceValidation?: boolean) => {
		setSubmitLoading(true)
		return updatePointReplenishmentStatus({
			id: selectedReplenishment.id,
			newStatut: selectedReplenishment.newStatut,
			commentaire: values.comment,
			nbFlacons: values.nbFlacons,
			...(values?.dateReception && {
				dateReception: new Date(values.dateReception).toISOString()
			}),
			forceValidation
		})
			.then(() => {
				if (selectedReplenishment.newStatut === REPLENISHMENT_STATUS.ANNULE) {
					snackSuccess({ id: 'replenishmentList.cancelSuccess', defaultMessage: 'La demande a bien été annulée', description: 'Success message' })

				} else {
					snackSuccess({ id: 'replenishmentList.updateStatusSuccess', defaultMessage: 'Le statut de la demande a bien été modifié', description: 'Success message' })
				}
				closeDialogAction()
				refreshTable()
			})
			.catch((e: any) => {
				snackError({
					id: 'replenishmentList.updateStatusError',
					defaultMessage: 'Une erreur est survenue, le statut de la demande n\'a pas été modifié',
					description: 'Success message'
				})
			})
			.finally(() => setSubmitLoading(false))
	}

	const resetDateFields = (form: FormApi<FormValues>) => {
		form.change('startDatetime', undefined)
		form.change('startDate', undefined)
		form.change('startTime', undefined)
		form.change('endDatetime', undefined)
		form.change('endDate', undefined)
		form.change('endTime', undefined)
	}

	const computeQuantityFromDoses = (row: PointReplenishmentModel) => {
		const dosesSum = Number(row.nbDoses1 || 0) + Number(row.nbDoses2 || 0) + Number(row.nbDosesR || 0)
		return Math.ceil(dosesSum / (row.produit.nbDoses || 1))
	}

	const fetchProccessedVaccins = useCallback((idZone?: string) => {
		if (idZone && isSuperAdmin) {
			getAllProducts(idZone)
				.then((response: VaccinModel[]) => setProccessedVaccins(response.filter((product: ProductModel) => product.type === PRODUCT_TYPES.VACCIN)))
		}
	}, [isSuperAdmin])

	useEffect(() => {
		fetchProccessedVaccins(zones.length > 0 ? zones[0]?.id : undefined)
	}, [fetchProccessedVaccins, zones])

	const getHeaders = () => {
		const headers: Column[] = [
			{
				key: 'statut',
				name: <FormattedMessage
					id="dispatch.replenishmentList.status"
					defaultMessage="Statut"
					description="Header column name"
				/>,
				sortable: true,
				render: (row: PointReplenishmentModel) => {
					let element

					switch (row.statut) {
						case REPLENISHMENT_STATUS.EN_ATTENTE:
							element = <HourglassEmpty />
							break
						case REPLENISHMENT_STATUS.TRAITE:
							element = <Assignment />
							break
						default:
							return (
								<span>
                                    <FormattedMessage
	                                    id={`enum.replenishmentStatus.${row.statut}`}
	                                    defaultMessage="Statut"
	                                    description="Status"
                                    />
                                </span>
							)
					}

					return (
						<Tooltip title={
							<FormattedMessage
								id={`enum.replenishmentStatus.${row.statut}`}
								defaultMessage="Statut"
								description="Status"
							/>
						}>
							{element}
						</Tooltip>
					)
				}
			},
			{
				key: 'dateReception',
				name: <FormattedMessage
					id="dispatch.replenishmentList.wantedDate"
					defaultMessage="Date de réception souhaitée"
					description="Header column name"
				/>,
				sortable: true,
				render: (row: PointReplenishmentModel) => <>
					<span>{displayDate(moment(row.dateReception), DATE_FORMAT.DATE, isDateFormatEn)}</span>

				</>
			}
		]

		headers.push(...[{
				key: 'commentaireCentre',
				name: <FormattedMessage
					id="dispatch.replenishmentList.destinationComment"
					defaultMessage="Commentaire vaccination"
					description="Header column name"
				/>,
				sortable: true,
				render: (row: PointReplenishmentModel) => {
					switch (row.statut) {
						default:
							return <span>{row.commentaireCentre}</span>
					}
				}
			}]
		)

		headers.push(...[{
			key: 'produit.id',
			name: <FormattedMessage
				id="global.functional.product"
				defaultMessage="Produit"
				description="Header column name"
			/>,
			sortable: true,
			render: (row: PointReplenishmentModel) => <span>{row.produit.nomCommercial}</span>
		}, {
			key: 'nbDoses1',
			name: <FormattedMessage
				id="global.functionnal.quantity"
				defaultMessage="Quantité"
				description="Header column name"
			/>,
			sortable: true,
			render: (row: PointReplenishmentModel) => {
				if (row.modeQte === QTY_MODE.CONDITIONNEMENT) {
					return <span>
                        {row.nbCond}
						<FormattedMessage
							id="global.functionnal.conditioningType"
							defaultMessage={row.produit.typeConditionnement}
							description="Conditioning type"
							values={
								{
									count: row.nbCond,
									conditioningType: row.produit.typeConditionnement
								}
							}
						/>
                    </span>
				}

				const conditioningSum = computeQuantityFromDoses(row)
				return (
					<div>
						<Typography className={classes.countLine}>
							<FormattedMessage
								id="dispatch.replenishmentList.quantity1"
								defaultMessage="1ère injection : "
								description="Header column name"
							/>
							<span style={{ fontWeight: 'bold' }}>{`${row.nbDoses1} `}</span>
							<FormattedMessage
								id="dispatch.replenishmentList.conditioningType"
								defaultMessage="Type conditionnement"
								description="Header column name"
								values={{
									count: row.nbDoses1
								}}
							/>
						</Typography>
						<Typography className={classes.countLine}>
							<FormattedMessage
								id="dispatch.replenishmentList.quantity2"
								defaultMessage="2ème injection : "
								description="Header column name"
							/>
							<span style={{ fontWeight: 'bold' }}>{`${row.nbDoses2} `}</span>
							<FormattedMessage
								id="dispatch.replenishmentList.conditioningType"
								defaultMessage="Type conditionnement"
								description="Header column name"
								values={{
									count: row.nbDoses2
								}}
							/>
						</Typography>
						<Typography className={classes.countLine}>
							<FormattedMessage
								id="dispatch.replenishmentList.quantityR"
								defaultMessage="injection rappel : "
								description="Header column name"
							/>
							<span style={{ fontWeight: 'bold' }}>{`${row.nbDosesR} `}</span>
							<FormattedMessage
								id="dispatch.replenishmentList.conditioningType"
								defaultMessage="Type conditionnement"
								description="Header column name"
								values={{
									count: row.nbDosesR
								}}
							/>
						</Typography>
						<FormattedMessage
							id="global.functionnal.totalCountExplicit"
							defaultMessage={`Soit ${conditioningSum} ${row.produit.typeConditionnement}s`}
							description="Count total"
							values={{ count: conditioningSum, type: row.produit.typeConditionnement }}
						>
							{msg => <Typography variant="caption" className={classes.countLine}>{msg}</Typography>}
						</FormattedMessage>
					</div>
				)
			}
		}])

		if (role === USER_ROLE.ROLE_ADMIN) {
			headers.push(
				{
					key: 'centreVacc.id',
					name: <FormattedMessage
						id="dispatch.replenishmentList.expedition"
						defaultMessage="Expéditeur"
						description="Header column name"
					/>,
					sortable: true,
					render: (row: PointReplenishmentModel) => <MailTo center={row.centreVacc} />
				}
			)
			headers.push(
				{
					key: 'pointVacc.id',
					name: <FormattedMessage
						id="global.functionnal.destination"
						defaultMessage="Destination"
						description="Header column name"
					/>,
					sortable: true,
					render: (row: PointReplenishmentModel) => <MailTo center={row.pointVacc} />
				}
			)
		} else if (role === USER_ROLE.ROLE_VACCINATION_CENTER) {
			headers.push(
				{
					key: 'pointVacc.id',
					name: <FormattedMessage
						id="dispatch.replenishmentList.point"
						defaultMessage="Point"
						description="Header column name"
					/>,
					sortable: true,
					render: (row: PointReplenishmentModel) => <MailTo center={row.pointVacc} />
				}
			)
		}

		headers.push(
			{
				key: 'commentairePoint',
				name: <FormattedMessage
					id="dispatch.replenishmentList.pointComment"
					defaultMessage="Commentaire point"
					description="Header column name"
				/>,
				sortable: true
			}
		)

		if (role !== USER_ROLE.ROLE_ADMIN) {
			headers.push({
				key: '',
				name: <span></span>,
				render: (row: PointReplenishmentModel) =>
					row.statut !== REPLENISHMENT_STATUS.TRAITE ? (
						<Button
							size="small"
							className={classes.button}
							onClick={() => {
								const quantitySumFromDoses = computeQuantityFromDoses(row)
								if (role === USER_ROLE.ROLE_VACCINATION_CENTER) {
									openDialogAction(
										{
											...getDataToDialogAction(row, REPLENISHMENT_STATUS.TRAITE, quantitySumFromDoses)
										},
										REPLENISHMENT_DIALOG.PROCESS
									)
								} else {
									openDialogAction(getDataToDialogAction(row, REPLENISHMENT_STATUS.ANNULE, quantitySumFromDoses), REPLENISHMENT_DIALOG.CANCEL)
								}
							}}
							disableRipple
						>
							{role === USER_ROLE.ROLE_VACCINATION_CENTER ? <FormattedMessage
								id="dispatch.replenishmentList.actions.processPoint"
								defaultMessage="Traité"
								description="Action"
							/> : <FormattedMessage
								id="dispatch.replenishment.actions.cancel"
								defaultMessage="Annuler"
								description="Cancel action"
							/>}
						</Button>
					) : (
						<span></span>
					)
			})
		}

		return headers
	}

	const getDataToDialogAction = (row: PointReplenishmentModel, statut: string, quantitySum: number) => ({
		id: row.id,
		newStatut: statut,
		productModeQte: row.modeQte,
		productName: row.produit.nomCommercial,
		productQuantity1: row.nbDoses1,
		productQuantity2: row.nbDoses2,
		productQuantityR: row.nbDosesR,
		productQuantityCond: row.nbCond,
		productTotalQuantity: quantitySum,
		productCond: row.produit.typeConditionnement,
		destinationCenter: row.pointVacc.nom,
		nbDoses: row.produit.nbDoses,
		receptionDate: row.dateReception
	})

	const replnishementFilters = useMemo(() => ({
		...replenishmentPoint.filters,
		idCentre: selectedCenter && selectedCenter.type !== CENTER_TYPE.VACCINATION_POINT ? undefined : replenishmentPoint.filters.idCentre
	}), [replenishmentPoint.filters, selectedCenter])

	const initValues = useMemo(() => ({
		...replnishementFilters,
		idProduit: replnishementFilters.idProduit ? replnishementFilters.idProduit : SELECT_VALUES.ALL,
		startDatetime: replnishementFilters.dateDebut ? formatDateInForm(moment(Date.parse(replnishementFilters.dateDebut)), DATE_FORMAT_IN_FORM.DATETIME_TIMEZONE) : undefined,
		endDatetime: replnishementFilters.dateFin ? formatDateInForm(moment(Date.parse(replnishementFilters.dateFin)), DATE_FORMAT_IN_FORM.DATETIME_TIMEZONE) : undefined
	}), [replnishementFilters])

	return (
		<PageLayout>
			<Paper className={classes.paperForm}>
				<Form
					initialValues={initValues}
					// @ts-ignore
					decorators={formDecorators}
					validate={onValidate}
					onSubmit={getReplenishments}
					render={({ handleSubmit, submitting, values, form }) => (
						<form onSubmit={handleSubmit}>
							<Grid
								container
								direction="row"
								justify="flex-start"
								spacing={2}
							>
								{isSuperAdmin &&
									<Grid item xs={12} md={3}>
										<Field
											name="idZone"
											component={FormSelect}
											label={
												<FormattedMessage
													id="product.Zone"
													defaultMessage="Zone"
													description="Zone"
												/>
											}
										>
											{zones.map((zone) => (
												<MenuItem key={zone.id} value={zone.id}>
													{zone.name}
												</MenuItem>
											))}
										</Field>
									</Grid>}
								<Grid item xs={12} md={5}>
									<Field
										name="idProduit"
										component={FormSelect}
										label={
											<FormattedMessage
												id="global.functional.product"
												defaultMessage="Produit"
												description="Product"
											/>
										}
									>
										<MenuItem key="default" value={SELECT_VALUES.ALL}>
											<FormattedMessage
												id="select.all"
												defaultMessage="Tous"
												description="All"
											/>
										</MenuItem>
										{processedVaccins.map((vaccin: VaccinModel) => (
											<MenuItem
												key={vaccin.id}
												value={vaccin.id}
											>
												{vaccin.nomCommercial}
											</MenuItem>
										))}
									</Field>
								</Grid>
								{
									role === USER_ROLE.ROLE_VACCINATION_CENTER
									&&
									<Grid item xs={12} md={5}>
										<Field
											name="idCentre"
											label={<FormattedMessage
												id="dispatch.replenishmentList.search.vaccinationLocation"
												defaultMessage="Centre"
												description="Destination field"
											/>}
											placeholder={intl.formatMessage({
												id: 'select.all',
												defaultMessage: 'Tous',
												description: 'All'
											})}
											component={FieldAutocomplete}
											options={selectedCenter.centreEnfantsByType.vaccinations.map((centre: CentreModel) => ({
												label: `${centre.nom}${centre.service ? ` - ${centre.service}` : ''}`,
												value: centre.id
											}))}
										/>
									</Grid>
								}

								<Grid container item xs={12} spacing={2}>
									<Grid item xs={12} md={3}>
										<Field
											name="statut"
											component={FormSelect}
											label={
												<FormattedMessage
													id="dispatch.replenishmentList.status"
													defaultMessage="Statut"
													description="Status"
												/>
											}
											multiple
										>
											{Object.values(REPLENISHMENT_STATUS)
												.filter((status: string) => status === REPLENISHMENT_STATUS.EN_ATTENTE || status === REPLENISHMENT_STATUS.TRAITE)
												.map((status: string) => <MenuItem
													key={status}
													value={status}
												>
													{intl.formatMessage({
														id: `enum.replenishmentStatus.${status}`,
														defaultMessage: status,
														description: 'Status option'
													})}
												</MenuItem>)}

										</Field>
									</Grid>
								</Grid>
								<Grid item xs={12}>
									<Grid item xs={12} md={5}>
										<Field
											name="dateRole"
											component={FormSelect}
											label={
												<FormattedMessage
													id="dispatch.replenishmentList.search.dateRole"
													defaultMessage="Type de date"
													description="Type de date"
												/>
											}
										>
											<MenuItem key="default" value={SELECT_VALUES.NONE}>
												<FormattedMessage
													id="select.none"
													defaultMessage="Aucun"
													description="None"
												/>
											</MenuItem>
											<MenuItem
												key={DATE_ROLE.CREATION}
												value={DATE_ROLE.CREATION}
											>
												<FormattedMessage
													id="enum.dateRole.creation"
													defaultMessage="Date de création"
													description="Form select value"
												/>
											</MenuItem>
											<MenuItem
												key={DATE_ROLE.RECEPTION}
												value={DATE_ROLE.RECEPTION}
											>
												<FormattedMessage
													id="enum.dateRole.reception"
													defaultMessage="Date de réception"
													description="Form select value"
												/>
											</MenuItem>
										</Field>
										<OnChange
											name="dateRole"
										>
											{
												value => {
													resetDateFields(form)

													if (value === SELECT_VALUES.NONE) {
														form.change('isDateDemande', false)
														form.change('isDateReception', false)
													} else if (value === DATE_ROLE.CREATION) {
														form.change('isDateDemande', true)
														form.change('isDateReception', false)
													} else if (value === DATE_ROLE.RECEPTION) {
														form.change('isDateDemande', false)
														form.change('isDateReception', true)
													}
												}
											}
										</OnChange>
									</Grid>
								</Grid>

								{values.dateRole !== SELECT_VALUES.NONE
									&&
									<>
										<Grid item xs={12}>
											<Typography>
												{
													values.dateRole === DATE_ROLE.RECEPTION
														?
														<FormattedMessage
															id="dispatch.replenishmentList.search.receptionDate"
															defaultMessage="Date de réception"
															description="Section label for datetime"
														/>
														:
														<FormattedMessage
															id="dispatch.replenishmentList.search.creationDate"
															defaultMessage="Date de creation"
															description="Section label for datetime"
														/>
												}
											</Typography>
										</Grid>
										<FieldDatetime
											datetimeName="startDatetime"
											dateName="startDate"
											timeName="startTime"
											dateLabel={
												<FormattedMessage
													id="dispatch.replenishmentList.search.startDate"
													defaultMessage="Date de début (jj/mm/aaaa)"
													description="input label"
												/>
											}
											timeLabel={
												<FormattedMessage
													id="dispatch.replenishmentList.search.startTime"
													defaultMessage="Heure de début (hh:mm)"
													description="input label"
												/>
											}
										/>
										<FieldDatetime
											datetimeName="endDatetime"
											dateName="endDate"
											timeName="endTime"
											dateLabel={
												<FormattedMessage
													id="dispatch.replenishmentList.search.endDate"
													defaultMessage="Date de fin (jj/mm/aaaa)"
													description="input label"
												/>
											}
											timeLabel={
												<FormattedMessage
													id="dispatch.replenishmentList.search.endTime"
													defaultMessage="Heure de fin (hh:mm)"
													description="input label"
												/>
											}
										/>
									</>
								}

								{/* INVISIBLE FIELDS (USEFUL FOR FORM VALUE MANAGEMENT ONLY) */}
								<Grid item style={{ display: 'none' }}>
									<Field
										name="isDateReception"
										component={FormSwitch}
										type="checkbox"
									/>
								</Grid>
								<Grid item style={{ display: 'none' }}>
									<Field
										name="isDateDemande"
										component={FormSwitch}
										type="checkbox"
									/>
								</Grid>
								{/* !INVISIBLE FIELDS */}

								<Grid item xs={12} md={12}>
									<div className={classes.submitButtonRoot}>
										<Button
											variant="contained"
											startIcon={<Search />}
											type="submit"
											isLoading={submitting}
										>
											<FormattedMessage
												id="button.search"
												defaultMessage="Rechercher"
												description="Search button label"
											/>
										</Button>
									</div>
								</Grid>
							</Grid>
						</form>
					)} />
			</Paper>

			<SpringDataTable
				apiUrl="/point-vaccination/besoin"
				nom="replenishmentList"
				headers={getHeaders()}
				filters={replnishementFilters}
				noResultFragment={<NotFoundPage />}
			/>
			<ExportExcelButton
				url="/point-vaccination/besoin/export"
				fileName={DOWNLOAD_FILE_TYPE.POINT_VACCINATION_BESOIN.outputFile}
				filters={replnishementFilters}
			/>
			<DialogReplenishmentActions
				dialogType={openDialogType}
				loading={submitLoading}
				closeDialogAction={closeDialogAction}
				onSubmit={(values: any) => onSubmitDialogAction(values, false)}
				onSubmitConfirm={() => {
				}}
				onSubmitMultiple={() => {
				}}
				replenishment={selectedReplenishment}
			/>
		</PageLayout>
	)
}

const actions = {
	refreshTable: () => dataTableActions.refresh('replenishmentList'),
	clearRows: () => dataTableActions.selectRows('replenishmentList', [])
}

export default compose<any>(
	connect(null, actions),
	injectSnackbarActions
)(VaccinPointReplenishmentList)
