import React from 'react'
import { FormattedMessage } from 'react-intl'
import SvgUser from '../../components/icons/home/SvgUser'
import SvgCenter from '../../components/icons/home/SvgCenter'
import SvgProduct from '../../components/icons/home/SvgProduct'
import SvgStock from '../../components/icons/home/SvgStock'
import SvgReplenishment from '../../components/icons/home/SvgReplenishment'
import SvgStockEntry from '../../components/icons/home/SvgStockEntry'
import SvgDispatchExit from '../../components/icons/home/SvgDispatchExit'
import SvgScrapping from '../../components/icons/home/SvgScrapping'
import SvgMovement from '../../components/icons/home/SvgMovement'
import SvgUsage from '../../components/icons/home/SvgUsage'
import SvgVaccinationExit from '../../components/icons/home/SvgVaccinationExit'
import SvgDashboard from '../../components/icons/home/SvgDashboard'

interface HOME_MENU {
	tiles: HOME_MENU_TILE[]
}

export interface HOME_MENU_TILE {
	title: string | React.ReactElement
	content: string | React.ReactElement
	linkText: string | React.ReactElement
	linkHref: string
	icon: React.ReactElement
}

export const HOME_ADMIN_DATA: HOME_MENU =
	{
		tiles: [
			{
				title:
					<FormattedMessage
						id="menu.admin.home.tiles.users.title"
						defaultMessage="Utilisateurs"
						description="tile title"
					/>,
				content:
					<FormattedMessage
						id="menu.admin.home.tiles.users.body"
						defaultMessage="Saisissez les utilisateurs, leurs droits, profils, ..."
						description="tile content"
					/>,
				linkText:
					<FormattedMessage
						id="menu.admin.home.tiles.users.link"
						defaultMessage="Gérer les utilisateurs"
						description="tile link"
					/>,
				linkHref: '/users',
				icon: <SvgUser />
			},
			{
				title:
					<FormattedMessage
						id="menu.admin.home.tiles.centers.title"
						defaultMessage="Centres"
						description="tile title"
					/>,
				content:
					<FormattedMessage
						id="menu.admin.home.tiles.centers.body"
						defaultMessage="Gérez les centres de dispatch, centre de vaccinations, ..."
						description="tile content"
					/>,
				linkText:
					<FormattedMessage
						id="menu.admin.home.tiles.centers.link"
						defaultMessage="Gérer les centres"
						description="tile link"
					/>,
				linkHref: '/centers',
				icon: <SvgCenter />
			},
			{
				title:
					<FormattedMessage
						id="menu.admin.home.tiles.products.title"
						defaultMessage="Produits"
						description="tile title"
					/>,
				content:
					<FormattedMessage
						id="menu.admin.home.tiles.products.body"
						defaultMessage="Gérez les fiches produits disponibles dans l’application"
						description="tile content"
					/>,
				linkText:
					<FormattedMessage
						id="menu.admin.home.tiles.products.link"
						defaultMessage="Gérer les produits"
						description="tile link"
					/>,
				linkHref: '/products',
				icon: <SvgProduct />
			},
			{
				title:
					<FormattedMessage
						id="menu.admin.home.tiles.stocks.title"
						defaultMessage="Stocks"
						description="tile title"
					/>,
				content:
					<FormattedMessage
						id="menu.admin.home.tiles.stocks.body"
						defaultMessage="Consultez les stocks dans les centres, effectuer des corrections"
						description="tile content"
					/>,
				linkText:
					<FormattedMessage
						id="menu.admin.home.tiles.stocks.link"
						defaultMessage="Voir les stocks"
						description="tile link"
					/>,
				linkHref: '/stocks',
				icon: <SvgStock />
			},
			{
				title:
					<FormattedMessage
						id="menu.admin.home.tiles.replenishments.title"
						defaultMessage="Demandes"
						description="tile title"
					/>,
				content:
					<FormattedMessage
						id="menu.admin.home.tiles.replenishments.body"
						defaultMessage="Consultez les demandes de réapprovisionnement des centres"
						description="tile content"
					/>,
				linkText:
					<FormattedMessage
						id="menu.admin.home.tiles.replenishments.link"
						defaultMessage="Voir les demandes"
						description="tile link"
					/>,
				linkHref: '/replenishments',
				icon: <SvgReplenishment />
			}
		]
	}

export const HOME_ADMIN_ZONE_DATA: HOME_MENU =
	{
		tiles: [
			{
				title:
					<FormattedMessage
						id="menu.admin.home.tiles.users.title"
						defaultMessage="Utilisateurs"
						description="tile title"
					/>,
				content:
					<FormattedMessage
						id="menu.admin.home.tiles.users.body"
						defaultMessage="Saisissez les utilisateurs, leurs droits, profils, ..."
						description="tile content"
					/>,
				linkText:
					<FormattedMessage
						id="menu.admin.home.tiles.users.link"
						defaultMessage="Gérer les utilisateurs"
						description="tile link"
					/>,
				linkHref: '/users',
				icon: <SvgUser />
			},
			{
				title:
					<FormattedMessage
						id="menu.admin.home.tiles.centers.title"
						defaultMessage="Centres"
						description="tile title"
					/>,
				content:
					<FormattedMessage
						id="menu.admin.home.tiles.centers.body"
						defaultMessage="Gérez les différents types de centres."
						description="tile content"
					/>,
				linkText:
					<FormattedMessage
						id="menu.admin.home.tiles.centers.link"
						defaultMessage="Gérer les centres"
						description="tile link"
					/>,
				linkHref: '/centers',
				icon: <SvgCenter />
			},
			{
				title:
					<FormattedMessage
						id="menu.admin.home.tiles.products.title"
						defaultMessage="Produits"
						description="tile title"
					/>,
				content:
					<FormattedMessage
						id="menu.admin.home.tiles.products.body"
						defaultMessage="Gérez les fiches produits disponibles dans l’application"
						description="tile content"
					/>,
				linkText:
					<FormattedMessage
						id="menu.admin.home.tiles.products.link"
						defaultMessage="Gérer les produits"
						description="tile link"
					/>,
				linkHref: '/products',
				icon: <SvgProduct />
			},
			{
				title:
					<FormattedMessage
						id="menu.admin.home.tiles.stocks.title"
						defaultMessage="Stocks"
						description="tile title"
					/>,
				content:
					<FormattedMessage
						id="menu.admin.home.tiles.stocks.body"
						defaultMessage="Consultez les stocks dans les centres, effectuer des corrections"
						description="tile content"
					/>,
				linkText:
					<FormattedMessage
						id="menu.admin.home.tiles.stocks.link"
						defaultMessage="Voir les stocks"
						description="tile link"
					/>,
				linkHref: '/stocks',
				icon: <SvgStock />
			},
			{
				title:
					<FormattedMessage
						id="menu.admin.home.tiles.replenishments.title"
						defaultMessage="Demandes"
						description="tile title"
					/>,
				content:
					<FormattedMessage
						id="menu.admin.home.tiles.replenishments.body"
						defaultMessage="Consultez les demandes de réapprovisionnement des centres"
						description="tile content"
					/>,
				linkText:
					<FormattedMessage
						id="menu.admin.home.tiles.replenishments.link"
						defaultMessage="Voir les demandes"
						description="tile link"
					/>,
				linkHref: '/replenishments',
				icon: <SvgReplenishment />
			}
		]
	}

export const HOME_REFERENT_WITH_STOCK_DATA: HOME_MENU =
	{
		tiles: [
			{
				title:
					<FormattedMessage
						id="menu.referentCenter.home.tiles.withStock.enterStock.title"
						defaultMessage="Entrée en stock"
						description="tile title"
					/>,
				content:
					<FormattedMessage
						id="menu.referentCenter.home.tiles.withStock.enterStock.body"
						defaultMessage="Saisissez les informations sur les produits que vous réceptionnez"
						description="tile content"
					/>,
				linkText:
					<FormattedMessage
						id="menu.referentCenter.home.tiles.withStock.enterStock.link"
						defaultMessage="Faire une entrée en stock"
						description="tile link"
					/>,
				linkHref: '/referent/stock-entries',
				icon: <SvgStockEntry />
			},
			{
				title:
					<FormattedMessage
						id="menu.referentCenter.home.tiles.withStock.exitStock.title"
						defaultMessage="Préparation / Expédition"
						description="tile title"
					/>,
				content:
					<FormattedMessage
						id="menu.referentCenter.home.tiles.withStock.exitStock.body"
						defaultMessage="Gérez vos livraisons vers les centres de vaccinations"
						description="tile content"
					/>,
				linkText:
					<FormattedMessage
						id="menu.referentCenter.home.tiles.withStock.exitStock.link"
						defaultMessage="Faire une sortie de stock"
						description="tile link"
					/>,
				linkHref: '/referent/exits',
				icon: <SvgDispatchExit />
			},
			{
				title:
					<FormattedMessage
						id="menu.referentCenter.home.tiles.withStock.scrapping.title"
						defaultMessage="Mise en déchet"
						description="tile title"
					/>,
				content:
					<FormattedMessage
						id="menu.referentCenter.home.tiles.withStock.scrapping.body"
						defaultMessage="Déclarez les sorties de stock pour flacons cassés, inutilisables, périmés, ..."
						description="tile content"
					/>,
				linkText:
					<FormattedMessage
						id="menu.referentCenter.home.tiles.withStock.scrapping.link"
						defaultMessage="Effectuer une mise en déchet"
						description="tile link"
					/>,
				linkHref: '/referent/scrappings',
				icon: <SvgScrapping />
			},
			{
				title:
					<FormattedMessage
						id="menu.referentCenter.home.tiles.withStock.replenishment.title"
						defaultMessage="Liste des demandes"
						description="tile title"
					/>,
				content:
					<FormattedMessage
						id="menu.referentCenter.home.tiles.withStock.replenishment.body"
						defaultMessage="Consulter et traiter les demandes de réapprovisionnement provenant des centres"
						description="tile content"
					/>,
				linkText:
					<FormattedMessage
						id="menu.referentCenter.home.tiles.withStock.replenishment.link"
						defaultMessage="Voir les demandes"
						description="tile link"
					/>,
				linkHref: '/referent/replenishments',
				icon: <SvgReplenishment />
			},
			{
				title:
					<FormattedMessage
						id="menu.referentCenter.home.tiles.withStock.dashboard.title"
						defaultMessage="Tableau de bord"
						description="tile title"
					/>,
				content:
					<FormattedMessage
						id="menu.referentCenter.home.tiles.withStock.dashboard.body"
						defaultMessage="Consultez en temps réel la synthèse des stocks, des mouvements, ..."
						description="tile content"
					/>,
				linkText:
					<FormattedMessage
						id="menu.referentCenter.home.tiles.withStock.dashboard.link"
						defaultMessage="Voir le tableau de bord"
						description="tile link"
					/>,
				linkHref: '/referent/dashboard',
				icon: <SvgDashboard />
			},
			{
				title:
					<FormattedMessage
						id="menu.referentCenter.home.tiles.withStock.movementHistory.title"
						defaultMessage="Historique des mouvements"
						description="tile title"
					/>,
				content:
					<FormattedMessage
						id="menu.referentCenter.home.tiles.withStock.movementHistory.body"
						defaultMessage="Consultez les mouvements de stock par produit"
						description="tile content"
					/>,
				linkText:
					<FormattedMessage
						id="menu.referentCenter.home.tiles.withStock.movementHistory.link"
						defaultMessage="Voir les mouvements de stock"
						description="tile link"
					/>,
				linkHref: '/referent/movements',
				icon: <SvgMovement />
			}
		]
	}

export const HOME_REFERENT_WITHOUT_STOCK_DATA: HOME_MENU = {
	tiles: [
		{
			title:
				<FormattedMessage
					id="menu.referentCenter.home.tiles.withoutStock.affiliatedCenters.title"
					defaultMessage="Mes centres associés"
					description="tile title"
				/>,
			content:
				<FormattedMessage
					id="menu.referentCenter.home.tiles.withoutStock.affiliatedCenters.body"
					defaultMessage="Consultez les stocks de mes centres associés."
					description="tile content"
				/>,
			linkText:
				<FormattedMessage
					id="menu.referentCenter.home.tiles.withoutStock.affiliatedCenters.link"
					defaultMessage="Voir les stocks"
					description="tile link"
				/>,
			linkHref: '/referent/affiliated-stocks',
			icon: <SvgCenter />
		},
		{
			title:
				<FormattedMessage
					id="menu.referentCenter.home.tiles.withoutStock.procurement.title"
					defaultMessage="Allocation"
					description="tile title"
				/>,
			content:
				<FormattedMessage
					id="menu.referentCenter.home.tiles.withoutStock.procurement.body"
					defaultMessage="Consultez les allocations de mes centres associés."
					description="tile content"
				/>,
			linkText:
				<FormattedMessage
					id="menu.referentCenter.home.tiles.withoutStock.procurement.link"
					defaultMessage="Voir les allocations"
					description="tile link"
				/>,
			linkHref: '/procurements',
			icon: <SvgStock />
		},
		{
			title:
				<FormattedMessage
					id="menu.referentCenter.home.tiles.withoutStock.useDeclaration.title"
					defaultMessage="Suivi des administrations"
					description="tile title"
				/>,
			content:
				<FormattedMessage
					id="menu.referentCenter.home.tiles.withoutStock.useDeclaration.body"
					defaultMessage="Consultez les administrations de mes centres associés."
					description="tile content"
				/>,
			linkText:
				<FormattedMessage
					id="menu.referentCenter.home.tiles.withoutStock.useDeclaration.link"
					defaultMessage="Voir les administrations"
					description="tile link"
				/>,
			linkHref: '/usage-control',
			icon: <SvgUsage />
		}
	]
}

export const HOME_DISPATCH_DATA: HOME_MENU =
	{
		tiles: [
			{
				title:
					<FormattedMessage
						id="menu.dispatchCenter.home.tiles.enterStock.title"
						defaultMessage="Entrée en stock"
						description="tile title"
					/>,
				content:
					<FormattedMessage
						id="menu.dispatchCenter.home.tiles.enterStock.body"
						defaultMessage="Saisissez les informations sur les produits que vous réceptionnez"
						description="tile content"
					/>,
				linkText:
					<FormattedMessage
						id="menu.dispatchCenter.home.tiles.enterStock.link"
						defaultMessage="Faire une entrée en stock"
						description="tile link"
					/>,
				linkHref: '/dispatch/stock-entries',
				icon: <SvgStockEntry />
			},
			{
				title:
					<FormattedMessage
						id="menu.dispatchCenter.home.tiles.exitStock.title"
						defaultMessage="Préparation / Expédition"
						description="tile title"
					/>,
				content:
					<FormattedMessage
						id="menu.dispatchCenter.home.tiles.exitStock.body"
						defaultMessage="Gérez vos livraisons vers les centres de vaccinations"
						description="tile content"
					/>,
				linkText:
					<FormattedMessage
						id="menu.dispatchCenter.home.tiles.exitStock.link"
						defaultMessage="Faire une sortie de stock"
						description="tile link"
					/>,
				linkHref: '/dispatch/exits',
				icon: <SvgDispatchExit />
			},
			{
				title:
					<FormattedMessage
						id="menu.dispatchCenter.home.tiles.scrapping.title"
						defaultMessage="Mise en déchet"
						description="tile title"
					/>,
				content:
					<FormattedMessage
						id="menu.dispatchCenter.home.tiles.scrapping.body"
						defaultMessage="Déclarez les sorties de stock pour flacons cassés, inutilisables, périmés, ..."
						description="tile content"
					/>,
				linkText:
					<FormattedMessage
						id="menu.dispatchCenter.home.tiles.scrapping.link"
						defaultMessage="Effectuer une mise en déchet"
						description="tile link"
					/>,
				linkHref: '/dispatch/scrappings',
				icon: <SvgScrapping />
			},
			{
				title:
					<FormattedMessage
						id="menu.dispatchCenter.home.tiles.replenishment.title"
						defaultMessage="Liste des demandes"
						description="tile title"
					/>,
				content:
					<FormattedMessage
						id="menu.dispatchCenter.home.tiles.replenishment.body"
						defaultMessage="Consulter et traiter les demandes de réapprovisionnement provenant des centres"
						description="tile content"
					/>,
				linkText:
					<FormattedMessage
						id="menu.dispatchCenter.home.tiles.replenishment.link"
						defaultMessage="Voir les demandes"
						description="tile link"
					/>,
				linkHref: '/dispatch/replenishments',
				icon: <SvgReplenishment />
			},
			{
				title:
					<FormattedMessage
						id="menu.dispatchCenter.home.tiles.dashboard.title"
						defaultMessage="Tableau de bord"
						description="tile title"
					/>,
				content:
					<FormattedMessage
						id="menu.dispatchCenter.home.tiles.dashboard.body"
						defaultMessage="Consultez en temps réel la synthèse des stocks, des mouvements, ..."
						description="tile content"
					/>,
				linkText:
					<FormattedMessage
						id="menu.dispatchCenter.home.tiles.dashboard.link"
						defaultMessage="Voir le tableau de bord"
						description="tile link"
					/>,
				linkHref: '/dispatch/dashboard',
				icon: <SvgDashboard />
			},
			{
				title:
					<FormattedMessage
						id="menu.dispatchCenter.home.tiles.movementHistory.title"
						defaultMessage="Historique des mouvements"
						description="tile title"
					/>,
				content:
					<FormattedMessage
						id="menu.dispatchCenter.home.tiles.movementHistory.body"
						defaultMessage="Consultez les mouvements de stock par produit"
						description="tile content"
					/>,
				linkText:
					<FormattedMessage
						id="menu.dispatchCenter.home.tiles.movementHistory.link"
						defaultMessage="Voir les mouvements de stock"
						description="tile link"
					/>,
				linkHref: '/dispatch/movements',
				icon: <SvgMovement />
			}
		]
	}

export const HOME_VACCINATION_DATA: HOME_MENU =
	{
		tiles: [
			{
				title:
					<FormattedMessage
						id="menu.vaccinationCenter.home.tiles.replenishment.title"
						defaultMessage="Liste des demandes"
						description="tile title"
					/>,
				content:
					<FormattedMessage
						id="menu.vaccinationCenter.home.tiles.replenishment.body"
						defaultMessage="Envoyez vos demande de réapprovisionnement vers votre centre"
						description="tile content"
					/>,
				linkText:
					<FormattedMessage
						id="menu.vaccinationCenter.home.tiles.replenishment.link"
						defaultMessage="Faire une demande"
						description="tile link"
					/>,
				linkHref: '/vaccination/replenishment-form',
				icon: <SvgReplenishment />
			},
			{
				title:
					<FormattedMessage
						id="menu.vaccinationCenter.home.tiles.enterStock.title"
						defaultMessage="Entrée en stock"
						description="tile title"
					/>,
				content:
					<FormattedMessage
						id="menu.vaccinationCenter.home.tiles.enterStock.body"
						defaultMessage="Saisissez les informations sur les produits que vous réceptionnez"
						description="tile content"
					/>,
				linkText:
					<FormattedMessage
						id="menu.vaccinationCenter.home.tiles.enterStock.link"
						defaultMessage="Faire une entrée en stock"
						description="tile link"
					/>,
				linkHref: '/vaccination/stock-entries',
				icon: <SvgStockEntry />
			},
			{
				title:
					<FormattedMessage
						id="menu.vaccinationCenter.home.tiles.exitFridge.title"
						defaultMessage="Sortie de frigo"
						description="tile title"
					/>,
				content:
					<FormattedMessage
						id="menu.vaccinationCenter.home.tiles.exitFridge.body"
						defaultMessage="Gérez l’utilisation des doses sortie de la chaine du froid"
						description="tile content"
					/>,
				linkText:
					<FormattedMessage
						id="menu.vaccinationCenter.home.tiles.exitFridge.link"
						defaultMessage="Faire une sortie de frigo"
						description="tile link"
					/>,
				linkHref: '/vaccination/exits',
				icon: <SvgVaccinationExit />
			},
			{
				title:
					<FormattedMessage
						id="menu.vaccinationCenter.home.tiles.declarationOfUse.title"
						defaultMessage="Déclaration d'utilisation"
						description="tile title"
					/>,
				content:
					<FormattedMessage
						id="menu.vaccinationCenter.home.tiles.declarationOfUse.body"
						defaultMessage="Déclarez le nombre d’injections réalisée à des fins statistiques"
						description="tile content"
					/>,
				linkText:
					<FormattedMessage
						id="menu.vaccinationCenter.home.tiles.declarationOfUse.link"
						defaultMessage="Faire une déclaration"
						description="tile link"
					/>,
				linkHref: '/vaccination/declarations-of-use',
				icon: <SvgUsage />
			},
			{
				title:
					<FormattedMessage
						id="menu.vaccinationCenter.home.tiles.dashboard.title"
						defaultMessage="Tableau de bord"
						description="tile title"
					/>,
				content:
					<FormattedMessage
						id="menu.vaccinationCenter.home.tiles.dashboard.body"
						defaultMessage="Consultez en temps réel la synthèse des stocks, des mouvements…"
						description="tile content"
					/>,
				linkText:
					<FormattedMessage
						id="menu.vaccinationCenter.home.tiles.dashboard.link"
						defaultMessage="Voir le tableau de bord"
						description="tile link"
					/>,
				linkHref: '/vaccination/dashboard',
				icon: <SvgDashboard />
			},
			{
				title:
					<FormattedMessage
						id="menu.vaccinationCenter.home.tiles.movementHistory.title"
						defaultMessage="Historique des mouvements"
						description="tile title"
					/>,
				content:
					<FormattedMessage
						id="menu.vaccinationCenter.home.tiles.movementHistory.body"
						defaultMessage="Consultez les mouvements de stock par produit"
						description="tile content"
					/>,
				linkText:
					<FormattedMessage
						id="menu.vaccinationCenter.home.tiles.movementHistory.link"
						defaultMessage="Voir les mouvements de stock"
						description="tile link"
					/>,
				linkHref: '/vaccination/movements',
				icon: <SvgMovement />
			}
		]
	}

export const HOME_VACCINATION_POINT_DATA: HOME_MENU = {
	tiles: [
		{
			title:
				<FormattedMessage
					id="menu.vaccinationPoint.home.tiles.replenishment.title"
					defaultMessage="Faire une demandes"
					description="tile title"
				/>,
			content:
				<FormattedMessage
					id="menu.vaccinationPoint.home.tiles.replenishment.body"
					defaultMessage="Envoyez vos demande de réapprovisionnement vers votre centre de vaccination"
					description="tile content"
				/>,
			linkText:
				<FormattedMessage
					id="menu.vaccinationPoint.home.tiles.replenishment.link"
					defaultMessage="Faire une demande"
					description="tile link"
				/>,
			linkHref: '/vaccination-point/replenishment-form',
			icon: <SvgDispatchExit />
		},
		{
			title:
				<FormattedMessage
					id="menu.vaccinationCenter.home.tiles.declarationOfUse.title"
					defaultMessage="Déclaration d'utilisation"
					description="tile title"
				/>,
			content:
				<FormattedMessage
					id="menu.vaccinationCenter.home.tiles.declarationOfUse.body"
					defaultMessage="Déclarez le nombre d’injections réalisée à des fins statistiques"
					description="tile content"
				/>,
			linkText:
				<FormattedMessage
					id="menu.vaccinationCenter.home.tiles.declarationOfUse.link"
					defaultMessage="Faire une déclaration"
					description="tile link"
				/>,
			linkHref: '/vaccination/declarations-of-use',
			icon: <SvgUsage />
		},
		{
			title:
				<FormattedMessage
					id="menu.vaccinationPoint.home.tiles.replenishmentList.title"
					defaultMessage="Historique des demandes"
					description="tile title"
				/>,
			content:
				<FormattedMessage
					id="menu.vaccinationPoint.home.tiles.replenishmentList.body"
					defaultMessage="Consulter vos demandes de réapprovisionnement"
					description="tile content"
				/>,
			linkText:
				<FormattedMessage
					id="menu.vaccinationPoint.home.tiles.replenishmentList.link"
					defaultMessage="Voir mes demandes"
					description="tile link"
				/>,
			linkHref: '/vaccination-point/replenishments',
			icon: <SvgReplenishment />
		}
	]
}
