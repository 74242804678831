import React, { useContext } from 'react'
import { compose } from 'redux'
import { injectActions as injectSnackbarActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import { useHistory } from 'react-router-dom'
import { injectToolbarData } from 'isotope-client'
import { FormattedMessage } from 'react-intl'
import { RequestCreateOrUpdateZoneModel, ZoneModel } from '../services/zoneDataModel'
import errorsMapper from '../../../../utils/errorsMapper'
import { postZoneDetails } from '../services/zoneApi'
import ZoneDetailsForm from './ZoneDetailsForm'
import { REFERENTIEL } from '../../../common/phidemData/phidemDataModel'
import { PhidemDataContext } from '../../../common/phidemData/PhidemDataContext'
import { languageChoices } from '../services/zoneConsts'

interface ZoneDetailsCreateProps {
	snackSuccess: (value: any) => void,
	snackError: (value: any) => void,
}

const ZoneDetailsCreate: React.FC<ZoneDetailsCreateProps> = (
	{
		snackSuccess,
		snackError
	}) => {
	const history = useHistory()
	const { reloadReferentiel } = useContext(PhidemDataContext)

	const onSubmit = async (formValuesModel: RequestCreateOrUpdateZoneModel) => {
		return postZoneDetails(formValuesModel)
			.then((response: ZoneModel) => {
				history.push(`/zones/${response.id}`)
				snackSuccess({ id: 'zone.createSuccess', defaultMessage: 'La zone a bien été créée', description: 'Success message' })
				reloadReferentiel(REFERENTIEL.ZONE)
			})
			.catch((e: any) => {
				const { errors, displayFields } = errorsMapper(e)
				if (displayFields) {
					snackError({ id: 'global.error.formError', defaultMessage: 'Votre formulaire comporte des erreurs', description: 'Form error message' })
				} else {
					snackError({ id: 'zone.createError', defaultMessage: 'Une erreur est survenue, le centre n\'a pas été créé', description: 'Error message' })
				}
				return errors
			})
	}

	return (
		<ZoneDetailsForm
			initialValues={{
				name: '',
				timezone: 'Europe/Paris',
				defaultLanguageId: '1',
				languagesId: languageChoices,
				configZoneLanguages: [
					{
						idLanguage: '1',
						centreReferentName: 'Centre Référent',
						centreDispatchName: 'Centre de dispatch',
						centreVaccinationName: 'Centre de vaccination',
						centreVaccinationPointName: 'Point de vaccination',
					},
					{
						idLanguage: '2',
						centreReferentName: 'Referral Centre',
						centreDispatchName: 'Dispatch Centre',
						centreVaccinationName: 'Vaccination Centre',
						centreVaccinationPointName: 'Vaccination Point',
					},
				],
				besoinCouverture: false,
				besoinDoses: false,
				preparationTransfert: false,
				active: true
			}}
			onSubmit={onSubmit}
			isCreationForm
		/>
	)
}

export default compose<any>(injectToolbarData(() => ({
	title: <FormattedMessage
		id="zone.pageTitle.create"
		defaultMessage="Nouvelle zone"
		description="Page title"
	/>
})), injectSnackbarActions)(ZoneDetailsCreate)
