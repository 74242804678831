import { LOCAL_STORAGE_ITEM } from '../../../utils/constants'

export const SWITCH_CENTER = 'SWITCH_CENTER'

export const INIT_ZONE = 'INIT_ZONE'

interface SessionCenter {
    id?: string,
    type?: string
}

interface SessionZone {
    id?: string
}

export const getStorageCenter = () => localStorage.getItem(LOCAL_STORAGE_ITEM.CENTER)
export const setStorageCenter = (centerId?: string) => localStorage.setItem(LOCAL_STORAGE_ITEM.CENTER, centerId || '')
export const deleteStorageCenter = () => localStorage.removeItem(LOCAL_STORAGE_ITEM.CENTER)

export const getStorageZone = () => localStorage.getItem(LOCAL_STORAGE_ITEM.ZONE)
export const setStorageZone = (zoneId?: string) => localStorage.setItem(LOCAL_STORAGE_ITEM.ZONE, zoneId || '')
export const deleteStorageZone = () => localStorage.removeItem(LOCAL_STORAGE_ITEM.ZONE)

export const switchCenter = (center?: SessionCenter) => (dispatch: any) => {
    setStorageCenter(center ? center.id : '')
    dispatch({
        type: SWITCH_CENTER,
        payload: center
    })
}

export const switchZone = (zone?: SessionZone) => (dispatch: any) => {
    setStorageZone(zone ? zone.id : '')
    dispatch({
        type: INIT_ZONE,
        payload: zone
    })
}
