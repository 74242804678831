import React, { useContext, useEffect, useState } from 'react'
import { dataTableActions, FormSelect, SpringDataTable } from 'isotope-client'
import { FormattedMessage } from 'react-intl'
import NotFoundPage from '../../../components/layout/NotFoundPage'
import { CarrierModel, RequestCarrierFiltersModel } from './services/carrierModel'
import { useHistory } from 'react-router-dom'
import RedirectFAB from '../RedirectFAB'
import { updateCarrierStatus } from './services/carrierApi'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { injectActions as injectSnackbarActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import { PhidemDataContext } from '../../common/phidemData/PhidemDataContext'
import { REFERENTIEL } from '../../common/phidemData/phidemDataModel'
import { makeStyles } from '@material-ui/core'
import { createStyles, Theme } from '@material-ui/core/styles'
import { RequestContainerFiltersModel } from '../container/services/containerModel'
import Paper from '@material-ui/core/Paper'
import { Field, Form } from 'react-final-form'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '../../../components/layout/buttons/Button'
import { Search } from '@material-ui/icons'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		paperForm: {
			width: '70%',
			minWidth: 300,
			maxWidth: 880,
			padding: theme.spacing(2),
			marginBottom: 50
		},
		submitButtonRoot: {
			width: '100%',
			margin: theme.spacing(1),
			display: 'flex',
			justifyContent: 'flex-end'
		}
	})
)

interface CarrierListProps {
	refreshTable: () => void,
	snackSuccess: (value: any) => void,
	snackError: (value: any) => void,
}

const CarrierList: React.FC<CarrierListProps> = (
	{
		refreshTable,
		snackSuccess,
		snackError
	}
) => {
	const classes = useStyles()
	const history = useHistory()
	const { reloadReferentiel, user: { isSuperAdmin }, zones } = useContext(PhidemDataContext)
	const [filters, setFilters] = useState<RequestContainerFiltersModel>({
		zoneId: zones.length > 0 ? zones[0]?.id : undefined
	})

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			setFilters({
				...filters,
				zoneId: zones.length > 0 ? zones[0]?.id : undefined
			})
		}, 500)

		return () => clearTimeout(timeoutId)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [zones])

	const handleCarrierStatus = (id: string, status: boolean) => {
		updateCarrierStatus(id, status)
			.then(() => {
				refreshTable()
				snackSuccess({
					id: 'carrier.updateStatusSuccess',
					defaultMessage: 'Le statut du transporteur a bien été modifié',
					description: 'Success message'
				})
				reloadReferentiel(REFERENTIEL.CARRIER)
				reloadReferentiel(REFERENTIEL.CENTER)
			})
			.catch(() => snackError({
				id: 'carrier.updateStatusError',
				defaultMessage: 'Une erreur est survenue, le statut du transporteur n\'a pas été modifiée',
				description: 'Error message'
			}))
	}

	const getCarriers = (formValues: RequestCarrierFiltersModel) => {
		setFilters({ zoneId: formValues?.zoneId })
	}

	const getHeaders = () => {
		const headers = [
			{
				key: 'nom',
				name: <FormattedMessage
					id="carrier.name"
					defaultMessage="Nom"
					description="Header column name"
				/>,
				sortable: true
			},
			{
				key: 'siret',
				name: <FormattedMessage
					id="carrier.siret"
					defaultMessage="SIRET"
					description="Header column siret"
				/>,
				sortable: true
			},
			{
				key: 'adresse',
				name: <FormattedMessage
					id="carrier.address"
					defaultMessage="Adresse"
					description="Header column address"
				/>,
				sortable: true,
				render: (row: CarrierModel) => <span>{row.adresse} {row.codePostal} {row.ville} {row.pays}</span>
			},
			{
				key: 'telephone',
				name: <FormattedMessage
					id="carrier.phone"
					defaultMessage="Téléphone"
					description="Header column phone"
				/>,
				sortable: true
			},
			{
				key: 'mailContact',
				name: <FormattedMessage
					id="carrier.contact"
					defaultMessage="Contact"
					description="Header column contact"
				/>,
				sortable: true
			},
			{
				key: 'actif',
				name: <FormattedMessage
					id="global.status.label"
					defaultMessage="Statut"
					description="Header column status"
				/>,
				sortable: true,
				render: (row: CarrierModel) => row.actif ? <FormattedMessage
					id="global.status.enabled"
					defaultMessage="Actif"
					description="Center status"
				/> : <FormattedMessage
					id="global.status.disabled"
					defaultMessage="Inactif"
					description="Center status"
				/>

			}
		]

		if (isSuperAdmin) {
			headers.unshift({
				key: 'centreDispatch.nom',
				name: <FormattedMessage
					id="carrier.centerName"
					defaultMessage="Nom du centre"
					description="Header column name"
				/>,
				sortable: true,
				render: (row: CarrierModel) => <span>{`${row.centreDispatch.nom}${row.centreDispatch.service ? ` - ${row.centreDispatch.service}` : ''}`}</span>
			})
		}

		return headers
	}

	return (
		<>
			{isSuperAdmin &&
				<Paper className={classes.paperForm}>
					<Form
						initialValues={{ zoneId: zones.length > 0 ? zones[0]?.id : undefined }}
						onSubmit={getCarriers}
						// @ts-ignore
						render={({ handleSubmit, submitting }) => (
							<form onSubmit={handleSubmit}>
								<Grid
									container
									direction="row"
									justify="flex-start"
									spacing={2}
								>
									<Grid item xs={12} md={3}>
										<Field
											name="zoneId"
											component={FormSelect}
											label={
												<FormattedMessage
													id="product.Zone"
													defaultMessage="Zone"
													description="Zone"
												/>
											}
										>
											{zones.map((zone) => (
												<MenuItem key={zone.id} value={zone.id}>
													{zone.name}
												</MenuItem>
											))}
										</Field>
									</Grid>
									<Grid item xs={12} md={12}>
										<div className={classes.submitButtonRoot}>
											<Button
												variant="contained"
												startIcon={<Search />}
												type="submit"
												isLoading={submitting}
											>
												<FormattedMessage
													id="button.search"
													defaultMessage="Rechercher"
													description="Search button label"
												/>
											</Button>
										</div>
									</Grid>
								</Grid>
							</form>
						)} />
				</Paper>
			}
			<RedirectFAB redirectPath="/carriers/add">
				<SpringDataTable
					apiUrl="/referentiel/transporteur"
					nom="carrierList"
					headers={getHeaders()}
					filters={filters}
					makeActions={(row: CarrierModel) => [
						{
							label: <FormattedMessage
								id="global.update"
								defaultMessage="Modifier"
								description="Update action"
							/>, action: () => {
								history.push(`/carriers/${row.id}`)
							}
						},
						{
							label: row.actif ? <FormattedMessage
								id="global.disable"
								defaultMessage="Désactiver"
								description="Disable action"
							/> : <FormattedMessage
								id="global.enable"
								defaultMessage="Activer"
								description="Enable action"
							/>, action: () => handleCarrierStatus(row.id, !row.actif)
						}
					]}
					showActions
					noResultFragment={<NotFoundPage />}
				/>
			</RedirectFAB>
		</>
	)
}

const actions = {
	refreshTable: () => dataTableActions.refresh('carrierList')
}

export default compose<any>(
	connect(null, actions),
	injectSnackbarActions
)(CarrierList)
