export interface Language {
	id: string
	name: string
	codeLang: string
	defaultLanguageFo: boolean
	defaultLanguageBo: boolean
	activeFlagFo: boolean
	activeFlagBo: boolean
	idCreator: string
	creationDate: string
	idModifier: string
	modificationDate: string
}

export interface ZoneModel {
	id: string
	name: string
	timezone: string
	active: boolean
	config: ZoneConfigModel
	defaultLanguage: Language
	languages: Language[]
	configZoneLanguages: ZoneLanguageConfigModel[]
}

export interface ZoneConfigModel {
	besoinCouverture: boolean
	besoinDoses: boolean
	preparationTransfert: boolean
}

export interface ZoneLanguageConfigModel {
	idLanguage: string,
	centreReferentName: string
	centreDispatchName: string
	centreVaccinationName: string
	centreVaccinationPointName: string
}

export interface RequestZoneFiltersModel {
	statut: string
	name?: string
}

export const ZONE_STATUS = {
	ACTIVE: 'ACTIVE',
	INACTIVE: 'INACTIVE',
	INDIFFERENT: 'INDIFFERENT'
}

export interface RequestCreateOrUpdateZoneModel {
	id?: string
	name: string
	timezone: string
	defaultLanguageId: string
	languagesId?: { id: string, libelle: string }[]
	configZoneLanguages: RequestCreateOrUpdateZoneLanguageConfigModel[]
	besoinCouverture: boolean
	besoinDoses: boolean
	preparationTransfert: boolean
	active: boolean
}

export interface RequestCreateOrUpdateZoneLanguageConfigModel {
	idLanguage: string,
	centreReferentName: string
	centreDispatchName: string
	centreVaccinationName: string
	centreVaccinationPointName: string
}

export const defaultRequestZone: RequestCreateOrUpdateZoneModel = {
	id: undefined,
	name: '',
	timezone: '',
	defaultLanguageId: '',
	languagesId: [],
	configZoneLanguages: [],
	besoinCouverture: false,
	besoinDoses: false,
	preparationTransfert: false,
	active: false
}