import { TrackerModel } from '../../admin/tracker/services/trackerModel'
import { CarrierModel } from '../../admin/carrier/services/carrierModel'
import { ContainerModel } from '../../admin/container/services/containerModel'
import { ProductModel, VaccinModel } from '../../admin/product/services/productModel'
import { TemplateRowModel } from '../../admin/template/services/templateModels'
import { LANGUAGES_CODE, REPLENISHMENT_STATUS, SELECT_VALUES } from '../../../utils/constants'
import { Language, ZoneConfigModel, ZoneModel } from '../../admin/zone/services/zoneDataModel'

export interface PhidemDataModel {
	user: {
		centerList: CentreInfoModel[]
		selectedCenter: CentreModel
		selectedZone: ZoneModel
		isSuperAdmin: boolean
		isZoneAdmin: boolean
		isAdminDispatch: boolean
		selectedLang: string
		isDateFormatEn: boolean
	}
	vaccins: VaccinModel[]
	products: ProductModel[]
	templates: TemplateRowModel[]
	zones: ZoneModel[]
	referentialData: PhidemReferentialDataModel
	application: ApplicationModel
	valueList: ValueListDicModel
	reloadReferentiel: (key: string) => void
}

export interface PhidemReferentialDataModel {
	carriers: CarrierModel[]
	containers: ContainerModel[]
	trackers: TrackerModel[]
}

export interface ResponseValueListModel {
	id: string
	code: string
	label: string
}

export interface ValueListModel {
	[key: string]: string
}

export interface ValueListDicModel {
	[key: string]: ValueListModel
}

export interface CentreModel {
	id: string
	nom: string
	type: string
	numFiness?: string
	service?: string
	siret?: string
	adresse: string
	codePostal: string
	ville: string
	telephone?: string
	actif: boolean
	gestionStock: boolean
	desactivationSuivi: boolean
	modeQte?: string
	modeAdministration?: string
	modeOffline: boolean
	idReferent?: string
	idTransporteur?: string
	centreParents: CentreModel[]
	centreEnfants: CentreModel[]

	// Distinction des enfants selon usage front
	centreEnfantsByType: {
		vaccinations: CentreModel[]
		refDispatchs: CentreModel[]
	}
}

export interface CentreInfoModel {
	id: string
	nom: string
	type: string
	service?: string
	zoneId?: string
}

export interface CentreInfoMailingModel {
	id: string
	nom: string
	type: string
	service?: string
	referentEmail?: string
	centerEmail?: string
}

export interface HistoryModel {
	creationDate: string
	modificationDate: string
	creationUser: string
	modificationUser: string
}

export interface AuthorityModel {
	authority: string
}

export interface ApplicationConfiguration {
	defaultLanguage: string
	replenishmentCoverage: boolean
	replenishmentDose: boolean
	transfertPreparation: boolean
	languageList: string[]
}

export interface ApplicationModel {
	replenishment: {
		filters: any
		setFilters: (filters: any) => void
	}
	replenishmentPoint: {
		filters: any
		setFilters: (filters: any) => void
	}
	configuration: ApplicationConfiguration
}

export const initAppConfiguration: ApplicationConfiguration = {
	defaultLanguage: LANGUAGES_CODE.FR,
	transfertPreparation: false,
	replenishmentCoverage: false,
	replenishmentDose: false,
	languageList: [LANGUAGES_CODE.FR, LANGUAGES_CODE.EN]
}

export const initCentre = {
	id: '',
	nom: '',
	type: '',
	numFiness: undefined,
	service: undefined,
	siret: undefined,
	adresse: '',
	codePostal: '',
	ville: '',
	telephone: undefined,
	actif: false,
	gestionStock: false,
	desactivationSuivi: false,
	modeOffline: false,
	idReferent: undefined,
	centreParents: [],
	centreEnfants: [],
	centreEnfantsByType: {
		vaccinations: [],
		refDispatchs: []
	}
}

export const initDispatchData = {
	carriers: [],
	containers: [],
	trackers: []
}

export const initApplication = {
	replenishment: {
		filters: {
			idProduit: undefined,
			idCentre: undefined,
			statut: [REPLENISHMENT_STATUS.EN_ATTENTE, REPLENISHMENT_STATUS.CONFIRME],
			dateRole: SELECT_VALUES.NONE,
			isDateDemande: false,
			isDateReception: false,
			dateDebut: undefined,
			dateFin: undefined,
			idTransporteur: undefined,
			typeEtiquette: undefined,
			texteEtiquette: undefined
		},
		setFilters: () => {
		}
	},
	replenishmentPoint: {
		filters: {
			idProduit: undefined,
			idCentre: undefined,
			statut: [REPLENISHMENT_STATUS.EN_ATTENTE],
			dateRole: SELECT_VALUES.NONE,
			isDateDemande: false,
			isDateReception: false,
			dateDebut: undefined,
			dateFin: undefined
		},
		setFilters: () => {
		}
	},
	configuration: initAppConfiguration
}

export const initLanguage: Language = {
	id: '',
	name: '',
	codeLang: '',
	defaultLanguageFo: false,
	defaultLanguageBo: false,
	activeFlagFo: false,
	activeFlagBo: false,
	idCreator: '',
	creationDate: '',
	idModifier: '',
	modificationDate: ''
}

export const initZoneConfigModel: ZoneConfigModel = {
	besoinCouverture: false,
	besoinDoses: false,
	preparationTransfert: false
}

export const initZoneModel: ZoneModel = {
	id: '',
	name: '',
	timezone: '',
	active: false,
	config: initZoneConfigModel,
	defaultLanguage: initLanguage,
	languages: [],
	configZoneLanguages: []
}

export const REFERENTIEL = {
	CENTER: 'CENTER',
	CARRIER: 'CARRIER',
	CONTAINER: 'CONTAINER',
	TRACKER: 'TRACKER',
	PRODUCT: 'PRODUCT',
	TEMPLATE: 'TEMPLATE',
	ZONE: 'ZONE'
}
