import React, { useContext, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { Grid } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Logo from '../Logo'
import { switchCenter } from '../../modules/common/session/sessionActions'
import { CentreInfoModel } from '../../modules/common/phidemData/phidemDataModel'
import { SessionReducer } from '../../modules/common/session/sessionReducer'
import { useHistory } from 'react-router-dom'
import { PhidemDataContext } from '../../modules/common/phidemData/PhidemDataContext'
import AutocompleteCenter from '../fields/AutocompleteCenter'
import WifiIcon from '@material-ui/icons/Wifi'
import WifiOffIcon from '@material-ui/icons/WifiOff'
import { FormattedMessage } from 'react-intl'
import Paper from '@material-ui/core/Paper'
import { colors } from '../../utils/constants'
import Typography from '@material-ui/core/Typography'
import DialogOfflineMode from './DialogOfflineMode'
import { OfflineContext } from '../../modules/common/offline/context/OfflineContext'

interface BlocHeaderStyle {
	offlineMode: boolean
}

const useStyles = ({ offlineMode }: BlocHeaderStyle) => makeStyles((theme) =>
	createStyles({
		select: {
			marginTop: 10
		},
		offlinePaper: {
			fontSize: 12,
			width: 'calc(100% + 8px)',
			margin: '10px -4px -4px -4px',
			backgroundColor: offlineMode ? colors.snackError : theme.palette.secondary.main,
			color: 'white',
			borderRadius: 4,
			cursor: 'pointer'
		},
		offlineLabel: {
			color: 'white',
			marginRight: 8
		}
	})
)

interface BlocHeaderProps {
	session: SessionReducer,
	switchCenter: (id: string | any) => void
}

const BlocHeader: React.FC<BlocHeaderProps> = (
	{
		session,
		switchCenter
	}
) => {
	const { offlineMode, hasOfflineActionsToSend, handleOfflineMode, clearOfflineActionList } = useContext(OfflineContext)
	const [isOfflineDialogOpen, setIsOfflineDialogOpen] = useState<boolean>(false)
	const classes = useStyles({ offlineMode })()
	const history = useHistory()
	const { user: { centerList, selectedCenter, selectedZone } } = useContext(PhidemDataContext)
	const lockOfflineMode = useMemo(() => offlineMode && hasOfflineActionsToSend, [offlineMode, hasOfflineActionsToSend])

	const updateUserCenter = (item: any) => {
		const selectedCenter = centerList.find((center: CentreInfoModel) => center.id === item)
		if (selectedCenter) {
			handleOfflineMode(false)
			switchCenter({
				id: selectedCenter.id,
				type: selectedCenter.type
			})

			history.push('/')
		}
	}

	const updateOfflineMode = () => {
		if (lockOfflineMode) {
			setIsOfflineDialogOpen(false)
		} else {
			setIsOfflineDialogOpen(false)
			handleOfflineMode(!offlineMode)
			clearOfflineActionList()
			history.push('/')
		}
	}

	return <Grid container>
		<Grid item xs={12}><Logo /></Grid>
		{selectedZone && <Grid item xs={12} style={{ marginTop: '10px', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
			<Typography
				style={{
					color: '#000000',
					marginRight: '8px',
					fontSize: '16px',
					fontFamily: 'Roboto',
					fontWeight: 'normal'
				}}
			>
				{selectedZone.name}
			</Typography>
		</Grid>}
		{!!session.centerId && <Grid item xs={12} className={classes.select}>
			<AutocompleteCenter
				options={centerList.map((centre: CentreInfoModel) => ({
					label: `${centre.nom}${centre.service ? ` - ${centre.service}` : ''}`,
					type: centre.type,
					value: centre.id
				}))}
				onChange={(item: any) => updateUserCenter(item)}
				disabled={centerList.length === 1 || offlineMode}
				selectedCenter={session.centerId}
			/>
		</Grid>}
		{selectedCenter.modeOffline && <>
			<Paper className={classes.offlinePaper} onClick={() => setIsOfflineDialogOpen(true)}>
				<Grid item container xs={12} alignItems="center" justify="center">
					<Typography className={classes.offlineLabel}>
						<FormattedMessage
							id={offlineMode ? 'menuHeader.offlineMode' : 'menuHeader.onlineMode'}
							defaultMessage="Mode déconnecté"
							description="Online/Offline label"
						/>
					</Typography>
					{offlineMode ? <WifiOffIcon /> : <WifiIcon />}
				</Grid>
			</Paper>
		</>}
		<DialogOfflineMode
			isOpen={isOfflineDialogOpen}
			disabled={lockOfflineMode}
			offlineMode={offlineMode}
			handleClose={() => setIsOfflineDialogOpen(false)}
			onConfirm={updateOfflineMode}
		/>
	</Grid>
}

const mapStateToProps = (state: any) => ({
	session: state.session
})

const actions = {
	switchCenter
}


export default connect(mapStateToProps, actions)(BlocHeader)
