import React, { useContext } from 'react'
import { Field, Form } from 'react-final-form'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { FormattedMessage } from 'react-intl'
import { FormInput, FormSelect } from 'isotope-client'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Alert from '@material-ui/lab/Alert'
import Loader from '../../../../components/layout/Loader'
import PageFormContainer from '../../../../components/layout/PageFormContainer'
import { SCREEN_SIZE, TEMPLATE_TYPES } from '../../../../utils/constants'
import FieldFile from '../../../../components/fields/FieldFile'
import Button from '../../../../components/layout/buttons/Button'
import ErrorMessageRequired from '../../../../components/layout/errors/ErrorMessageRequired'
import ListSubheader from '@material-ui/core/ListSubheader'
import MenuItem from '@material-ui/core/MenuItem'
import { PhidemDataContext } from '../../../common/phidemData/PhidemDataContext'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		submitButtonRoot: {
			width: '100%',
			margin: 40,
			marginBottom: 100,
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'center',
			alignItems: 'center',
			[theme.breakpoints.down('md')]: {
				flexDirection: 'column',
				marginLeft: 0,
				marginRight: 0
			}
		},
		button: {
			marginBottom: theme.spacing(4),
			marginRight: theme.spacing(4)
		}
	})
)

export interface TemplateFormValues {
	nom: string
	type: string
	file?: File
	publie?: boolean
	zoneId: string
}

interface TemplateFormProps {
	isLoading?: boolean
	isStatusLoading?: boolean
	onSubmit: (event: any) => void
	isCreationForm: boolean
	initialValues?: TemplateFormValues
	updateTemplateStatus?: (wantedStatus: boolean) => void
}

const TemplateForm: React.FC<TemplateFormProps> = (
	{
		onSubmit,
		isLoading = false,
		isStatusLoading = false,
		isCreationForm,
		initialValues = {},
		updateTemplateStatus
	}
) => {
	const classes = useStyles()
	const isLargeScreen = useMediaQuery(`(min-width: ${SCREEN_SIZE.LARGE}px)`)
	const { zones, user: { isSuperAdmin } } = useContext(PhidemDataContext)

	const onValidate = (formValues: TemplateFormValues) => {
		const errors: any = {}

		if (!formValues.nom) {
			errors.nom = <ErrorMessageRequired />
		}
		if (!formValues.type) {
			errors.type = <ErrorMessageRequired />
		}
		if (!formValues.zoneId) {
			errors.zoneId = <ErrorMessageRequired />
		}
		if (isCreationForm && !formValues.file) {
			errors.file = <ErrorMessageRequired />
		}

		return errors
	}

	const renderTemplatesItems = (templates: string[]) => {
		return templates.map((templateType: string) => (
			<MenuItem
				key={templateType}
				value={templateType}
			>
				<FormattedMessage
					id={`enum.templateTypes.${templateType}`}
					defaultMessage={templateType}
					description="Template type"
				/>
			</MenuItem>
		))
	}

	return isLoading
		?
		<Loader />
		:
		<Form
			initialValues={initialValues}
			onSubmit={onSubmit}
			validate={onValidate}
			render={({ handleSubmit, submitting, values }) => (
				<PageFormContainer onSubmit={handleSubmit}>
					<Grid item xs={12}>
						<Typography variant={isLargeScreen ? 'h1' : 'h3'}>
							<FormattedMessage
								id="template.create.formSection.information"
								defaultMessage="Information"
								description="Form section title"
							/>
						</Typography>
					</Grid>
					{isSuperAdmin &&
						<Grid item xs={6}>
							<Field
								name="zoneId"
								component={FormSelect}
								required
								disabled={!isCreationForm}
								label={
									<FormattedMessage
										id="product.Zone"
										defaultMessage="Zone"
										description="Zone"
									/>
								}
							>
								{zones.map((zone) => (
									<MenuItem key={zone.id} value={zone.id}>
										{zone.name}
									</MenuItem>
								))}
							</Field>
						</Grid>}
					<Grid item xs={12} md={5}>
						<Field
							name="nom"
							component={FormInput}
							label={<FormattedMessage
								id="template.fieldsLabel.nom"
								defaultMessage="Nom"
								description="Input label"
							/>}
							required
						/>
					</Grid>
					<Grid item xs={12} md={4}>
						<Field
							name="type"
							component={FormSelect}
							label={
								<FormattedMessage
									id="template.fieldsLabel.type"
									defaultMessage="Type"
									description="Input label"
								/>
							}
							required
						>
							<ListSubheader><FormattedMessage id="template.category.referent" defaultMessage="Templates référent" description="Template type category" /></ListSubheader>
							{renderTemplatesItems(Object.values(TEMPLATE_TYPES.REFERENT))}
							<ListSubheader><FormattedMessage id="template.category.dispatch" defaultMessage="Templates dispatch" description="Template type category" /></ListSubheader>
							{renderTemplatesItems(Object.values(TEMPLATE_TYPES.DISPATCH))}
							<ListSubheader><FormattedMessage id="template.category.vaccination" defaultMessage="Templates vaccination" description="Template type category" /></ListSubheader>
							{renderTemplatesItems(Object.values(TEMPLATE_TYPES.VACCINATION))}
						</Field>
					</Grid>
					<Grid item xs={12}>
						<Alert severity="info" variant="outlined">
							{
								isCreationForm ?
									<FormattedMessage
										id="template.alert.fileInfoCreate"
										defaultMessage="Le fichier doit être au format .docx."
										description="Info message"
									/>
									:
									<FormattedMessage
										id="template.alert.fileInfoUpdate"
										defaultMessage="Ce fichier (si renseigné), écrasera le précédent. Le fichier doit être au format .docx."
										description="Info message"
									/>
							}
						</Alert>
					</Grid>
					<FieldFile
						label={<FormattedMessage
							id="template.fieldsLabel.file"
							defaultMessage="Fichier"
							description="File description"
						/>}
						required={isCreationForm}
						accept=".docx"
					/>
					<div
						className={classes.submitButtonRoot}
						style={{
							justifyContent: isLargeScreen ? 'flex-start' : 'center'
						}}
					>
						{isCreationForm ?
							<Button type="submit" variant="contained" isLoading={submitting} className={classes.button}>
								<FormattedMessage
									id="button.create"
									defaultMessage="Créer"
									description="Message on form submission button"
								/>
							</Button>
							:
							<>
								<Button type="submit" variant="contained" isLoading={submitting} className={classes.button}>
									<FormattedMessage
										id="button.save"
										defaultMessage="Sauvegarder"
										description="Message on form submission button"
									/>
								</Button>
								<Button
									variant="contained"
									onClick={() => updateTemplateStatus !== undefined && updateTemplateStatus(!values.publie)}
									isLoading={isStatusLoading}
									className={classes.button}
								>
									{values.publie ?
										<FormattedMessage
											id="button.unpublish"
											defaultMessage="Masquer"
											description="Update status button"
										/>
										:
										<FormattedMessage
											id="button.publish"
											defaultMessage="Publier"
											description="Update status button"
										/>
									}
								</Button>
							</>
						}
					</div>
				</PageFormContainer>
			)}
		/>
}

export default TemplateForm
