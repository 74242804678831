import React, { useContext } from 'react'
import { FormSelect, FormSwitch, SpringDataTable, StaticDataTable } from 'isotope-client'
import { injectActions as injectSnackbarActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import moment from 'moment'
import { Field, Form } from 'react-final-form'
import { OnChange } from 'react-final-form-listeners'
import { FormattedMessage, useIntl } from 'react-intl'
import { VaccinModel } from '../../../admin/product/services/productModel'
import { PhidemDataContext } from '../../phidemData/PhidemDataContext'
import { getAdministrationsByWeek } from '../../../vaccination/services/vaccinationApi'
import FieldWeekPicker from '../../../../components/fields/FieldWeekPicker'
import Button from '../../../../components/layout/buttons/Button'
import ExportExcelButton from '../../../../components/layout/buttons/ExportExcelButton'
import SplitButton from '../../../../components/layout/buttons/SplitButton'
import PageLayout from '../../../../components/layout/PageLayout'
import { ADMIN_MODE, CENTER_TYPE, DOWNLOAD_FILE_TYPE, SELECT_VALUES } from '../../../../utils/constants'
import { fetchDownload, getFormattedUrl } from '../../../../utils/fetchDownload'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { Search } from '@material-ui/icons'
import FieldAutocomplete from '../../../../components/fields/FieldAutocomplete'
import { getLocationOptions } from '../../../../utils/formUtils'
import { DATE_FORMAT, displayDate } from '../../../../utils/dateUtils'

const useStyles = makeStyles((theme: Theme) => createStyles({
	paperForm: {
		width: '70%',
		minWidth: 300,
		maxWidth: 880,
		padding: theme.spacing(2),
		marginBottom: 50
	},
	submitButton: {
		width: '100%',
		margin: theme.spacing(1),
		display: 'flex',
		justifyContent: 'flex-end'
	},
	mode: {
		color: '#55555a',
		fontSize: 12,
		transform: 'scale(1)',
		fontWeight: 'normal',
		whiteSpace: 'nowrap'
	}
}))

const DAYS: string[] = ['lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi', 'dimanche']
const NB_WEEKS_EXPORT = [1, 2, 5]

interface UsageControlProps {
	snackError: (value: any) => void
}

interface Column {
	key: string,
	name: React.ReactElement,
	sortable?: boolean,
	render?: (row: any) => React.ReactElement
}

interface FiltersModel {
	product: string
	center: string
	modeAdministration: string
	week: {
		from: Date,
		to: Date
	}
}

const UsageControl = ({ snackError }: UsageControlProps) => {
	const classes = useStyles()
	const intl = useIntl()
	const { vaccins, user: { selectedCenter, isDateFormatEn, selectedLang } } = useContext(PhidemDataContext)
	const isVaccinationPoint = selectedCenter && selectedCenter.type === CENTER_TYPE.VACCINATION_POINT
	const [filters, setFilters] = React.useState({
		product: '',
		center: isVaccinationPoint ? selectedCenter.id : '',
		modeAdministration: ADMIN_MODE.SEMAINE,
		week: moment().endOf('week').toISOString()
	})
	const [isLoadingDocument, setIsLoadingDocument] = React.useState(false)
	const [administrationByWeek, setAdministrationByWeek] = React.useState([])

	React.useEffect(() => {
		if (filters.modeAdministration === ADMIN_MODE.SEMAINE) {
			getAdministrationsByWeek(filters)
				.then((res: any) => setAdministrationByWeek(res))
		}
	}, [filters])

	const onSubmit = (formValues: FiltersModel) => {
		setFilters({
			product: formValues.product === SELECT_VALUES.ALL ? '' : formValues.product,
			center: formValues.center === SELECT_VALUES.ALL ? '' : formValues.center,
			modeAdministration: formValues.modeAdministration ? ADMIN_MODE.JOUR : ADMIN_MODE.SEMAINE,
			week: moment(formValues.week.to).toISOString()
		})
	}

	const getHeaders = () => {
		const headers: Column[] = [
			{
				key: 'centre',
				name: <FormattedMessage
					id="administrationTracking.headers.location"
					defaultMessage="Centre"
					description="Header column name"
				/>,
				render: row => row.centre.nom || row.centre
			},
			{
				key: 'produit',
				name: <FormattedMessage
					id="global.functionnal.product"
					defaultMessage="Produit"
					description="Header column name"
				/>,
				render: row => row.produit.nomCommercial || row.produit
			}
		]

		if (filters.modeAdministration === ADMIN_MODE.JOUR) {
			DAYS.forEach((day: string, index: number) => {
				headers.push({
					key: day,
					name: <span>{displayDate(moment(filters.week).isoWeekday(index + 1), DATE_FORMAT.SHORT_DATE, isDateFormatEn)}</span>,
					render: (row) => (
						<>
							<span>{`D1 : ${row[`d1j${index + 1}`] ? row[`d1j${index + 1}`] : 0}`}</span>
							<br />
							<span>{`D2 : ${row[`d2j${index + 1}`] ? row[`d2j${index + 1}`] : 0}`}</span>
							<br />
							<span>{`DR : ${row[`d3j${index + 1}`] ? row[`d3j${index + 1}`] : 0}`}</span>
							<br />
							<span>{`DU : ${row[`duj${index + 1}`] ? row[`duj${index + 1}`] : 0}`}</span>
						</>
					)
				})
			})
		} else if (filters.modeAdministration === ADMIN_MODE.SEMAINE) {
			for (let index = 0; index < 5; index++) {
				const startDate = displayDate(moment(filters.week).add('weeks', index).isoWeekday(1), DATE_FORMAT.SHORT_DATE, isDateFormatEn)
				const endDate = displayDate(moment(filters.week).add('weeks', index).isoWeekday(7), DATE_FORMAT.SHORT_DATE, isDateFormatEn)
				headers.push({
					key: `week${index}`,
					name: <span>{`${startDate} - ${endDate}`}</span>,
					render: (row) => {
						const foundUsage = row.utilisations.find((rowU: any) => rowU.week === index)
						return <>
							<span>{`D1 : ${foundUsage ? foundUsage.d1 : 0}`}</span>
							<br />
							<span>{`D2 : ${foundUsage ? foundUsage.d2 : 0}`}</span>
							<br />
							<span>{`DR : ${foundUsage ? foundUsage.d3 : 0}`}</span>
							<br />
							<span>{`DU : ${foundUsage ? foundUsage.du : 0}`}</span>
						</>
					}
				})
			}
		}

		return headers
	}

	const downloadDocument = (nbWeeks: number) => {
		setIsLoadingDocument(true)
		fetchDownload(getFormattedUrl('/vaccination/utilisation/export', { ...filters, nbWeeks: nbWeeks }))
			.then(
				(blob: any) => {
					const url = window.URL.createObjectURL(blob)
					const a = document.createElement('a')
					a.href = url
					a.download = DOWNLOAD_FILE_TYPE.ADMINISTRATIONS.outputFile
					a.click()
				}
			)
			.catch(() => snackError({ id: 'global.error.downloadFailed', defaultMessage: 'Le téléchargement a échoué', description: 'Error message' }))
			.finally(() => setIsLoadingDocument(false))
	}

	return (
		<PageLayout>
			<Paper className={classes.paperForm}>
				<Form
					initialValues={{
						...filters,
						product: filters.product || SELECT_VALUES.ALL,
						center: isVaccinationPoint ? selectedCenter.id : (filters.center || SELECT_VALUES.ALL),
						modeAdministration: filters.modeAdministration === ADMIN_MODE.JOUR,
						week: {
							from: moment(filters.week).subtract('days', 6).toISOString(),
							to: filters.week
						}
					}}
					onSubmit={onSubmit}
					render={({ handleSubmit, submitting }) => (
						<form onSubmit={handleSubmit}>
							<Grid
								container
								direction="row"
								justify="flex-start"
							>
								<Grid item container xs={12} spacing={2}>
									<Grid item xs={5}>
										<Field
											name="product"
											label={<FormattedMessage
												id="global.functionnal.product"
												defaultMessage="Produit"
												description="Product"
											/>}
											component={FormSelect}
										>
											<MenuItem key={0} value={SELECT_VALUES.ALL}>
												<FormattedMessage
													id="select.all"
													defaultMessage="Tous"
													description="All"
												/>
											</MenuItem>
											{vaccins.map((vaccin: VaccinModel) =>
												<MenuItem key={vaccin.id} value={vaccin.id}>{vaccin.nomCommercial}</MenuItem>
											)}
										</Field>
									</Grid>
									<Grid item xs={5}>
										{!isVaccinationPoint && <Field
											name="center"
											label={<FormattedMessage
												id="administrationTracking.filters.location"
												defaultMessage="Centre"
												description="Location"
											/>}
											placeholder={intl.formatMessage({
												id: 'select.all',
												defaultMessage: 'Tous',
												description: 'All'
											})}
											component={FieldAutocomplete}
											options={(() => getLocationOptions(selectedCenter, selectedCenter.type === CENTER_TYPE.VACCINATION))()}
										/>
										}
									</Grid>
									<FieldWeekPicker
										name="week"
										label={
											<FormattedMessage
												id={`administrationTracking.filters.${filters.modeAdministration === ADMIN_MODE.SEMAINE ? 'startWeek' : 'week'}`}
												defaultMessage="Date"
												description="form input"
											/>
										}
										isDateFormatEn={isDateFormatEn}
										selectedLang={selectedLang}
									/>
									<Grid item xs={12} md={5}>
										<span className={classes.mode}>
											<FormattedMessage
												id="administrationTracking.fields.mode.label"
												defaultMessage="Afficher par :"
												description="Product"
											/>
										</span>
										<Field
											name="modeAdministration"
											component={FormSwitch}
											label={
												<FormattedMessage
													id="administrationTracking.fields.mode.value"
													defaultMessage={`${ADMIN_MODE.SEMAINE} / ${ADMIN_MODE.JOUR}`}
													description="Mode"
												/>
											}
										/>
										<OnChange name="modeAdministration">
											{(value) => {
												setFilters((filters) => {
													return { ...filters, modeAdministration: value ? ADMIN_MODE.JOUR : ADMIN_MODE.SEMAINE }
												})
											}}
										</OnChange>
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={12}>
								<div className={classes.submitButton}>
									<Button
										variant="contained"
										startIcon={<Search />}
										type="submit"
										isLoading={submitting}
									>
										<FormattedMessage
											id="button.search"
											defaultMessage="Rechercher"
											description="Search button label"
										/>
									</Button>
								</div>
							</Grid>
						</form>
					)}
				/>
			</Paper>

			{
				filters.modeAdministration === ADMIN_MODE.JOUR ?
					<>
						<SpringDataTable
							apiUrl="/vaccination/utilisation/jour"
							nom="procurementList"
							headers={getHeaders()}
							filters={filters}
						/>
						<SplitButton
							isLoading={isLoadingDocument}
							label="button.export"
							items={[
								...NB_WEEKS_EXPORT.map((nbWeeks: number) => ({
									label: intl.formatMessage({
										id: `procurement.export.${nbWeeks}`,
										defaultMessage: `Détaillé sur ${nbWeeks} semaines`,
										description: 'Export button'
									}),
									onClick: () => downloadDocument(nbWeeks)
								}))
							]}
						/>
					</>
					:
					<>
						<StaticDataTable
							data={administrationByWeek}
							headers={getHeaders()}
						/>
						<ExportExcelButton
							url="/vaccination/utilisation/export"
							fileName={DOWNLOAD_FILE_TYPE.ADMINISTRATIONS.outputFile}
							filters={filters}
						/>
					</>
			}
		</PageLayout>
	)
}

export default injectSnackbarActions(UsageControl)
