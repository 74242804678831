import React, { useContext } from 'react'
import { compose } from 'redux'
import { injectActions as injectSnackbarActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import { useHistory } from 'react-router-dom'
import { injectToolbarData } from 'isotope-client'
import { FormattedMessage } from 'react-intl'
import { postCarrier } from './services/carrierApi'
import { CarrierModel } from './services/carrierModel'
import CarrierForm from './components/CarrierForm'
import errorsMapper from '../../../utils/errorsMapper'
import { PhidemDataContext } from '../../common/phidemData/PhidemDataContext'
import { REFERENTIEL } from '../../common/phidemData/phidemDataModel'

const defaultValues = {
	nom: '',
	idCentre: '',
	siret: '',
	adresse: '',
	complementAdresse: '',
	codePostal: '',
	ville: '',
	pays: '',
	telephone: '',
	mailContact: '',
	actif: false,
	zoneId: undefined
}

interface CarrierCreateProps {
	snackSuccess: (value: any) => void,
	snackError: (value: any) => void,
}

const CarrierCreate: React.FC<CarrierCreateProps> = (
	{
		snackSuccess,
		snackError
	}) => {
	const history = useHistory()
	const { user: { selectedCenter, selectedZone }, reloadReferentiel } = useContext(PhidemDataContext)

	const onSubmit = async (formValues: any) => {
		return postCarrier(formValues)
			.then((response: CarrierModel) => {
				history.push(`/carriers/${response.id}`)
				snackSuccess({ id: 'carrier.createSuccess', defaultMessage: 'Le transporteur a bien été créé', description: 'Success message' })
				reloadReferentiel(REFERENTIEL.CARRIER)
			})
			.catch((e: any) => {
				const { errors, displayFields } = errorsMapper(e)
				if (displayFields) {
					snackError({ id: 'global.error.formError', defaultMessage: 'Votre formulaire comporte des erreurs', description: 'Form error message' })
				} else {
					snackError({ id: 'carrier.createError', defaultMessage: 'Une erreur est survenue, le transporteur n\'a pas été créé', description: 'Error message' })
				}
				return errors
			})
	}

	return (
		<CarrierForm
			initialValues={{ ...defaultValues, idCentre: selectedCenter.id, zoneId: selectedZone?.id }}
			onSubmit={onSubmit}
			isCreationForm={true}
		/>
	)
}

export default compose<any>(injectToolbarData(() => ({
	title: <FormattedMessage
		id="carrier.pageTitle.create"
		defaultMessage="Nouveau transporteur"
		description="Page title"
	/>
})), injectSnackbarActions)(CarrierCreate)
