// Sort role by importance

export const sortRolesByImportance = (data: Role[]): Role[] => {
	const importanceOrder = [
		'admin',
		'admin_zone',
		'referent',
		'admin_dispatch',
		'dispatch_center',
		'vaccination_center',
		'vaccination_point'
	]

	return data.sort((a, b) => {
		const aIndex = importanceOrder.indexOf(a.code)
		const bIndex = importanceOrder.indexOf(b.code)

		if (aIndex !== -1 && bIndex !== -1) {
			return aIndex - bIndex
		}

		if (aIndex !== -1) return -1
		if (bIndex !== -1) return 1

		return 0
	})
}